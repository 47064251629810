export const enum AccountTypes {
  Agent,
  LocAccount,
  NationalAccount,
  InternalUser,
  TracerUser,
  AccountUser,
}
export const enum RoleCodes {
  LRS1,
  LRS2,
}
