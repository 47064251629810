import { Dispatch, SetStateAction } from "react";
import {
  MAX_RESULTS_WARNING,
  MIXED_RESULTS_WARNING,
} from "../../../common/constants";
import { AlertMessage, AlertType } from "../Common/AlertMessage";

export enum WarningType {
  maxAccounts = "MaxAccounts",
  maxResults = "MaxResults",
  mixedResults = "MixedResults",
}
export const WarningBanner = (props: {
  warningType: WarningType;
  isDismissable?: boolean;
  showWarning?: boolean;
  setShowWarning?: Dispatch<SetStateAction<boolean>>;
}) => {
  if (!props.warningType) {
    return null;
  }

  switch (props.warningType) {
    case WarningType.maxAccounts:
      return (
        <AlertMessage
          isDismissable={props.isDismissable ?? false}
          type={AlertType.warning}
          message="You have reached the maximum selection criteria"
        />
      );
    case WarningType.maxResults:
      return (
        <AlertMessage
          isDismissable={props.isDismissable ?? false}
          isShown={props.showWarning}
          setIsShown={props.setShowWarning}
          type={AlertType.warning}
          message={MAX_RESULTS_WARNING}
        />
      );
    case WarningType.mixedResults:
      return (
        <div style={{fontSize: 14}} className="tds-inline-alert--error">
          <p>{MIXED_RESULTS_WARNING}</p>
        </div>
      );
  }
};
