import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { TdsDateInput, TdsField } from "@trv-tds/react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accountIdentificationOptions } from "../../../common/constants";
import { RootState } from "../../../store";
import { StackContainer } from "../../Layout/StackContainer";
import { AccIdentification } from "../AgentAccountSearch/agentAccount";
import { addSuccessMessage } from "../../../features/claimActivity/claimActivityErrorMsgSlice";
import { addDateRequiredErrorMessage, addDateRequiredToErrorMessage, addHistoryDateRequiredErrorMessage } from "../../../features/errors/errorReducer";

type DateSelectionProps = {
  hasDouble?: boolean;
  reset?: boolean;
  disabledProducer?: boolean;
  setReset?: Dispatch<SetStateAction<boolean | undefined>>;
  fromDate?: any;
  setFromDate: Function;
  toDate?: any;
  setToDate: Function;
  blankDates?: boolean;
  blankHistoryDate?: boolean;
  historyDate?: any;
  setHistoryDate: Function;
  historyDate2?: any;
  setHistoryDate2?: Function;
  dateHasErrors: boolean;
  setDateHasErrors: Function;
  pageName?: string;
  dateRangeRequiredMsg?: boolean;
  setDateRangeRequiredMsg?: Function;
  hasNationalProgramsGroupMemberLossRun?: boolean;
};

export const DateSelectionCriteria = ({
  hasDouble,
  reset,
  setReset,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  blankDates = false,
  blankHistoryDate = false,
  historyDate,
  setHistoryDate,
  historyDate2,
  setHistoryDate2,
  dateHasErrors,
  setDateHasErrors,
  disabledProducer = false,
  pageName,
  hasNationalProgramsGroupMemberLossRun,
}: DateSelectionProps) => {
  const userAccountIdentification: AccIdentification = useSelector(
    (state: RootState) => state.agentAccount.accountIdentification
  );
  const [displayInvalidFromDateMsg, setDisplayInvalidFromDateMsg] =
    useState<boolean>(false);
  const [displayInvalidToDateMsg, setDisplayInvalidToDateMsg] =
    useState<boolean>(false);
  const [displayToDateToEarlyMsg, setDisplayToDateToEarlyMsg] =
    useState<boolean>(false);
  const [displayFromDateToLateMsg, setDisplayFromDateToLateMsg] =
    useState<boolean>(false);

  const [displayInvalidHistoryMonthMsg, setDisplayInvalidHistoryMonthMsg] =
    useState<boolean>(false);
  const [displayInvalidHistoryYearMsg, setDisplayInvalidHistoryYearMsg] =
    useState<boolean>(false);
  const [displayFromDateOutOfRangeMsg, setDisplayFromDateOutOfRangeMsg] =
    useState<boolean>(false);
  const [displayToDateOutOfRangeMsg, setDisplayToDateOutOfRangeMsg] =
    useState<boolean>(false);
    const [displayGMLRToDateOutOfRangeMsg, setDisplayGMLRToDateOutOfRangeMsg] = 
    useState<boolean>(false);
    const [displayGMLRFromDateOutOfRangeMsg, setDisplayGMLRFromDateOutOfRangeMsg] = 
    useState<boolean>(false);
      const [displayFromDateNotPopulatedMsg, setDisplayFromDateNotPopulatedMsg] =
    useState<boolean>(false);
  const [displayToDateNotPopulatedMsg, setDisplayToDateNotPopulatedMsg] =
    useState<boolean>(false);
  const [displayInvalidHistoryLengthMsg, setDisplayInvalidHistoryLengthMsg] =
    useState<boolean>(false);


  const [displayInvalidHistoryMonthMsg2, setDisplayInvalidHistoryMonthMsg2] =
    useState<boolean>(false);
  const [displayInvalidHistoryYearMsg2, setDisplayInvalidHistoryYearMsg2] =
    useState<boolean>(false);
  const [displayInvalidHistoryLengthMsg2, setDisplayInvalidHistoryLengthMsg2] =
    useState<boolean>(false);
  const [isDirty2, setIsDirty2] = useState<boolean>(false);

  const [fromHasErrors, setFromHasErrors] = useState<boolean>(false);
  const [toHasErrors, setToHasErrors] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const refFromDate = useRef<HTMLTdsDateInputElement>(null);
  const refToDate = useRef<HTMLTdsDateInputElement>(null);

  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const minDate = "1900-01-01";
  const maxDate = "2100-12-31";
  const [dateRangeRequiredMsg, setDateRangeRequiredMsg] = useState<boolean>(false);


  const dateRequiredErrorMessage: boolean = useSelector(
    (state: RootState) => state.errors.hasDateRequiredErrorMessage
  );
  const dateRequiredToErrorMessage: boolean = useSelector(
    (state: RootState) => state.errors.hasDateRequiredToErrorMessage
  );

  const historyDateRequiredErrorMessage: boolean = useSelector(
    (state: RootState) => state.errors.hasHistoryDateRequiredErrorMessage
  );

  const isInValidFirst = isDirty && (historyDate === "");
  const isInValidSecond = isDirty2 && (historyDate2 === "");
  const bothInvalid = hasDouble && (isInValidFirst || isInValidSecond)


  const date1 = new Date(!historyDate ? null : historyDate.split("/").join("/01/")).getTime();
  const date2 = new Date(!historyDate2 ? null : historyDate2.split("/").join("/01/")).getTime();
  let greaterError = false;
  if (hasDouble && isDirty && isDirty2 && date2 <= date1) {
    greaterError = true
  }

  const historyDateHasErrors =
    displayInvalidHistoryMonthMsg ||
    displayInvalidHistoryYearMsg ||
    displayInvalidHistoryLengthMsg ||
    displayInvalidHistoryMonthMsg2 ||
    displayInvalidHistoryYearMsg2 ||
    displayInvalidHistoryLengthMsg2 || greaterError;

  useEffect(() => {
    if (!blankDates)
      resetDates();
  }, [blankDates]);

  useEffect(() => {
    if (historyDateRequiredErrorMessage) {
      setIsDirty(true)
      setIsDirty2(true)
    } else if (!historyDateHasErrors) {
      setDateHasErrors(toHasErrors || fromHasErrors || historyDateHasErrors);
    }
  }, [historyDateRequiredErrorMessage, historyDateHasErrors])

  useEffect(() => {
    if (reset) {
      resetDates();
      if (setReset) {
        setReset(false);
        setFromHasErrors(false);
        setToHasErrors(false)
        setDisplayInvalidFromDateMsg(false);
        setDisplayInvalidToDateMsg(false);
        setDisplayToDateToEarlyMsg(false);
        setDisplayFromDateToLateMsg(false);
        setDisplayInvalidHistoryMonthMsg(false);
        setDisplayInvalidHistoryMonthMsg2(false)
        setDisplayInvalidHistoryYearMsg(false);
        setDisplayInvalidHistoryYearMsg2(false)
        setDisplayFromDateOutOfRangeMsg(false);
        setDisplayToDateOutOfRangeMsg(false);
        setDisplayGMLRToDateOutOfRangeMsg(false);
        setDisplayGMLRFromDateOutOfRangeMsg(false);
        setDisplayFromDateNotPopulatedMsg(false);
        setDisplayToDateNotPopulatedMsg(false);
        setDisplayInvalidHistoryLengthMsg(false);
        setDisplayInvalidHistoryLengthMsg2(false);
        dispatch(addHistoryDateRequiredErrorMessage(false));
        dispatch(addDateRequiredErrorMessage(false));
        dispatch(addDateRequiredToErrorMessage(false));
        setIsDirty(false)
        setIsDirty2(false)

      }
      setHistoryDate("");
    }
  }, [reset, setReset]);

  useEffect(() => {
    scrollToElement();
  }, [fromHasErrors, toHasErrors, dateHasErrors]);

  useEffect(() => {
    if (
      userAccountIdentification.accountIdentificationMethod ===
      accountIdentificationOptions.ProducerCode
    ) {
      setHistoryDate("");
      setDisplayInvalidHistoryLengthMsg(false);
      setDisplayInvalidHistoryMonthMsg(false);
      setDisplayInvalidHistoryYearMsg(false);
    }
    let fDate = new Date(fromDate);
    fDate.setFullYear(fDate.getFullYear() + 5);
    fDate.setFullYear(fDate.getFullYear() - 5);
    let tDate = new Date(toDate);
    tDate.setFullYear(tDate.getFullYear() - 5);
    if (userAccountIdentification.accountIdentificationMethod === accountIdentificationOptions.ProducerCode && fDate < tDate && pageName !== 'NationalPrograms') {
      setDisplayFromDateOutOfRangeMsg(true)
      setFromHasErrors(true);
    } else {
      setDisplayToDateOutOfRangeMsg(false);
      setDisplayGMLRToDateOutOfRangeMsg(false);
      setDisplayGMLRFromDateOutOfRangeMsg(false);
      setDisplayFromDateOutOfRangeMsg(false);
      setToHasErrors(false);
      setFromHasErrors(false);
      if(hasNationalProgramsGroupMemberLossRun){
        tDate.setFullYear(tDate.getFullYear() - 2);
        if(fDate < tDate){
          setDisplayGMLRToDateOutOfRangeMsg(true);
          setToHasErrors(true);
          setDisplayGMLRFromDateOutOfRangeMsg(true);
          setFromHasErrors(true);
        }else{
          setDisplayGMLRToDateOutOfRangeMsg(false);
          setToHasErrors(false);
          setDisplayGMLRFromDateOutOfRangeMsg(false);
          setFromHasErrors(false);
        }

      }
    }
  }, [userAccountIdentification.accountIdentificationMethod]);

  const scrollToElement = () => {
    if (
      fromHasErrors ||
      toHasErrors ||
      displayInvalidHistoryMonthMsg ||
      displayInvalidHistoryYearMsg ||
      displayInvalidHistoryLengthMsg
    )
      ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  function isLeap(year: number) {
    return new Date(year, 1, 29).getDate() === 29;
  }

  function resetDates() {
    setIsDirty(false)
    setIsDirty2(false)
    setDateRangeRequiredMsg(false);
    if (!blankDates) {
      let curr = new Date();
      curr.setDate(curr.getDate());
      let toDateStr = curr.toISOString().substring(0, 10);
      setToDate(toDateStr);
      curr.setFullYear(curr.getFullYear() - 3)
      let dateStr = curr.toISOString().substring(0, 10);
      setFromDate(dateStr);
    } else {
      setToDate("")
      setFromDate("")
    }
  }

  const historyDateHandler = (event: any) => {
    dispatch(addSuccessMessage(""));
    setIsDirty(false)
    let errorState = false;
    let prevValue = historyDate;
    const regex = new RegExp("[0-9]|/");
    if (event.target.value.length === 8) return;
    if (regex.test(event.target.value.charAt(event.target.value.length - 1))) {
      if (event.target.value.length === 2) {
        if (
          event.target.value.endsWith("/") &&
          event.target.value.indexOf("/") ===
          event.target.value.lastIndexOf("/")
        ) {
          setHistoryDate("0" + event.target.value);
        } else {
          if (historyDate.length < 2 && event.target.value.length === 2) {
            //auto add forward slash
            setHistoryDate(event.target.value.substring(0, 2) + "/");
          } else if (event.target.value.length < historyDate.length) {
            //delete character case
            setHistoryDate(event.target.value.substring(0, 1));
          }
        }
        if (
          event.target.value.length > 0 &&
          (event.target.value < 1 || event.target.value > 12)
        ) {
          setDisplayInvalidHistoryMonthMsg(true);
          errorState = true;
        } else {
          setDisplayInvalidHistoryMonthMsg(false);
        }
      } else if (
        event.target.selectionStart === 3 &&
        event.target.value.length > 3
      ) {
        //user types number just before / position
        let tempDate = event.target.value.replace("/", "");
        setHistoryDate(
          tempDate.slice(0, 2) +
          "/" +
          tempDate.slice(3, event.target.value.length)
        );
      } else if (event.target.value.length >= 7) {
        setDisplayInvalidHistoryMonthMsg(
          event.target.value.substring(0, 2) < 1 ||
          event.target.value.substring(0, 2) > 12
        );
        setHistoryDate(event.target.value.substring(0, 7));
        if (
          event.target.value.substring(3, 7) < 1900 ||
          event.target.value.substring(3, 7) > 2100
        ) {
          setDisplayInvalidHistoryYearMsg(true);
          errorState = true;
        } else {
          setDisplayInvalidHistoryYearMsg(false);
        }
      } else if (
        event.target.value.indexOf("/") !== event.target.value.lastIndexOf("/")
      ) {
        setHistoryDate(
          event.target.value.substring(0, event.target.value.indexOf("/") + 1)
        );
      } else if (
        event.target.value.length === 1 &&
        event.target.value === "/"
      ) {
        console.log("");
      } else {
        setHistoryDate(event.target.value);
      }
    } else if (event.target.value.length === 0) {
      setDisplayInvalidHistoryYearMsg(false);
      setDisplayInvalidHistoryMonthMsg(false);
      setHistoryDate(event.target.value);
    } else {
      setHistoryDate(prevValue);
    }
    setDateHasErrors(errorState || toHasErrors || fromHasErrors || historyDateHasErrors);
  };


  const historyDateHandler2 = (event: any) => {
    dispatch(addSuccessMessage(""));
    setIsDirty2(false)
    let errorState = false;
    let prevValue = historyDate2;
    const regex = new RegExp("[0-9]|/");
    if (event.target.value.length === 8) return;
    if (regex.test(event.target.value.charAt(event.target.value.length - 1))) {
      if (event.target.value.length === 2) {
        if (
          event.target.value.endsWith("/") &&
          event.target.value.indexOf("/") ===
          event.target.value.lastIndexOf("/")
        ) {
          setHistoryDate2 && setHistoryDate2("0" + event.target.value);
        } else {
          if (historyDate2.length < 2 && event.target.value.length === 2) {
            //auto add forward slash
            setHistoryDate2 && setHistoryDate2(event.target.value.substring(0, 2) + "/");
          } else if (event.target.value.length < historyDate2.length) {
            //delete character case
            setHistoryDate2 && setHistoryDate2(event.target.value.substring(0, 1));
          }
        }
        if (
          event.target.value.length > 0 &&
          (event.target.value < 1 || event.target.value > 12)
        ) {
          setDisplayInvalidHistoryMonthMsg2(true);
          errorState = true;
        } else {
          setDisplayInvalidHistoryMonthMsg2(false);
        }
      } else if (
        event.target.selectionStart === 3 &&
        event.target.value.length > 3
      ) {
        //user types number just before / position
        let tempDate = event.target.value.replace("/", "");
        setHistoryDate2 && setHistoryDate2(
          tempDate.slice(0, 2) +
          "/" +
          tempDate.slice(3, event.target.value.length)
        );
      } else if (event.target.value.length >= 7) {
        setDisplayInvalidHistoryMonthMsg2(
          event.target.value.substring(0, 2) < 1 ||
          event.target.value.substring(0, 2) > 12
        );
        setHistoryDate2 && setHistoryDate2(event.target.value.substring(0, 7));
        if (
          event.target.value.substring(3, 7) < 1900 ||
          event.target.value.substring(3, 7) > 2100
        ) {
          setDisplayInvalidHistoryYearMsg2(true);
          errorState = true;
        } else {
          setDisplayInvalidHistoryYearMsg2(false);
        }
      } else if (
        event.target.value.indexOf("/") !== event.target.value.lastIndexOf("/")
      ) {
        setHistoryDate2 && setHistoryDate2(
          event.target.value.substring(0, event.target.value.indexOf("/") + 1)
        );
      } else if (
        event.target.value.length === 1 &&
        event.target.value === "/"
      ) {
        console.log("");
      } else {
        setHistoryDate2 && setHistoryDate2(event.target.value);
      }
    } else if (event.target.value.length === 0) {
      setDisplayInvalidHistoryYearMsg2(false);
      setDisplayInvalidHistoryMonthMsg2(false);
      setHistoryDate2 && setHistoryDate2(event.target.value);
    } else {
      setHistoryDate2 && setHistoryDate2(prevValue);
    }
    setDateHasErrors(errorState || toHasErrors || fromHasErrors || historyDateHasErrors);
  };

  const historyDateLengthHandler = (event: any) => {
    setIsDirty(true);
    if (event.target.value.length > 0) {
      dispatch(addHistoryDateRequiredErrorMessage(false))
    }
    if (event.target.value.length > 0 && event.target.value.length < 7) {
      setDisplayInvalidHistoryLengthMsg(true);
      setDateHasErrors(true);
    } else {
      setDisplayInvalidHistoryLengthMsg(false);
    }
  };

  const historyDateLengthHandler2 = (event: any) => {
    setIsDirty2(true);

    if (event.target.value.length > 0) {
      dispatch(addHistoryDateRequiredErrorMessage(false))
    }
    if (event.target.value.length > 0 && event.target.value.length < 7) {
      setDisplayInvalidHistoryLengthMsg2(true);
      setDateHasErrors(true);
    } else {
      setDisplayInvalidHistoryLengthMsg2(false);
    }
  };

  const handleFromDateChange = (event: any) => {
    let errorState = false;
    let utcTime = Date.parse(event.target.value);
    let fDate = new Date(utcTime);
    let tDate = new Date(toDate);
    let minimumDate = new Date(minDate);
    let maximumDate = new Date(maxDate);
    let validDate = isValidDate(fDate);
    let splitDate = ''
    if (event.target.value) {
      const orgDateValue = event.target.value.split("-");
      splitDate = `${orgDateValue[0]}-${orgDateValue[1]}-${orgDateValue[2]}`;
    }
    if (validDate && toDate?.length > 0) {
      dispatch(addDateRequiredErrorMessage(false))
    }
    setFromDate(splitDate);
    if (datesPopulated(event.target.value, toDate)) {
      setDisplayFromDateNotPopulatedMsg(false);
      if (
        validDate &&
        validDateFormat(event.target.value) &&
        fDate >= minimumDate &&
        fDate <= maximumDate &&
        refFromDate.current?.datevalue !== null
      ) {
        setDisplayInvalidFromDateMsg(false);
        pageName === 'NationalPrograms' ? setDateRangeRequiredMsg(false) : setDisplayFromDateNotPopulatedMsg(false);
      } else {
        setDisplayInvalidFromDateMsg(true);
        errorState = true;
      }
      if (tDate < fDate && validDateFormat(event.target.value)) {
        setDisplayFromDateToLateMsg(true);
        errorState = true;
      } else {
        setDisplayToDateToEarlyMsg(false);
        setDisplayFromDateToLateMsg(false);
        if (
          !displayInvalidToDateMsg &&
          !displayToDateOutOfRangeMsg &&
          !displayToDateNotPopulatedMsg
        )
          setToHasErrors(false);
      }
      fDate.setFullYear(fDate.getFullYear() + 5);
      fDate.setFullYear(fDate.getFullYear() - 5);
      tDate.setFullYear(tDate.getFullYear() - 5);
      if (userAccountIdentification.accountIdentificationMethod === accountIdentificationOptions.ProducerCode && fDate < tDate && pageName !== 'NationalPrograms') {
        setDisplayFromDateOutOfRangeMsg(true)
        errorState = true;
      } else if(hasNationalProgramsGroupMemberLossRun){
        tDate.setFullYear(tDate.getFullYear() - 2);
        if(fDate < tDate){
          setDisplayGMLRFromDateOutOfRangeMsg(true);
          errorState = true;
        }else{
          setDisplayGMLRFromDateOutOfRangeMsg(false);
          setDisplayGMLRToDateOutOfRangeMsg(false);
          errorState = false;
          setToHasErrors(false);
        }
      }else {
        setDisplayFromDateOutOfRangeMsg(false);
        setDisplayGMLRToDateOutOfRangeMsg(false);
        if (displayToDateOutOfRangeMsg) {
          setDisplayToDateOutOfRangeMsg(false);
          setToHasErrors(false);
        }
      }
    } else {
      clearDateErrors();
      setDisplayFromDateNotPopulatedMsg(true);
      pageName === 'NationalPrograms' ? setDateRangeRequiredMsg(true) : setDisplayToDateNotPopulatedMsg(true);
      errorState = true;
      setToHasErrors(true);
    }
    if (errorState) scrollToElement();
    setFromHasErrors(errorState);
    setDateHasErrors(errorState || displayInvalidToDateMsg || displayToDateOutOfRangeMsg || displayToDateNotPopulatedMsg || historyDateHasErrors || displayGMLRFromDateOutOfRangeMsg);
  };
  function clearDateErrors() {
    setDisplayToDateToEarlyMsg(false);
    setDisplayFromDateToLateMsg(false);
    setDisplayInvalidFromDateMsg(false);
    setDisplayFromDateOutOfRangeMsg(false);
    setDisplayInvalidToDateMsg(false);
    setDisplayToDateOutOfRangeMsg(false);
    setDisplayGMLRToDateOutOfRangeMsg(false);
    setDisplayGMLRFromDateOutOfRangeMsg(false);
    setDateHasErrors(false);
  }
  function datesPopulated(stringDateVal: string, dateVal: Date) {
    return (
      validDateFormat(stringDateVal) || validDateFormat(JSON.stringify(dateVal))
    );
  }
  function isValidDate(date: any) {
    return (
      date &&
      Object.prototype.toString.call(date) === "[object Date]" &&
      !isNaN(date)
    );
  }
  function validDateFormat(targetVal: string) {
    const regex = new RegExp("[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]");
    return regex.test(JSON.stringify(targetVal));
  }
  const handleToDateChange = (event: any) => {
    let errorState = false;
    let utcTime = Date.parse(event.target.value);
    let changedDate = new Date(utcTime);
    let fDate = new Date(fromDate);
    let tDate = new Date(utcTime);
    let validDate = isValidDate(changedDate);
    let minimumDate = new Date(minDate);
    let maximumDate = new Date(maxDate);
    let splitDate = ''
    if (validDate && fromDate?.length > 0) {
      dispatch(addDateRequiredToErrorMessage(false))
      dispatch(addDateRequiredErrorMessage(false))
    }
    if (event.target.value) {
      const orgDateValue = event.target.value.split("-");
      splitDate = `${orgDateValue[0]}-${orgDateValue[1]}-${orgDateValue[2]}`;
    }
    setToDate(splitDate);
    if (datesPopulated(event.target.value, fromDate)) {
      setDisplayToDateNotPopulatedMsg(false);
      if (
        validDate &&
        validDateFormat(event.target.value) &&
        tDate >= minimumDate &&
        tDate <= maximumDate &&
        refToDate.current?.datevalue !== null
      ) {
        setDisplayInvalidToDateMsg(false);
        pageName === 'NationalPrograms' ? setDateRangeRequiredMsg(false) : setDisplayFromDateNotPopulatedMsg(false);
      } else {
        setDisplayInvalidToDateMsg(true);
        errorState = true;
      }
      if (tDate < fDate && validDateFormat(event.target.value)) {
        setDisplayToDateToEarlyMsg(true);
        errorState = true;
      } else {
        setDisplayToDateToEarlyMsg(false);
        setDisplayFromDateToLateMsg(false);
      }
      fDate.setFullYear(fDate.getFullYear() + 5);
      fDate.setFullYear(fDate.getFullYear() - 5);
      tDate.setFullYear(tDate.getFullYear() - 5);
      if (userAccountIdentification.accountIdentificationMethod === accountIdentificationOptions.ProducerCode && fDate < tDate && pageName !== 'NationalPrograms') {
        setDisplayToDateOutOfRangeMsg(true)
        errorState = true;
      } else if (hasNationalProgramsGroupMemberLossRun) {
        tDate.setFullYear(tDate.getFullYear() - 2);
        if(fDate < tDate){
          setDisplayGMLRToDateOutOfRangeMsg(true);
          errorState = true;
        }else{
          setDisplayGMLRFromDateOutOfRangeMsg(false);
          setDisplayGMLRToDateOutOfRangeMsg(false);
          errorState = false;
          setFromHasErrors(false);
        }
      } else {
        setDisplayGMLRToDateOutOfRangeMsg(false);
        setDisplayToDateOutOfRangeMsg(false);
        setDisplayGMLRToDateOutOfRangeMsg(false);
        setDisplayGMLRFromDateOutOfRangeMsg(false);
        setDisplayFromDateOutOfRangeMsg(false);
        setFromHasErrors(false);
      }
    } else {
      clearDateErrors();
      setDisplayToDateNotPopulatedMsg(true);
      pageName === 'NationalPrograms' ? setDateRangeRequiredMsg(true) : setDisplayFromDateNotPopulatedMsg(true);
      errorState = true;
      setToHasErrors(true);
    }

    if (errorState) scrollToElement();
    setToHasErrors(errorState);
    setDateHasErrors(errorState || displayInvalidFromDateMsg || displayGMLRFromDateOutOfRangeMsg || displayFromDateOutOfRangeMsg || displayFromDateNotPopulatedMsg || (userAccountIdentification.accountIdentificationMethod === accountIdentificationOptions.ProducerCode && fDate < tDate && pageName !== 'NationalPrograms') || historyDateHasErrors);
  };


  return (
    <StackContainer
      onClick={() => {
        dispatch(addSuccessMessage(""));
      }}
      stackProps={{ width: "100%" }}
      spacing={8}
      direction="row"
      title="Date Selection Criteria"
    >
      <FormControl ref={ref}>
        <FormLabel
          aria-label="form_label"
          color={toHasErrors || fromHasErrors ? "#874600" : "black"}
          textStyle="label"
        >
          Accident Date Range (MM/DD/YYYY)
        </FormLabel>
        <Stack
          direction={["column", "row", "row"]}
          justifyItems="stretch"
          alignItems="start"
        >
          <TdsField
            aria-label="from_date_field"
            alert={fromHasErrors ? "error" : "false"}
          >
            <span aria-hidden="true" slot="label"></span>
            <TdsDateInput
              name="from-date-input"
              ref={refFromDate}
              aria-label="dl_from_input_date_selection"
              data-testid="date_input_start"
              value={fromDate}
              min={minDate}
              max={maxDate}
              onTdsBlur={handleFromDateChange}
              className={displayInvalidFromDateMsg ? "tds-field-invalid" : ""}
            />
            <Box maxW="200">
              <div
                data-testid="date_error"
                className="tds-field--error"
                style={{ marginBottom: 0 }}
              >
                <div
                  data-testid="invalid_date"
                  id="date-selection-invalid-from-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!displayInvalidFromDateMsg}
                >
                  <p style={{ fontSize: "14px" }}>Invalid date</p>
                </div>
                <div
                  data-testid="invalid_from_date"
                  id="date-selection-invalid-from-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!displayFromDateToLateMsg}
                >
                  <p style={{ fontSize: "14px" }}>
                    To Date must be equal to or after From Date
                  </p>
                </div>
                <div
                  data-testid="range_too_long"
                  id="date-selection-invalid-from-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!displayFromDateOutOfRangeMsg}
                >
                  <p style={{ fontSize: "14px" }}>
                    Accident date range should not exceed a 5 year period
                  </p>
                </div>
                <div
                  data-testid="range_too_long"
                  id="date-selection-invalid-from-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!displayGMLRFromDateOutOfRangeMsg}
                >
                  <p style={{ fontSize: "14px" }}>
                  Accident date range should not exceed a 7 year period
                  </p>
                </div>
                
                <div
                  data-testid="date_range_required"
                  id="date-selection-invalid-from-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!(dateRequiredErrorMessage || displayFromDateNotPopulatedMsg || displayToDateNotPopulatedMsg || dateRangeRequiredMsg)}
                >
                  <p style={{ fontSize: "14px" }}>Date range required</p>
                </div>
              </div>
            </Box>
          </TdsField>
          <Text as="h4" textStyle="h4" pt={4}>
            TO
          </Text>
          <TdsField
            aria-label="to_date_field"
            alert={toHasErrors ? "error" : "false"}
          >
            <span aria-hidden="true" slot="label"></span>
            <TdsDateInput
              name="to-date-input"
              ref={refToDate}
              aria-label="dl_to_input_date_selection"
              data-testid="date_input_end"
              value={toDate}
              min={minDate}
              max={maxDate}
              onTdsBlur={handleToDateChange}
              className={displayInvalidFromDateMsg ? "tds-field-invalid" : ""}
            />
            <Box maxW="200">
              <div className="tds-field--error" style={{ marginBottom: 0 }}>
                <div
                  id="date-selection-invalid-from-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!displayInvalidToDateMsg}
                >
                  <p style={{ fontSize: "14px" }}>Invalid date</p>
                </div>
                <div
                  id="date-selection-invalid-from-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!displayToDateToEarlyMsg}
                >
                  <p style={{ fontSize: "14px" }}>
                    To Date must be equal to or after From Date
                  </p>
                </div>
                <div
                  id="date-selection-invalid-from-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!displayToDateOutOfRangeMsg}
                >
                  <p style={{ fontSize: "14px" }}>
                    Accident date range should not exceed a 5 year period
                  </p>
                </div>
                <div
                  id="date-selection-invalid-gmlrt-to-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!displayGMLRToDateOutOfRangeMsg}
                >
                  <p style={{ fontSize: "14px" }}>
                  Accident date range should not exceed a 7 year period
                  </p>
                </div>
                <div
                  id="date-selection-invalid-from-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!(displayToDateNotPopulatedMsg || dateRequiredToErrorMessage)}
                >
                  <p style={{ fontSize: "14px" }}>Date range required</p>
                </div>
              </div>
            </Box>
          </TdsField>
        </Stack>
      </FormControl>
      {!blankHistoryDate &&
        <FormControl width={hasDouble ? "80%" : "40%"}>
          <FormLabel
            color={(
              isDirty &&
              displayInvalidHistoryMonthMsg &&
              userAccountIdentification.accountIdentificationMethod !==
              accountIdentificationOptions.ProducerCode
            ) ? "#874600" : "black"}
            textStyle="label"
          >
            {hasDouble ? "History Dates (MM/YYYY)" : "History Date (MM/YYYY)"}{" "}
          </FormLabel>
          <Stack mt={3} direction="row">
            <Box width={150}>
              <TdsField
                aria-label="history_date_field"
                alert={
                  (
                    isDirty &&
                    displayInvalidHistoryMonthMsg &&
                    userAccountIdentification.accountIdentificationMethod !==
                    accountIdentificationOptions.ProducerCode
                  )
                    ? "error"
                    : "false"
                }
              >
                <input
                  type="text"
                  style={{ height: "40px" }}
                  aria-label="dl_history_date_selection"
                  data-testid="history_date"
                  color={
                    
                    (
                      isDirty &&
                      (displayInvalidHistoryMonthMsg || displayInvalidHistoryYearMsg) &&
                      userAccountIdentification.accountIdentificationMethod !==
                      accountIdentificationOptions.ProducerCode
                    )
                      ? "#EF7A00"
                      : "black"
                  }
                  size={6}
                  onChange={historyDateHandler}
                  onBlur={historyDateLengthHandler}
                  value={historyDate}
                  disabled={
                    disabledProducer &&
                    userAccountIdentification.accountIdentificationMethod ===
                    accountIdentificationOptions.ProducerCode
                  }
                ></input>
                <div className="tds-field--error" style={{ marginBottom: 0 }}>
                  <div
                    id="date-selection-invalid-from-date"
                    aria-atomic="true"
                    className="tds-field__message"
                    role="alert"
                    hidden={!(!bothInvalid && historyDateRequiredErrorMessage)}
                  >
                    <p style={{ fontSize: "14px" }}>History date required </p>
                  </div>
                  <div
                    data-testid="invalid_month"
                    id="date-selection-invalid-from-date"
                    aria-atomic="true"
                    className="tds-field__message"
                    role="alert"
                    hidden={
                      !(
                        isDirty &&
                        displayInvalidHistoryMonthMsg &&
                        userAccountIdentification.accountIdentificationMethod !==
                        accountIdentificationOptions.ProducerCode
                      )
                    }
                  >
                    <p style={{ fontSize: "14px" }}>Invalid month</p>
                  </div>
                  <div
                    id="date-selection-invalid-from-date"
                    aria-atomic="true"
                    className="tds-field__message"
                    role="alert"
                    data-testid="invalid_year"
                    hidden={
                      !(
                        isDirty &&
                        displayInvalidHistoryYearMsg &&
                        userAccountIdentification.accountIdentificationMethod !==
                        accountIdentificationOptions.ProducerCode
                      )
                    }
                  >
                    <p style={{ fontSize: "14px" }}>Invalid year</p>
                  </div>
                  <div
                    id="date-selection-invalid-from-date"
                    aria-atomic="true"
                    className="tds-field__message"
                    role="alert"
                    data-testid="invalid_format"
                    hidden={
                      !(
                        isDirty &&
                        displayInvalidHistoryLengthMsg &&
                        userAccountIdentification.accountIdentificationMethod !==
                        accountIdentificationOptions.ProducerCode
                      )
                    }
                  >
                    <p style={{ fontSize: "14px" }}>
                      Date must be in MM/YYYY format
                    </p>
                  </div>
                  <div
                    id="date-selection-invalid-from-date"
                    aria-atomic="true"
                    className="tds-field__message"
                    role="alert"
                    data-testid="invalid_format_both_history_required"
                    hidden={
                      !((bothInvalid || isInValidSecond) && (!disabledProducer || userAccountIdentification.accountIdentificationMethod !== accountIdentificationOptions.ProducerCode))
                    }
                  >
                    <p style={{ fontSize: "14px" }}>
                      Both History Dates required
                    </p>
                  </div>

                </div>
              </TdsField>
            </Box>
            {hasDouble &&
              <Box width={150}>
                <TdsField
                  aria-label="history_date_field"
                  alert={
                    isDirty2 &&
                      (displayInvalidHistoryMonthMsg2 ||
                        displayInvalidHistoryYearMsg2 ||
                        displayInvalidHistoryLengthMsg2)
                      ? "error"
                      : "false"
                  }
                >
                  <input
                    type="text"
                    style={{ height: "40px" }}
                    aria-label="dl_history_date_selection"
                    data-testid="history_date2"
                    color={
                      displayInvalidHistoryMonthMsg2 ||
                        displayInvalidHistoryYearMsg2 ||
                        displayInvalidHistoryLengthMsg2
                        ? "#EF7A00"
                        : "black"
                    }
                    size={6}
                    onChange={historyDateHandler2}
                    onBlur={historyDateLengthHandler2}
                    value={historyDate2}
                    disabled={disabledProducer &&
                      userAccountIdentification.accountIdentificationMethod ===
                      accountIdentificationOptions.ProducerCode
                    }
                  ></input>
                  <div className="tds-field--error" style={{ marginBottom: 0 }}>
                    <div
                      data-testid="invalid_month"
                      id="date-selection-invalid-from-date"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      hidden={
                        !(
                          isDirty2 &&
                          displayInvalidHistoryMonthMsg2 &&
                          userAccountIdentification.accountIdentificationMethod !==
                          accountIdentificationOptions.ProducerCode
                        )
                      }
                    >
                      <p style={{ fontSize: "14px" }}>Invalid month</p>
                    </div>
                    <div
                      id="date-selection-invalid-from-date"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      data-testid="invalid_year"
                      hidden={
                        !(
                          isDirty2 &&
                          displayInvalidHistoryYearMsg2 &&
                          userAccountIdentification.accountIdentificationMethod !==
                          accountIdentificationOptions.ProducerCode
                        )
                      }
                    >
                      <p style={{ fontSize: "14px" }}>Invalid year</p>
                    </div>
                    <div
                      id="date-selection-invalid-from-date"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      data-testid="invalid_format2"
                      hidden={
                        !(
                          isDirty2 &&
                          displayInvalidHistoryLengthMsg2 &&
                          userAccountIdentification.accountIdentificationMethod !==
                          accountIdentificationOptions.ProducerCode
                        )
                      }
                    >
                      <p style={{ fontSize: "14px" }}>
                        Date must be in MM/YYYY format
                      </p>
                    </div>
                    <div
                      id="date-selection-invalid-from-date"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      data-testid="invalid_format_both_history"
                      hidden={
                        !(
                          !bothInvalid &&
                          isDirty2 &&
                          greaterError &&
                          userAccountIdentification.accountIdentificationMethod !==
                          accountIdentificationOptions.ProducerCode
                        )
                      }
                    >
                      <p style={{ fontSize: "14px" }}>
                        Second History Date must be greater than first History Date.
                      </p>
                    </div>

                  </div>
                </TdsField>
              </Box>
            }
          </Stack>
        </FormControl>
      }
    </StackContainer>
  );
};
export default DateSelectionCriteria;
