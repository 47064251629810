import { ReactComponent as QuestionCircle118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/question-circle-1-18.svg';
import { Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAccountErrorMessage, setSubmitClicked, showClaimActivityErrorMessage } from "../../features/claimActivity/claimActivityErrorMsgSlice";
import useAuth from "../../hooks/useAuth";
import { RootState } from "../../store";
import PolicyNumber from "./AccountIdentification/PolicyNumber";
import AgentAccountSearch from "./AgentAccountSearch/AgentAccountSearch";
import { AccIdentification } from "./AgentAccountSearch/agentAccount";
import ClaimActivityTable from "./ClaimActivity/ClaimActivityTable";
import { useSubmitParametersMutation } from "../../features/submitReport/submitReportApiSlice";
import { ReportData } from "../../common/types";
import { useGetPrefillDataQuery } from "../../features/utils/prefillApiSlice";
import { getHistoryDate } from "../../features/utils/utils";
import moment from "moment";
import { AppContext } from "../../context/appContext";
import { TdsLoadingSpinner } from "@trv-tds/react";
import { useGetOrganizationLevelDataQuery } from "../../features/utils/filterLookupApiSlice";
import useClaimActivityListData from "../../hooks/useClaimActivityListData";
import useLocalStorage from "../../hooks/useLocalStorage";

const HomeForm = () => {
  const dispatch = useDispatch()
  const { isAgentUser, isInternalUser, isAccountLocationUser, isAccountId, profileData } = useAuth();
  const [showClaimActivityTable, setShowClaimActivityTable] = useState<boolean>(false);
  const [ showPolicySummary, setShowPolicySummary ] = useState<boolean>(false);
  const [ errorProducerCode, setErrorProducerCode ] = useState<boolean>(false);
  const [ enableOneClickReport, setEnableOneClickReport ] = useState<boolean>(false);
  const [reportDataSummary, setReportDataSummary] = useLocalStorage<any>("reportDataSummary", {});

  useClaimActivityListData();
  
  const userAccountIdentification: AccIdentification = useSelector(
    (state: RootState) => state.agentAccount.accountIdentification
  );
  
  const limitingCriteria = {
    LimitingCriteria: {
      Selection:
        userAccountIdentification.accountIdentificationSelections.length > 0
          ? userAccountIdentification.accountIdentificationSelections[0]
          : "",
    },
  };
  const { accountIdentificationSelections } = userAccountIdentification;
  const [hasBeenClicked, setHasBeenClicked] = useState<boolean>(false);
  const [currentlyShownItems, setCurrentlyShownItems] = useState<string[]>([]);

  const [historyDate, setHistoryDate] = useState<string>("");
  // const [orgLevelData, setOrgLevelData] = useState<any>();
  const [acctOrgLevelData, setAcctOrgLevelData] = useState<any>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { claimActivityErrorMessage: claimActivityListError, showClaimActivityError } = useSelector((state: RootState) => state.claimActivity);

  const defaultAppConfig = { REACT_APP_ST_CLAIM_REPORT_URL: undefined }
  const { appConfig: { REACT_APP_ST_CLAIM_REPORT_URL } = defaultAppConfig } = useContext(AppContext)

  const defaultAppDomainConfig = { REACT_APP_DOMAIN_URL: undefined }
  const { appConfig: { REACT_APP_DOMAIN_URL } = defaultAppDomainConfig } = useContext(AppContext);

  const [postReportData] = useSubmitParametersMutation();

  // const { Headers, AccountEffectiveDate, AccountNumber } = orgLevelData;
  const {
    data: orgLevelData,
    isSuccess: isOrgSuccess,
    isLoading: isOrgLoading,
    isError: isOrgError,
    error: orgError,
  } = useGetOrganizationLevelDataQuery(limitingCriteria, {
    skip: claimActivityListError !== "",
  });


  useEffect(() => {
    dispatch(showClaimActivityErrorMessage(false))
  }, [])

  useEffect(() => {
    if (
      (isAgentUser || isInternalUser) &&
      accountIdentificationSelections.length > 0 &&
      accountIdentificationSelections !== currentlyShownItems
    ) {
      setShowClaimActivityTable(false);
    }
    if (
      isAccountId ||
      isAccountLocationUser
    ) {
      setShowClaimActivityTable(true);
    } else if (
      (isAgentUser || isInternalUser) &&
      accountIdentificationSelections.length === 0
    ) {
      setShowClaimActivityTable(false);
      setHasBeenClicked(false);
    }
    if (accountIdentificationSelections.length === 0) {
      setErrorProducerCode(false);
    }
  }, [accountIdentificationSelections, profileData]);

  const lossCriteria = {
    UserInformation: profileData[0],
  };

  let shouldSkip = (profileData === undefined || profileData === null || profileData?.length === 0) ||
    (profileData?.length > 0 && (
      profileData[0]?.Role.IsInternalUser ||
      (profileData[0]?.Role.IsAccountId === false && profileData[0]?.Role.IsAccountLocationId === false)
  ));

  const {
    data: prefill_data,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetPrefillDataQuery(lossCriteria, {
    skip: shouldSkip
  });

  useEffect(() => {
    if (
      prefill_data &&
      prefill_data.result &&
      prefill_data.result.Selections &&
      !isLoading
    ) {
      setAcctOrgLevelData(prefill_data.result);
      if (profileData[0]?.Role.IsBroker === true || profileData[0]?.Role.IsAgent === true || profileData[0]?.Role.IsAccountLocationId === true) {
        setEnableOneClickReport(true);
      }
    }
  }, [prefill_data, isLoading, profileData]);

  function buttonClick() {
    dispatch(showClaimActivityErrorMessage(true))
    setCurrentlyShownItems(accountIdentificationSelections);
    setHasBeenClicked(true);
    setShowClaimActivityTable(true);
    if (claimActivityListError !== "") {
      scrollToTop()
    }
  }

  function getOrgLevelData() {
    if (prefill_data && prefill_data.result && prefill_data.result.Orgs) {
      return prefill_data.result.Orgs;
    } else {
      return orgLevelData;
    }
  }

  function getSelections() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (
        profileData[0].Role.IsAccountId === true ||
        profileData[0].Role.IsAccountLocationId === true
      ) {
        if (
          prefill_data &&
          prefill_data.result &&
          prefill_data.result.Selections
        ) {
          return prefill_data.result.Selections;
        }
      } else {
        return accountIdentificationSelections;
      }
    }
  }

  function getAccountIdType() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (
        profileData[0].Role.IsAccountId === true ||
        profileData[0].Role.IsAccountLocationId === true
      ) {
        if (prefill_data && prefill_data.result)
          return prefill_data ? prefill_data.result.AccountIdType : "";
      } else {
        if (
          userAccountIdentification.accountIdentificationMethod ===
          "Policy Number"
        )
          return 2;
        else if (
          userAccountIdentification.accountIdentificationMethod ===
          "Account Number"
        )
          return 1;
        else if (
          userAccountIdentification.accountIdentificationMethod === "SAI Number"
        )
          return 4;
        else if (
          userAccountIdentification.accountIdentificationMethod ===
          "Producer Code"
        )
          return 3;
        else return -1;
      }
    }
  }

  function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  };

  async function detailLossClick(year: number, reportName: string, reportType: number) {
    dispatch(showClaimActivityErrorMessage(true))
    setErrorProducerCode(false);
    if (claimActivityListError !== "") {
      if (getAccountIdType() === 3 && reportName == 'Policy Summary' && accountIdentificationSelections.length > 0) {
        setErrorProducerCode(true);
        return false;
      }
      setErrorProducerCode(false);
      scrollToTop();
      dispatch(setSubmitClicked(true));
      return true;
    } else if (getAccountIdType() === 3 && reportName == 'Policy Summary' && accountIdentificationSelections.length > 0) {
      setErrorProducerCode(true);
      return false;
    } else {
      if (accountIdentificationSelections.length === 0 && (profileData[0]?.Role.IsInternalUser === true || (isAccountId !== true && enableOneClickReport !== true))) {
        dispatch(
          addAccountErrorMessage(
            "Please enter an account identifier to run the report."
          )
        );
        return false;
      }
      let effYearFrom: number = 0;
      let effYearTo: number = 0;
      let showPolicySummary: boolean = false;
      if (reportName === 'Policy Summary') {
        // setShowPolicySummary(true);
        showPolicySummary = true;
        effYearFrom = Number.parseInt(moment(subtractYears(new Date(), year)).format('YYYY'));
        effYearTo = Number.parseInt(moment(new Date()).format('YYYY'));
      }

      const reportData: ReportData = {
        LossesCriteria: {
          LargeLossValue: '',
          LargeLossType: 0,
          ClaimLimitDollarType: 0,
          ClaimLimitOperator: null,
          BeginDollarAmount: null,
          EndDollarAmount: null,
          Orgs: showPolicySummary ? null : await getOrgLevelData(),
          EffYearFrom: (showPolicySummary) ? effYearFrom : 0,
          EffYearTo: (showPolicySummary) ? effYearTo : 0,
          RankFieldName: "",
          Limits: [],
          ReportCategory: 0,
          AccountName: "",
          Selections: await getSelections(),
          FromDate: showPolicySummary ? '0001-01-01T00:00:00' : moment(subtractYears(new Date(), year)).format('YYYY-MM-DD'),
          ToDate: showPolicySummary ? '0001-01-01T00:00:00' : moment(new Date()).format('YYYY-MM-DD'),
          Heading: '',
          OutputFormat: 0,
          ReportType: reportType,
          AccountIdType: await getAccountIdType(),
          SortGroups: [],
          BaseUrl: reportType === 4 ? REACT_APP_DOMAIN_URL + '?page=DrilldownSummary&' : REACT_APP_ST_CLAIM_REPORT_URL,
          ShowDrilldown: true,
          ReportName: reportName,
          ParamId: 0,
          InsuredRetentionType: 0,
        },
        UserInformation:
          profileData && profileData.length ? profileData[0] : undefined,
      };

      await setReportDataSummary(reportData);

      const base64toBlob = (data: string) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
        const bytes = atob(base64WithoutPrefix);
        let length: number = bytes.length;
        let result: any = new Uint8Array(length);
  
        while (length--) {
            result[length] = bytes.charCodeAt(length);
        }
  
        const pdfType: any = new Blob([result], { type: 'application/pdf' });
        return pdfType
  
      };
  
      console.log('Detail Loss Report', reportData);
      setSubmitLoading(true);
      const { data }: any = await postReportData(reportData);
      const moreThanSixMb: number = Number(data?.messages[0].code) || 0;
      const windowParams = `left=0, top=400, toolbar=0, status=0, popup=1, width=${screen.width + (-200)}, height=${window.innerHeight + (-100)}`
      if (data && data.result) {
          if (moreThanSixMb == 1) {
              console.log('Report file size is more than 6 MB. Drilldown....!');
              const pdfURL: string = data.result;
              window.open(pdfURL, 'oneClickReport', windowParams);
              setSubmitLoading(false);
          } else {
              const blob = base64toBlob(`data:application/pdf;base64, ${data.result}`);
              const fileURL = window.URL.createObjectURL(blob);
              console.log('URL ::', fileURL.substring(5));
              window.open(fileURL, 'oneClickReport', windowParams);
              setSubmitLoading(false);
          }
      }
    }
    
  }

  const scrollToTop = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  return (
    <div>
      {submitLoading && <TdsLoadingSpinner label="Processing" size="xxx-large" label-displayed overlay centered>Processing...</TdsLoadingSpinner>}
      <PolicyNumber
        clearMsg={false}
        setClearMsg={() => { }}
        clearedCriteriaMsg={false}
        setClearedCriteriaMsg={() => { }}
        hasCustomFilters={false}
      />
      <AgentAccountSearch />
      <div className="homeform-inner-div-one-click-reports">
        <Text as="h3" textStyle="h3">
          One-Click Reports &nbsp;
          <div id='tooltip-example-top' className='docs-tooltip-sample' style={{ display: 'inline' }}>
            <span data-toggle='tooltip' role='button' tabIndex={0} className='tds-tooltip__container' aria-label='more information'>
              <QuestionCircle118 className='tds-icon-18' data-toggle='tooltip' color='#007395' />
              <div role='tooltip' className='tds-tooltip' data-position='bottom' style={{ height: '60px', width: 'auto' }}>
                <p style={{ fontWeight: '300', textAlign: 'left', lineHeight: '1.6' }}>
                  These links generate reports using default parameters. <br></br> To customize, select a report from the left navigation menu.
                </p>
              </div>
            </span>
          </div>
        </Text>
        <p
          className="body-xsmall wireframe-p-font-italic"
          style={{ marginBottom: "1rem" }}
        >
          If report does not appear, disable pop-up blockers in browser settings.
        </p>
        {errorProducerCode && (accountIdentificationSelections.length === 0 || claimActivityListError === "") && (
          <div className='tds-field--error' style={{ marginBottom: '10px' }} hidden={errorProducerCode && (accountIdentificationSelections.length === 0 || claimActivityListError !== "")}>
            <div id="detail-loss-missing-report-name-error" aria-atomic='true' className='tds-field__message' role='alert'>
              <p style={{ fontSize: '0.8rem' }} >
                Report not available using Producer Code. Please identify account using SAI, Account or Policy Number.
              </p>
            </div>
          </div>
        )}

        {errorProducerCode && (accountIdentificationSelections.length > 0 && claimActivityListError !== "") && (
          <div className='tds-field--error' style={{ marginBottom: '10px' }} hidden={!errorProducerCode}>
            <div id="detail-loss-missing-report-name-error" aria-atomic='true' className='tds-field__message' role='alert'>
              <p style={{ fontSize: '0.8rem' }} >
                Report not available using Producer Code. Please identify account using SAI, Account or Policy Number.
              </p>
            </div>
          </div>
        )}
        

        <div className="tds-row one-click-reports-table">
          <div className="tds-col-md-3">
            <button 
              onClick={() => detailLossClick(5, 'Detail Loss', 0)}
              className={`tds-button--small button-one-click ${((accountIdentificationSelections.length > 0 || enableOneClickReport === true) || isAccountId === true)? 'tds-button--tertiary' : ''}`}
              type="button"
            >
              DETAIL LOSS
            <p className="wireframe-one-click-reports-p-font-regular">
              Latest 5 years
            </p>
            </button>
          </div>
          <div className="tds-col-md-3">
            <button
              onClick={() => detailLossClick(3, 'Detail Loss', 0)}
              className={`tds-button--small button-one-click ${((accountIdentificationSelections.length > 0 || enableOneClickReport === true) || isAccountId === true)? 'tds-button--tertiary' : ''}`}
              type="button"
            >
              DETAIL LOSS
            <p className="wireframe-one-click-reports-p-font-regular">
              Latest 3 years
            </p>
            </button>
          </div>
          <div className="tds-col-md-3">
            <button
              onClick={() => detailLossClick(3, 'Financial Summary', 4)}
              className={`tds-button--small button-one-click ${((accountIdentificationSelections.length > 0 || enableOneClickReport === true) || isAccountId === true)? 'tds-button--tertiary' : ''}`}
              type="button"
            >
              FINANCIAL SUMMARY
            <p className="wireframe-one-click-reports-p-font-regular">
              Latest 3 years
            </p>
            </button>
          </div>
          <div className="tds-col-md-3">
            <button
              onClick={() => detailLossClick(3, 'Policy Summary', 16)}
              className={`tds-button--small button-one-click ${((accountIdentificationSelections.length > 0 || enableOneClickReport === true) || isAccountId === true)? 'tds-button--tertiary' : ''}`}
              type="button"
            >
              POLICY SUMMARY
            <p className="wireframe-one-click-reports-p-font-regular">
              Latest 3 years
            </p>
            </button>
          </div>
        </div>
      </div>
      {(isAgentUser || isInternalUser || isAccountId) && (
        <div className="homeform-inner-div-claim-activity">
          <div className="homeform-claim-activity-header-div">
            <div className="homeform-claim-activity-inner-header-div-one">
              {(isAgentUser || isInternalUser || isAccountId) && (
                <Text as="h3" textStyle="h3" width={150}>
                  Claim Activity
                </Text>
              )}
            </div>
            <div className="homeform-claim-activity-inner-header-div-two">
              {!isAccountLocationUser && !isAccountId && !showClaimActivityTable && (
                <button
                  disabled={
                    accountIdentificationSelections.length === 0 &&
                    isInternalUser
                  }
                  onClick={() => buttonClick()}
                  style={{
                    verticalAlign: "top",
                    height: "2rem",
                    marginLeft: 50,
                  }}
                  className="tds-button--secondary tds-button--small"
                  type="button"
                  hidden={isInternalUser &&
                    (accountIdentificationSelections.length === 0 ||
                      (claimActivityListError !== "" && showClaimActivityError))}
                >
                  VIEW CLAIM ACTIVITY
                </button>
              )}
            </div>
          </div>

          <div className="homeform-claim-activity-p-div">
            {isInternalUser &&
              (accountIdentificationSelections.length === 0 ||
                claimActivityListError !== "") && (
                <p className="body-xsmall wireframe-p-font-italic">
                  Add at least one account identifier to enable claim activity
                  function.
                </p>
              )}
            {isAgentUser && !showClaimActivityTable && (
              <p className="body-xsmall wireframe-p-font-italic">
                Click “View Claim Activity” button to see activity for all
                accounts.  To view selected accounts only, add at least one
                account identifier above.
              </p>
            )}
          </div>
        </div>
      )}
      {(showClaimActivityTable || isAccountLocationUser) && (
        <ClaimActivityTable />
      )}
    </div>
  );
};

export default HomeForm;
