import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Input,
  InputGroup,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { TdsField } from "@trv-tds/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BLANK_GROUP,
  DEFAULT_FIELD_OPTION_EXCLUSIONS,
  NON_PRODUCER_FIELD_OPTIONS,
  accountIdentificationOptions,
} from "../../../common/constants";
import { AccountOrgLevelData, GroupOption } from "../../../common/types";
import { addSuccessMessage } from "../../../features/claimActivity/claimActivityErrorMsgSlice";
import { useGetOrganizationLevelDataQuery } from "../../../features/utils/filterLookupApiSlice";
import { DowndownOptions } from "../../../features/utils/utils";
import { useGetDropdownListQuery } from "../../../features/utils/utisApiSlice";
import { RootState } from "../../../store";
import { AccIdentification } from "../AgentAccountSearch/agentAccount";
import { AddButton } from "../Common/AddButton";
import { AlertMessage } from "../Common/AlertMessage";
import { LoadingSkeleton } from "../Common/LoadingSkeleton";
import { RemoveButton } from "../Common/RemoveButton";

type GroupBySectionProps = {
  groups: GroupOption[];
  setGroups: Dispatch<SetStateAction<GroupOption[]>>;
  onShowModal?: Function;
  sectionHasErrors?: boolean;
  activeGroup?: GroupOption;
  setGroupByHasErrors?: Dispatch<SetStateAction<boolean | undefined>>;
  setActiveGroup?: Dispatch<SetStateAction<GroupOption | undefined>>;
  setOrgLevelData?: Function;
  acctOrgLevelData?: AccountOrgLevelData;
  setAcctOrgLevelData?: Function;
  pageName?: String;
};

export const GroupBySection = ({
  groups,
  setGroups,
  sectionHasErrors,
  setGroupByHasErrors,
  setOrgLevelData,
  acctOrgLevelData,
  pageName,
}: GroupBySectionProps) => {
  const userAccountIdentification: AccIdentification = useSelector(
    (state: RootState) => state.agentAccount.accountIdentification
  );
  const {
    data: dropDownData,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetDropdownListQuery();
  const dispatch = useDispatch();
  const [acctNumOrgData, setAcctNumOrgData] = useState<string>("");
  const seen = new Set();
  const duplicateError = groups.some(function (currentObject) {
    return currentObject.field!=="" && seen.size === seen.add(currentObject.field).size;
  });
  const dropDownOptions = dropDownData?.option?.filter((el) => {
    return (
      (el._Type === "Both" || el._Type === "Sorting") &&
      el._text !== "Policy Form Code"
    );
  });
  const defaultOptions = dropDownOptions?.filter((el) => {
    return !DEFAULT_FIELD_OPTION_EXCLUSIONS.includes(el._text);
  });
  const accountNumberOptions = dropDownOptions?.filter((el) => {
    return el._text !== "SAI Number";
  });
  const SAINumberOptions = dropDownOptions?.filter((el) => {
    return el._text !== "Account Number";
  });
  const producerCodeOptions = defaultOptions?.filter(
    (field) => !NON_PRODUCER_FIELD_OPTIONS.includes(field._text)
  );

  const [limitFieldOptions, setLimitFieldOptions] = useState<
    DowndownOptions[] | null | undefined
  >(dropDownOptions);
  const lc = {
    LimitingCriteria: {
      Selection:
        userAccountIdentification.accountIdentificationSelections.length > 0
          ? userAccountIdentification.accountIdentificationSelections[0]
          : "",
    },
  };

  useEffect(() => {
    if (pageName && pageName === "DetailChanges") {
      let rso: DowndownOptions = {
        _value: "ST_CD1",
        _text: "Risk State Historical",
        _Type: "Both",
        _current: "T2",
        _history: "",
        _unquoted: "",
      };
      let findId = defaultOptions?.findIndex((e) => e._text === "Risk State Historical");
      if (limitFieldOptions === undefined || (limitFieldOptions && findId && findId < 0))
        //@ts-ignore
        defaultOptions?.splice(defaultOptions.findIndex((e) => e._text === "Risk State") + 1, 0, rso);
    }
    setLimitFieldOptions(defaultOptions);
  }, []);

  const { claimActivityErrorMessage: claimActivityListError } = useSelector(
    (state: RootState) => state.claimActivity
  );
  const {
    data: orgLevelData,
    isSuccess: isOrgSuccess,
    isLoading: isOrgLoading,
    isError: isOrgError,
    error: orgError,
  } = useGetOrganizationLevelDataQuery(lc, {
    skip: claimActivityListError !== "",
  });

  useEffect(() => {
    if(duplicateError!==undefined && setGroupByHasErrors){
      setGroupByHasErrors(duplicateError)
    }
    if (sectionHasErrors === undefined && setGroupByHasErrors !== undefined) {
      setGroupByHasErrors(true);
    }
  }, [sectionHasErrors, duplicateError]);

  useEffect(() => {
    if(duplicateError!==undefined && setGroupByHasErrors){
      setGroupByHasErrors(duplicateError)
    }
  }, [duplicateError]);

  useEffect(() => {
    if (!isLoading && isSuccess && dropDownData && !limitFieldOptions) {
      if (pageName && pageName === "DetailChanges") {
        let rso: DowndownOptions = {
          _value: "ST_CD1",
          _text: "Risk State Historical",
          _Type: "Both",
          _current: "T2",
          _history: "",
          _unquoted: "",
        };
        //@ts-ignore
        if (limitFieldOptions === undefined || (limitFieldOptions && limitFieldOptions.findIndex((e) => e._text === "Risk State Historical") < 0))
          //@ts-ignore
          defaultOptions?.splice(defaultOptions.findIndex((e) => e._text === "Risk State") + 1, 0, rso);
      }
      setLimitFieldOptions(defaultOptions);
    }
  }, [defaultOptions]);

  useEffect(() => {
    setGroups(
      groups?.map((_group, i) =>
        _group.label === "SAI Number" || _group.label === "Account Number" ? { ..._group, label: "", field: "", originalLabel: "", hasSubtotal: false, hasPageBreak: false } : _group
      )
    );
    if (dropDownOptions && dropDownOptions.length > 0) {
      let rso: DowndownOptions = {
        _value: "ST_CD1",
        _text: "Risk State Historical",
        _Type: "Both",
        _current: "T2",
        _history: "",
        _unquoted: "",
      };
      if (
        userAccountIdentification.accountIdentificationMethod ===
        accountIdentificationOptions.AccountNumber
      ) {
        if (pageName && pageName === "DetailChanges") {
          //@ts-ignore
          if (accountNumberOptions?.findIndex((e) => e._text === 'Risk State Historical') < 0) {
            let fIndex = accountNumberOptions?.findIndex((e) => e._text === 'Risk State');
            //@ts-ignore
            accountNumberOptions?.splice(fIndex + 1, 0, rso);
          }
        }
        setLimitFieldOptions(accountNumberOptions);
      } else if (
        userAccountIdentification.accountIdentificationMethod ===
        accountIdentificationOptions.SAINumber
      ) {
        if (pageName && pageName === "DetailChanges") {
          //@ts-ignore
          if (SAINumberOptions?.findIndex((e) => e._text === 'Risk State Historical') < 0) {
            let fIndex = SAINumberOptions?.findIndex((e) => e._text === 'Risk State');
            //@ts-ignore
            SAINumberOptions?.splice(fIndex + 1, 0, rso);
          }
        }
        setLimitFieldOptions(SAINumberOptions);
      } else if (
        userAccountIdentification.accountIdentificationMethod ===
        accountIdentificationOptions.ProducerCode
      ) {
        setLimitFieldOptions(producerCodeOptions);
      } else {
        if (pageName && pageName === "DetailChanges") {
          //@ts-ignore
          if (defaultOptions?.findIndex((e) => e._text === 'Risk State Historical') < 0) {
            let fIndex = defaultOptions?.findIndex((e) => e._text === 'Risk State');
            //@ts-ignore
            defaultOptions?.splice(fIndex + 1, 0, rso);
          }
        }
        setLimitFieldOptions(defaultOptions);
      }
    }
  }, [userAccountIdentification.accountIdentificationMethod]);

  useEffect(() => {
    if (limitFieldOptions?.find((e) => e._Type === "ORG") !== undefined) {
      let limitFieldReduced = accountNumberOptions?.filter((e) => e._Type !== "ORG");
      setLimitFieldOptions(accountNumberOptions);
    }
    setAcctNumOrgData(
      userAccountIdentification.accountIdentificationSelections.length > 0
        ? userAccountIdentification.accountIdentificationSelections[0]
        : ""
    );
    if (
      userAccountIdentification.accountIdentificationMethod ===
      "Account Number" &&
      userAccountIdentification.accountIdentificationSelections.length > 0
    ) {
      //first need to create downdownoptions structure and populate org level headers into the structure and then add to drop down
      if (
        !isOrgLoading &&
        isOrgSuccess &&
        !isOrgError &&
        orgLevelData &&
        limitFieldOptions
      ) {
        if (setOrgLevelData) setOrgLevelData(orgLevelData);
        const { Headers, AccountEffectiveDate, AccountNumber } = orgLevelData;
        setAcctNumOrgData(
          userAccountIdentification.accountIdentificationSelections[0]
        );
        let ddHeaders: DowndownOptions[] = [];
        //      console.log(Headers);
        for (let x = 0; x < Headers.length; x++) {
          if (Headers[x] !== null)
            ddHeaders[x] = {
              _text: Headers[x],
              _value: "ORG" + (x + 1),
              _Type: "ORG",
              _history: AccountEffectiveDate,
              _unquoted: AccountNumber,
              _current: "",
            };
        }
        let existingDDData = accountNumberOptions?.filter(
          (e) => e._Type !== "ORG"
        );
        for (let y = 0; y < ddHeaders.length; y++) {
          existingDDData?.splice(existingDDData.length + 1, 0, ddHeaders[y]);
        }
        if (pageName && pageName === "DetailChanges") {
          let rso: DowndownOptions = {
            _value: "ST_CD1",
            _text: "Risk State Historical",
            _Type: "Both",
            _current: "T2",
            _history: "",
            _unquoted: "",
          };
          let fIndex = existingDDData?.findIndex((e) => e._text === 'Risk State');
          //@ts-ignore
          if (existingDDData && existingDDData.findIndex((e) => e._text === "Risk State Historical") < 0)
            //@ts-ignore
            existingDDData?.splice(fIndex + 1, 0, rso);
        }

        setLimitFieldOptions(existingDDData);
      }
    } else if (userAccountIdentification.accountIdentificationMethod ===
      "Account Number" &&
      userAccountIdentification.accountIdentificationSelections.length === 0) {
      //remove org level data if there was a previous account ID selected and it was subsequently removed
      if (userAccountIdentification.accountIdentificationMethod === "Account Number") {
        if (pageName && pageName === "DetailChanges") {
          let rso: DowndownOptions = {
            _value: "ST_CD1",
            _text: "Risk State Historical",
            _Type: "Both",
            _current: "T2",
            _history: "",
            _unquoted: "",
          };
          //@ts-ignore
          if (accountNumberOptions?.findIndex((e) => e._text === 'Risk State Historical') < 0) {
            //@ts-ignore
            accountNumberOptions?.splice(accountNumberOptions.findIndex((e) => e._text === "Risk State") + 1, 0, rso);
          }
        }
        setLimitFieldOptions(accountNumberOptions);

      }
    }
  }, [orgLevelData]);

  useEffect(() => {
    if (limitFieldOptions && acctOrgLevelData) {
      let ddHeaders: DowndownOptions[] = [];
      if (acctOrgLevelData?.Orgs?.Headers) {
        for (let x = 0; x < acctOrgLevelData.Orgs.Headers.length; x++) {
          if (acctOrgLevelData.Orgs.Headers[x] !== null)
            ddHeaders[x] = {
              _text: acctOrgLevelData.Orgs.Headers[x].toString(),
              _value: "ORG" + (x + 1),
              _Type: "ORG",
              _history: "",
              _unquoted: "",
              _current: "",
            };
        }
      }
      let existingDDData = limitFieldOptions?.filter((e) => e._Type !== "ORG");
      existingDDData = existingDDData?.filter((el) => {
        return el._text !== "SAI Number" && el._text !== "Account Number";
      });
      if (acctOrgLevelData.AccountIdType === 1) {
        existingDDData?.splice(
          existingDDData.findIndex(
            (existingDDData) => existingDDData._text === "Accident Year"
          ) + 1,
          0,
          {
            _value: "SAILOR_ACCT_NBR",
            _text: "Account Number",
            _Type: "Sorting",
            _current: "T6",
            _history: "",
            _unquoted: "",
          }
        );
      } else if (acctOrgLevelData.AccountIdType === 4) {
        existingDDData?.splice(
          existingDDData.findIndex(
            (existingDDData) => existingDDData._text === "Risk State"
          ) + 1,
          0,
          {
            _value: "SAI_NBR",
            _text: "SAI Number",
            _Type: "Sorting",
            _current: "T6",
            _history: "",
            _unquoted: "",
          }
        );
      }
      for (let y = 0; y < ddHeaders.length; y++) {
        existingDDData?.splice(existingDDData.length + 1, 0, ddHeaders[y]);
      }

      setLimitFieldOptions(existingDDData);
    }
  }, [acctOrgLevelData]);

  function enableFilterLookup(fieldStr: String) {
    if (acctOrgLevelData && fieldStr !== "") {
      return true;
    }
    if (acctNumOrgData !== "" && fieldStr !== "") {
      const hasOrgType = limitFieldOptions?.filter(
        (a) => a._text === fieldStr && a._Type === "ORG"
      );
      if (hasOrgType && hasOrgType?.length > 0) {
        return true;
      }
    }
    return (
      fieldStr === "Accident Cause" ||
      fieldStr === "Accident Result" ||
      fieldStr === "Accident State" ||
      fieldStr === "Adjusting Office" ||
      fieldStr === "Benefit State" ||
      fieldStr === "Body Part" ||
      fieldStr === "Claim Status" ||
      fieldStr === "File Prefix" ||
      fieldStr === "Incident Indicator" ||
      fieldStr === "IPG Line of Insurance" ||
      fieldStr === "Line of Insurance" ||
      fieldStr === "Risk State" ||
      fieldStr === "Subline of Insurance"
    );
  }

  const addGroupBy = () => {
    if (groups.length < 5) {
      const highestNumber = Math.max(...groups.map((o) => o.groupKey || 0));
      const newBlank = { ...BLANK_GROUP, groupKey: highestNumber + 1 };
      setGroups([...groups, newBlank]);
    }
  };

  const removeGroup = (row: number) => {
    // removes group and re-orders group keys
    setGroups(
      groups
        .filter((_, num) => num !== row)
        .map((_group, index) => {
          return { ..._group, groupKey: index };
        })
    );
  };
  const resetGroup = (resetGroupByRow: GroupOption) => {
    dispatch(addSuccessMessage(""));
    setGroups([
      ...groups.map((group) => {
        if (group.groupKey === resetGroupByRow.groupKey) {
          return { ...BLANK_GROUP, groupKey: group.groupKey };
        } else {
          return group;
        }
      }),
    ]);
  };
  if (error) {
    console.error(error);
  }

  return isLoading ? (
    <LoadingSkeleton />
  ) : isError ? (
    <AlertMessage message={"Error loading groups"} />
  ) : (
    <Box>
      <Text as="h4" textStyle="h4">
        Group By
      </Text>
      <AddButton
        data-testid="add-group-button"
        onClick={addGroupBy}
        text="Add a New Grouping"
        disabled={groups.length > 4}
        aria-label="add-grouping-button"
      />
      {groups?.map((group, index) => {
        return (
          <Flex w={850}>
            <Stack
              w="100%"
              key={index}
              direction={["column", "column", "row"]}
              spacing={2}
              mt={4}
            >
              <RemoveButton
                data-testid="remove-group-button"
                onClick={() => {
                  removeGroup(index);
                }}
                text="Remove Grouping"
                hidden={index === 0}
                aria-label="remove-grouping-button"
              />
              <FormControl w="100%">
                <TdsField label="Field">
                  <select
                    style={{
                      width: "200px",
                      height: "2rem",
                      verticalAlign: "top",
                      padding: "0 0 0 .75rem",
                      fontSize: "1rem",
                    }}
                    className="tds-field__input"
                    aria-label="Field"
                    name="tds-field__input"
                    data-testid="group-field-select"
                    value={group.originalLabel as string}
                    onChange={(event) => {
                      dispatch(addSuccessMessage(""));
                      if (event.target.value === "") {
                        resetGroup(group);
                      } else {
                        const limitFieldOption = limitFieldOptions?.filter(
                          (option) => option._text === event.target.value
                        )[0];
                        if (limitFieldOption) {
                          const highestNumber = Math.max(
                            ...groups.map((o) => o.groupKey || 0)
                          );
                          setGroups(
                            groups.map((_group, i) =>
                              i === index
                                ? {
                                  ..._group,
                                  groupKey: groups
                                    .map((o) => o.groupKey || 0)
                                    .includes(index)
                                    ? highestNumber + 1
                                    : index,
                                  lookupEnabled: enableFilterLookup(
                                    limitFieldOption._text
                                  ),
                                  hasPageBreak: false,
                                  hasSubtotal:
                                    limitFieldOption._text ===
                                      "Claim Number" ||
                                      limitFieldOption._text ===
                                      "Claim Number Other"
                                      ? false
                                      : true,
                                  field: limitFieldOption._value,
                                  label: limitFieldOption._text,
                                  originalLabel: limitFieldOption._text,
                                }
                                : _group
                            )
                          );
                        }
                      }
                    }}
                  >
                    {limitFieldOptions?.map((element, index) => (
                      <option
                        key={index}
                        data-testid="group-field-option"
                        selected={group.field === element._value}
                        value={element._text}
                      >
                        {element._text}
                      </option>
                    ))}
                  </select>
                </TdsField>
              </FormControl>
              <FormControl>
                <TdsField
                  label="Label"
                  message-aria-live="assertive"
                  alert={
                    group.dateInvalidError ||
                      group.dateFormatError ||
                      group.yearFormatError
                      ? "error"
                      : "false"
                  }
                >
                  <InputGroup style={{ fontSize: "14px" }}>
                    {group.label.length <= 21 && (
                      <Input
                        isDisabled={group.field === ""}
                        className={
                          "add-groups-value-input-field " +
                          (group.dateInvalidError ? "tds-field-invalid" : "")
                        }
                        aria-label="value"
                        type="text"
                        onChange={(event) => {
                          dispatch(addSuccessMessage(""));
                          setGroups(
                            groups.map((_group, i) =>
                              i === index
                                ? { ..._group, label: event.target.value }
                                : _group
                            )
                          );
                        }}
                        onFocus={(e) =>
                          e.currentTarget.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length
                          )
                        }
                        data-testid="group-label-input"
                        autoFocus
                        value={group.label as string}
                        style={{
                          float: "left",
                          width: "89%",
                          padding: "0 0 0 .75rem",
                        }}
                        w={10}
                      />
                    )}
                    {group.label.length > 21 && (
                      <Textarea
                        className="add-groups-value-input-field"
                        aria-label="value"
                        autoFocus
                        onFocus={(e) =>
                          e.currentTarget.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length
                          )
                        }
                        onChange={(event) => {
                          dispatch(addSuccessMessage(""));
                          setGroups(
                            groups.map((_group, i) =>
                              i === index
                                ? { ..._group, label: event.target.value }
                                : _group
                            )
                          );
                        }}
                        data-testid="group-label-input"
                        value={group.label as string}
                        style={{
                          float: "left",
                          width: "89%",
                          padding: "0 0 0 .75rem",
                        }}
                        w={10}
                      />
                    )}
                  </InputGroup>
                </TdsField>
              </FormControl>
              <FormControl>
                <Stack mt={10} direction={["row", "column", "row"]} spacing={4}>
                  <Checkbox
                    isDisabled={
                      group.originalLabel === "Claim Number" ||
                      group.originalLabel === "Claim Number Other"
                    }
                    onChange={() => {
                      dispatch(addSuccessMessage(""));
                      setGroups(
                        groups.map((_group, i) =>
                          i === index
                            ? { ..._group, hasSubtotal: !group.hasSubtotal }
                            : _group
                        )
                      );
                    }}
                    variant="basic"
                    isChecked={group.hasSubtotal}
                  >
                    Subtotal
                  </Checkbox>
                  <Checkbox
                    onChange={() => {
                      dispatch(addSuccessMessage(""));
                      setGroups(
                        groups.map((_group, i) =>
                          i === index
                            ? { ..._group, hasPageBreak: !group.hasPageBreak }
                            : _group
                        )
                      );
                    }}
                    variant="basic"
                    isDisabled={
                      group.originalLabel === "Claim Number" ||
                      group.originalLabel === "Claim Number Other"
                    }
                    isChecked={group.hasPageBreak}
                  >
                    Page Break
                  </Checkbox>
                </Stack>
              </FormControl>
              <div className="tds-field--error" style={{ marginBottom: 0 }}>
                <div
                  id="group-section-missing-operator-error"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!group.dateInvalidError}
                >
                  <p style={{ fontSize: "14px" }}>Invalid date</p>
                </div>
                <div
                  id="group-section-missing-operator-error"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!group.dateFormatError}
                >
                  <p style={{ fontSize: "14px" }}>
                    Date must be in MM/DD/YYYY format
                  </p>
                </div>
                <div
                  id="group-section-missing-operator-error"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!group.yearFormatError}
                >
                  <p style={{ fontSize: "14px" }}>Format must be YYYY</p>
                </div>
                <div
                  id="group-section-missing-operator-error"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!group.yearInvalidError}
                >
                  <p style={{ fontSize: "14px" }}>Invalid date</p>
                </div>
              </div>
              <FormControl w={20}>
                <Button
                  mt={7}
                  onClick={() => {
                    resetGroup(group);
                  }}
                  variant="outline"
                  size="md"
                >
                  Reset
                </Button>
              </FormControl>
            </Stack>
          </Flex>
        );
      })}

      <div className="tds-field--error" >
        <div
          id="group-section-duplicate-error"
          aria-atomic="true"
          className="tds-field__message"
          role="alert"
          data-testid="group-duplicate-error"
          hidden={!duplicateError}
        >
          <p style={{ fontSize: "14px" }}>
            Group By field already used
          </p>
        </div>
      </div>
    </Box>
  );
};
export default GroupBySection;
