import { useContext, useEffect, useRef, useState } from 'react';
import { useSubmitParametersMutation } from '../../features/submitReport/submitReportApiSlice';
import { useSearchParams, useNavigate } from "react-router-dom";
import { ReportData } from '../../common/types';
import { Loader } from '../Shared/Common/Loader';
import {
    Box,
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from '@chakra-ui/react';

import { TdsLoadingSpinner } from '@trv-tds/react';
import useLocalStorage from '../../hooks/useLocalStorage';
import { AppContext } from '../../context/appContext';
import { ReactComponent as Checklist172 } from '@trv-ebus/tds-icons/dist/assets/icons/illustrative/svg/checklist-1-72.svg';
import { ReactComponent as ArrowLeft124 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/arrow-left-1-24.svg'

const DrilldownSummary = () => {

    const defaultAppConfig = { REACT_APP_ST_CLAIM_REPORT_URL: undefined }
    const { appConfig: { REACT_APP_ST_CLAIM_REPORT_URL } = defaultAppConfig } = useContext(AppContext);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [postReportData] = useSubmitParametersMutation();
    const navigate = useNavigate();

    const [reportDataSummaryData] = useLocalStorage<any>("reportDataSummary", {});
    let [reportDataSummary, getReportDataSummary] = useState(reportDataSummaryData);
    const [defaultSortGroupsData] = useLocalStorage<any>("defaultSortGroups", []);
    let [defaultSortGroups, getDefaultSortGroups] = useState(defaultSortGroupsData);
    const [submitLoading, setSubmitLoading] = useState(true);
    const lossesCriteria = reportDataSummary.LossesCriteria;
    const [reportName, setReportName] = useState(lossesCriteria?.ReportName);

    function handleSubmit() {
        let [searchParams, setSearchParams] = useSearchParams();
        let formattedFilteredFilters = [];
        const drilldownReport = async () => {
            if (searchParams.size == 3 || searchParams.size == 4) {
                let drilldownObj: any = [];
                const sortGroups = lossesCriteria.SortGroups;
                const sortLevel = Number(searchParams.get('SortLevel')) - 1;
                const sortValues: any = searchParams.get('SortValues');
                const limitType: number = 1;
                const sortVal = sortValues.includes('~') ? sortValues.split('~') : [sortValues];
                console.log('sortVal', sortVal);
                if (sortVal) {
                    await sortVal.reverse();
                    const lastValue: any = sortVal.slice(-1);
                    if (sortGroups.length > 0) {
                        if (sortVal.length == 1) {
                            drilldownObj.push({
                                'FieldName': sortGroups[sortLevel].FieldName,
                                'DisplayName': sortGroups[sortLevel].Label,
                                'Operator': "EQ",
                                'LimitValue': stringFormat(sortVal[0]), // "\"BRASFIELD & GORRIE, L.L.C.            \"",
                                'LimitType': limitType
                            });
                        } else {
                            const drillDownCount = (sortGroups.length) - (sortVal.length);
                            if (drillDownCount > 0) {
                                sortGroups.splice(0, drillDownCount);
                            }
                            for (let i = 0; i < sortVal.length; i++) {
                                drilldownObj.push({
                                    'FieldName': sortGroups[i].FieldName,
                                    'DisplayName': sortGroups[i].Label,
                                    'Operator': "EQ",
                                    'LimitValue': stringFormat(sortVal[i]),
                                    'LimitType': limitType
                                });
                            }
                        }
                    } else {
                        // If no group is selected then select default group as Line of Insurance and Accident Year.
                        if (lossesCriteria.AccountIdType === 3) {
                            const ReportDataSummaryLimits = [{
                                'DisplayName': 'Line of Insurance',
                                'FieldName': 'MAJ_LN_CATGY_CD'
                            },
                            {
                                'DisplayName': 'Policy Year',
                                'FieldName': 'POL_YR'
                            },
                            {
                                'DisplayName': 'Insured Name',
                                'FieldName': 'POL_INSD_NM_1_TXT'
                            }];

                            if (sortVal.length > 1) {
                                const drillDownCount = (ReportDataSummaryLimits.length) - (sortVal.length);
                                if (drillDownCount > 0) {
                                    ReportDataSummaryLimits.splice(0, drillDownCount);
                                }
                                for (let i = 0; i < sortVal.length; i++) {
                                    drilldownObj.push({
                                        'FieldName': ReportDataSummaryLimits[i].FieldName,
                                        'DisplayName': ReportDataSummaryLimits[i].DisplayName,
                                        'Operator': "EQ",
                                        'LimitValue': stringFormat(sortVal[i]),
                                        'LimitType': limitType
                                    });
                                }
                            } else {
                                drilldownObj.push({
                                    'FieldName': ReportDataSummaryLimits[2].FieldName,
                                    'DisplayName': ReportDataSummaryLimits[2].DisplayName,
                                    'Operator': "EQ",
                                    'LimitValue': stringFormat(sortVal[0]),
                                    'LimitType': limitType
                                });
                            }
                        } else if (searchParams.get('SelectLagPeriod')) {
                            drilldownObj.push({
                                'FieldName': "CLAIM_LAG",
                                'Operator': "EQ",
                                'LimitValue': Number(searchParams.get('SelectLagPeriod')),
                                'LimitType': limitType
                            });
                        } else {
                            const ReportDataSummaryLimits = [{
                                'DisplayName': 'Policy Year',
                                'FieldName': 'POL_YR'
                            },
                            {
                                'DisplayName': 'Line of Insurance',
                                'FieldName': 'MAJ_LN_CATGY_CD'
                            }];

                            if (sortVal.length > 1) {
                                drilldownObj.push(
                                    {
                                        'FieldName': ReportDataSummaryLimits[0].FieldName,
                                        'DisplayName': ReportDataSummaryLimits[0].DisplayName,
                                        'Operator': "EQ",
                                        'LimitValue': stringFormat(sortVal[1]),
                                        'LimitType': limitType
                                    },
                                    {
                                        'FieldName': ReportDataSummaryLimits[1].FieldName,
                                        'DisplayName': ReportDataSummaryLimits[1].DisplayName,
                                        'Operator': "EQ",
                                        'LimitValue': stringFormat(sortVal[0]),
                                        'LimitType': limitType
                                    }
                                );
                            } else {
                                drilldownObj.push({
                                    'FieldName': ReportDataSummaryLimits[0].FieldName,
                                    'DisplayName': ReportDataSummaryLimits[0].DisplayName,
                                    'Operator': "EQ",
                                    'LimitValue': stringFormat(sortVal[0]),
                                    'LimitType': limitType
                                });
                            }
                            drilldownObj.reverse();
                        }
                    }

                    if (sortGroups.length > 0 && searchParams.get('SelectLagPeriod')) {
                        drilldownObj.push({
                            'FieldName': "CLAIM_LAG",
                            'Operator': "EQ",
                            'LimitValue': Number(searchParams.get('SelectLagPeriod')),
                            'LimitType': limitType
                        });
                    }

                    drilldownObj.reverse();
                    // this creates an immutable array that will not have duplicate values
                    const limitArray = [...reportDataSummary.LossesCriteria.Limits];
                    reportDataSummary.LossesCriteria.Limits = [
                        ...limitArray.filter((value, index) => {
                            if (value.LimitType === 0) {
                                return value;
                            }
                        }),
                        ...drilldownObj
                    ];
                    if (defaultSortGroups.length  > 0) {
                        defaultSortGroups.filter((value, index) => {
                            if (index === 0) {
                                value.Subtotal = true;
                            }
                        });
                    }

                    reportDataSummary.LossesCriteria.SortGroups = [...defaultSortGroups];
                    reportDataSummary.LossesCriteria.ReportType = 0;
                    reportDataSummary.LossesCriteria.BaseUrl = REACT_APP_ST_CLAIM_REPORT_URL;
                    reportDataSummary.LossesCriteria.ReportName = `Drilldown from ${reportName}`;
                    console.log('drilldownObj', drilldownObj);
                }

                // Retrive previous FinancialSummary request payload
                const { data }: any = await postReportData(reportDataSummary);
                const moreThanSixMb: number = Number(data?.messages[0].code) || 0;
                const windowParams = `left=0, top=400, toolbar=0, status=0, popup=1, width=${screen.width + (-200)}, height=${window.innerHeight + (-100)}`
                if (data && data.result) {
                    if (moreThanSixMb == 1) {
                        console.log('Report file size is more than 6 MB. Drilldown....!');
                        const pdfURL: string = data.result;
                        window.open(pdfURL, 'drilldown.pdf', windowParams);
                        setSubmitLoading(false);
                    } else {
                        const blob = base64toBlob(`data:application/pdf;base64, ${data.result}`);
                        const fileURL = window.URL.createObjectURL(blob);
                        console.log('URL ::', fileURL.substring(5));
                        window.open(fileURL, 'drilldown', windowParams);
                        setSubmitLoading(false);
                    }
                }
            }
        };

        const stringFormat = (value: string) => {
            return value.includes(',') ? "\"" + value + "\"" : value;
        }

        const base64toBlob = (data: string) => {
            // Cut the prefix `data:application/pdf;base64` from the raw base 64
            const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
            const bytes = atob(base64WithoutPrefix);
            let length: number = bytes.length;
            let result: any = new Uint8Array(length);

            while (length--) {
                result[length] = bytes.charCodeAt(length);
            }

            const pdfType: any = new Blob([result], { type: 'application/pdf' });
            return pdfType

        };

        useEffect(() => {
            drilldownReport();
        }, [])
    }

    handleSubmit();

    const reportsSummaryPage = () => { navigate(-1); }

    console.log('Drilldown Summary!!!');
    return (
        <>
            <Modal size={'full'} onClose={onClose} isOpen={true}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{reportName}</ModalHeader>
                    <ModalBody>
                        {submitLoading && <TdsLoadingSpinner label="Processing" size="xxx-large" label-displayed overlay centered>Processing...</TdsLoadingSpinner>}
                        {
                            !submitLoading &&
                            <Box bgColor="gray.50" p={4} my={2} className='list-page'>
                                <HStack align="stretch" py={0} my={0}>
                                    <Text mr={2} fontSize="xs" py={0} my={0} fontWeight={800} className='action-box'>
                                        <Button onClick={() => { reportsSummaryPage() }} variant='capsLink'>
                                            Reload {reportName}
                                            <Box className='check-list-icon'>
                                                <Checklist172 className="tds-icon-72" />
                                            </Box>
                                        </Button>
                                    </Text>
                                </HStack>
                            </Box>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default DrilldownSummary;