import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface FilterSectionWarningMessageState {
  filterSectionWarningMessage: string
  clearedCriteriaMessage: string
  optionalCriteriaActiveMessage: string
  getHasOptionalReportCriteria: string
}

const initialState: FilterSectionWarningMessageState = {
  filterSectionWarningMessage: "",
  clearedCriteriaMessage: "",
  optionalCriteriaActiveMessage: "",
  getHasOptionalReportCriteria: ""
}


export const filterSectionWarningMsgSlice = createSlice({
  name: 'filterSectionWarningMessage',
  initialState,
  reducers: {
    addFilterWarning: (state: FilterSectionWarningMessageState, action: PayloadAction<string>) => {
      state.filterSectionWarningMessage = action.payload;
    },
    removeFilterWarning: (state: FilterSectionWarningMessageState, action: PayloadAction<string>) => {
      state.filterSectionWarningMessage = "";
    },
    addClearedCriteriaMessage: (state: FilterSectionWarningMessageState, action: PayloadAction<string>) => {
      state.clearedCriteriaMessage = action.payload;
    },
    removeClearedCriteriaMessage: (state: FilterSectionWarningMessageState, action: PayloadAction<string>) => {
      state.clearedCriteriaMessage = "";
    },
    optionalCriteriaActiveMessage: (state: FilterSectionWarningMessageState, action: PayloadAction<string>) => {
      state.optionalCriteriaActiveMessage = action.payload;
    },
    getHasOptionalReportCriteria: (state: FilterSectionWarningMessageState, action: PayloadAction<string>) => {
      state.getHasOptionalReportCriteria = action.payload || "";
    },

  }
});

// Action creators
export const { addFilterWarning, removeFilterWarning, addClearedCriteriaMessage, removeClearedCriteriaMessage, optionalCriteriaActiveMessage, getHasOptionalReportCriteria } = filterSectionWarningMsgSlice.actions;

export default filterSectionWarningMsgSlice.reducer;
