import { Dispatch, SetStateAction } from 'react';
import { ReactComponent as ContactUsCloseIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-12.svg';
type ContactUsProps = {
  setShowContactUsDialog: Dispatch<SetStateAction<boolean>>;
};
const ContactUs = ({ setShowContactUsDialog }: ContactUsProps) => {
  return (
    <div role='dialog' className='tds-modal tds-modal__dialog--small' aria-label='contact us dialog'>
      <div role='document' className='tds-modal__dialog-container' style={{ width: '450px' }}>
        <div className='tds-modal__dialog-header'>
          <h3 className='tds-modal__dialog-title' style={{ color: '#46494d' }}>
            Contact Us
          </h3>
        </div>

        <div className='tds-modal__dialog-body' style={{ paddingBottom: '2rem' }}>
          <div className='tds-modal__dialog-body-content'>
            <h4 style={{ letterSpacing: 0 }}> For Agents Customers: </h4>
            <p className='contact-us-p-font tds-small'>
              
              Contact Agency Automation at{" "}
              <strong>
                <a href='tel:8008422522' style={{ textDecoration: 'none', color: 'inherit' }}>
                   (800) 842-2522
                </a>
              </strong>
            </p>

            <h4 style={{ letterSpacing: 0 }}> RMIS Customers: </h4>
            <p className='contact-us-p-font tds-small'>
              
              Contact the RMIS Hotline at{" "}
              <strong>
                <a href='tel:8602775800' style={{ textDecoration: 'none', color: 'inherit' }}>
                   (860) 277-5800
                </a>
              </strong>
            </p>

            <h4 style={{ letterSpacing: 0 }}> Travelers Employees: </h4>
            <p className='contact-us-p-font tds-small'> Contact the Technology Service Center (myServiceDesk) </p>

            <p className='contact-us-p-font tds-italic'>Note: For claim specific questions, please contact the claim professional.</p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button onClick={() => setShowContactUsDialog(false)} className='tds-button--primary tds-button--small' type='button'>
              
              CLOSE
            </button>
          </div>

          <button className='tds-modal__dismiss' aria-label='Dismiss dialog' type='button' onClick={() => setShowContactUsDialog(false)}>
            <ContactUsCloseIcon alt='Close Alert' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
