import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountIdentificationOptions } from '../common/constants';
import { AccIdentification } from '../components/Shared/AgentAccountSearch/agentAccount';
import { useGetClaimActivityListQuery } from '../features/claimActivity/claimActivityApiSlice';
import { addErrorMessage, removeErrorMessage } from '../features/claimActivity/claimActivityErrorMsgSlice';
import { RootState } from '../store';
import useAuth from './useAuth';

function useClaimActivityListData<T>() {
    const { profileData } = useAuth();
    const dispatch = useDispatch();
    const userAccountIdentification: AccIdentification = useSelector((state: RootState) => state.agentAccount.accountIdentification);

    function getAccountType() {
        if (userAccountIdentification.accountIdentificationSelections === null || userAccountIdentification.accountIdentificationSelections.length === 0)
            return 1;
        if (userAccountIdentification.accountIdentificationMethod === accountIdentificationOptions.AccountNumber)
            return 1;
        else if (userAccountIdentification.accountIdentificationMethod === accountIdentificationOptions.PolicyNumber)
            return 2;
        else if (userAccountIdentification.accountIdentificationMethod === accountIdentificationOptions.ProducerCode)
            return 3;
        else if (userAccountIdentification.accountIdentificationMethod === accountIdentificationOptions.SAINumber)
            return 4;
        else return 1;
    }

    let qoutedAndCommaSeparated = userAccountIdentification.accountIdentificationSelections;
    const lossCriteria =
    {
        'LossesCriteria': {
            'Selections': qoutedAndCommaSeparated,
            'AccountIdType': getAccountType()
        },
        'UserInformation': {
            'id': profileData.length && profileData[0].id,
            'email': profileData.length && profileData[0].email,
            'message': 'validated',
            'FirstName': profileData.length && profileData[0].FirstName,
            'LastName': profileData.length && profileData[0].LastName,
            'UserEmail': profileData.length && profileData[0].UserEmail,
            'Username': profileData.length && profileData[0].UserName,
            'NickName': profileData.length && profileData[0].NickName,
            'BrokerCodes': profileData.length && profileData[0].BrokerCodes,
            'EmploymentId': profileData.length && profileData[0].EmploymentId,
            'RmisId': profileData.length && profileData[0].RmisId,
            'Role': {
                'IsPilotUser': profileData.length &&  profileData[0].Role && profileData[0].Role.IsPilotUser,
                'IsLossReportsUser': profileData.length && profileData[0].Role && profileData[0].Role.IsLossReportsUser,
                'IsLossReportsUserNationalPrograms': profileData.length && profileData[0].Role && profileData[0].Role.IsLossReportsUserNationalPrograms,
                'IsTracerUser': profileData.length && profileData[0].Role && profileData[0].Role.IsTracerUser,
                'IsAccountId': profileData.length && profileData[0].Role && profileData[0].Role.IsAccountId,
                'IsAccountLocationId': profileData.length && profileData[0].Role && profileData[0].Role.IsAccountLocationId,
                'IsColaaBroker': profileData.length && profileData[0].Role && profileData[0].Role.IsColaaBroker,
                'IsBroker': profileData.length && profileData[0].Role && profileData[0].Role.IsBroker,
                'IsAuthorized': profileData.length && profileData[0].Role && profileData[0].Role.IsAuthorized,
                'IsInternalUser': profileData.length && profileData[0].Role && profileData[0].Role.IsInternalUser,
                // 'BrokerCodes': profileData.length && profileData[0].Role && profileData[0].Role.BrokerCodes,
                // 'EmploymentId': profileData.length && profileData[0].Role && profileData[0].Role.EmploymentId,

                'RoleCodes': profileData.length && profileData[0].Role && profileData[0].Role.RoleCodes,
                //               'EcarmaRole': profileData.length && profileData[0].Role && profileData[0].Role.EcarmaRole,
 //               'LossreportUserRole': profileData.length && profileData[0].Role && profileData[0].Role.LossreportUserRole,
 //               'LossreportNationalProgramUserRole': profileData.length && profileData[0].Role && profileData[0].Role.LossreportNationalProgramUserRole,
 //               'TracerRole': profileData.length && profileData[0].Role && profileData[0].Role.TracerRole,
 //               'PilotRole': profileData.length && profileData[0].Role && profileData[0].Role.PilotRole
            }
        }
    };
    let shouldSkip=(profileData===undefined || profileData===null || profileData?.length === 0) ||
    (profileData?.length > 0 && (userAccountIdentification.accountIdentificationSelections.length === 0 &&
      profileData[0]?.Role.IsInternalUser) 
    ); 
  
    const { data: claimActivityListData, isLoading: isClaimActivityListLoading, isSuccess: isClaimActivityListSuccess, isError: isClaimActivityListError, error: getClaimActivityListError  }
        = useGetClaimActivityListQuery(lossCriteria, { skip: shouldSkip});
            
    
    useEffect(() => {
        if (!isClaimActivityListLoading && isClaimActivityListSuccess && claimActivityListData ) {
            if (claimActivityListData.messages[0].code !== '0') {
                dispatch(addErrorMessage(claimActivityListData.messages[0].description));
            } else {
                dispatch(removeErrorMessage(""));
            }
        }
    }, [claimActivityListData, isClaimActivityListLoading, isClaimActivityListSuccess]);

    return { claimActivityListData, isClaimActivityListLoading, isClaimActivityListSuccess, isClaimActivityListError, getClaimActivityListError } as const;
}

export default useClaimActivityListData;