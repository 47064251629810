import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)
  const xl = defineStyle({
    fontSize: 'xxl',
    height: '2.8rem',
    maxHeight: '2.8rem',
    pl: 4,
  })
  
const sizes = {
    xl: definePartsStyle({ field: xl, addon: xl }),
  }
const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    borderRadius: '2rem',
    rounded: '0.1875rem',
    height: '2rem',
    maxHeight: '2rem',
  },
})

export const inputTheme = defineMultiStyleConfig({ baseStyle, sizes })