import { CloseIcon } from "@chakra-ui/icons";
import {
  Box
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";

export enum AlertType {
  error = "error",
  success = "success",
  warning = "warning",
  info = "info",
}

export const AlertMessage = ({
  type = AlertType.warning,
  message = "",
  isDismissable = false,
  isShown: show = true,
  setIsShown
}: {
  type?: AlertType;
  message: String;
  isDismissable?: boolean
  isShown?: boolean;
  setIsShown?: Dispatch<SetStateAction<boolean>>;
}) => {
  const [showDismiss, setShowDismiss] = useState(show);
  return !showDismiss ? (
    <></>
  ) : (
    // <div className="tds-alert--warning tds-alert--dismissible">
    <div
      className="tds-alert--warning tds-alert--dismissible"
      id="my-alert-info-dismissible"
    >
      {isDismissable && (
        <Box right={3} top={3} position="absolute">
          <CloseIcon
            border="none"
            cursor="pointer"
            aria-label="Close Alert"
            onClick={() => {
              setIsShown && setIsShown(false);
              setShowDismiss(false)
            }}
          />
        </Box>
      )}
      <p style={{fontSize: 14}}>{message}</p>
    </div>
  );
};
