import { Text } from "@chakra-ui/react";
import { TdsCombobox, TdsField } from "@trv-tds/react";
import { useEffect, useRef, useState } from "react";
import { AuthenticatedQuery } from "../../common/types";
import { useSavedParametersMutation } from "../../features/savedParameters/savedParametersApiSlice";
import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Loader } from "../Shared/Common/Loader";
import SavedParametersSearchResults from "../Shared/SavedParameters/SavedParametersSearchResults";
import { DEFAULT_SAVED_PARAMETERS_USER } from "../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { waitFor } from "@testing-library/react";
import { removeAccountErrorMessage, setSavedParametersErrorMessage } from "../../features/claimActivity/claimActivityErrorMsgSlice";

const SavedParameters = () => {
  const { profileData } = useAuth();
  const [isDefaultUser] = useLocalStorage<boolean>("isDefaultUser", false);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [selectedReportName, setSelectedReportName] = useState<string>("");
  const [selectedAccountType, setSelectedAccountType] = useState<any>("All");
  const [selectedReportType, setSelectedReportType] = useState<any>("All");
  const[isLoaded, setIsLoaded] = useState(false);
  const { savedParametersErrorMessage: spErrorMessage } = useSelector((state: RootState) => state.claimActivity);
  const scrollRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const {isAgentUser, isInternalUser, isAccountId} = useAuth();
  useEffect(() => {
    dispatch(setSavedParametersErrorMessage(""));
  }, []);


  const _lossCriteria: AuthenticatedQuery = {
    UserInformation: isDefaultUser
      ? DEFAULT_SAVED_PARAMETERS_USER
      : profileData[0],
  };
  const scrollToElement = () => {
    if (spErrorMessage!=="") 
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };


  useEffect(() => {
    if(_lossCriteria && _lossCriteria.UserInformation && savedParametersData === undefined && !isLoaded){
      loadSavedParameters(_lossCriteria);
      setIsLoaded(true);
    }
}, [_lossCriteria]);

  useEffect(() => {
    scrollToElement();
  }, [spErrorMessage]);

  const [
    loadSavedParameters,
    {
      data: savedParametersData,
      isLoading: isSavedParametersLoading,
      isError: isSavedParametersError,
      isSuccess: isSavedParametersSuccess,
      error: getSavedParametersError,
    },
  ] = useSavedParametersMutation();

  const {
    Search: searchResults,
    AccountName: accountTypes,
    ReportType: reportTypes,
  } = savedParametersData || {};

  const selectedSearchResults = searchResults
    ?.filter(
      (item: any) =>
        item.RPT_NM?.toLowerCase().includes(selectedReportName.toLowerCase())
    )
    .filter((item: any) =>
      selectedReportType === "All"
        ? true
        : item.RPT_TYPE_NM === selectedReportType
    )
    .filter((item: any) =>
      selectedAccountType === "All"
        ? true
        : item.RPT_ACCT_NM === selectedAccountType
    );

  const searchResultsClick = () => {
    dispatch(setSavedParametersErrorMessage(""));
    loadSavedParameters(_lossCriteria);
    setShowSearchResults(true);
  };

  if (isSavedParametersError) {
    console.log(
      "error retrieving saved parameters data:" + getSavedParametersError,
      savedParametersData
    );
  }

  return (
    <div className="report-options-main-div">
      <div className="report-options-inner-div-title-act-identification">
        <Text as="h2" textStyle="h2">
          Saved Parameters
        </Text>
        <Text as="h3" textStyle="h3">
          Search Options
        </Text>
      </div>
      <div ref={scrollRef} className='tds-alert-error' hidden={ spErrorMessage==="" }>
            {spErrorMessage}
      </div>
      <div className="saved-parameters-form-elements-div">
      {(isAgentUser || isInternalUser) && (
        <div className="saved-parameters-account-name-field">
          <TdsField label="Account Name">
            <TdsCombobox
              selectedValue={selectedAccountType}
              defaultValue={selectedAccountType}
              style={{ width: "100%", height: "2rem", verticalAlign: "top" }}
            >
              <select
                className="tds-field__input"
                aria-label="account name"
                name="tds-field__input"
                onChange={(event) => setSelectedAccountType(event.target.value)}
              >
                {<option value="All">All</option>}
                {accountTypes &&
                  accountTypes.map((account: any, index: any) => (
                    <option key={index} value={account.Account_ID}>
                      {account.Account_Name}
                    </option>
                  ))}
              </select>
            </TdsCombobox>
          </TdsField>
        </div>
    )}
        <div className="saved-parameters-report-type-field">
          <TdsField label="Report Type">
            <TdsCombobox
              style={{ width: "100%", height: "2rem", verticalAlign: "top" }}
              selectedValue={selectedReportType}
              defaultValue={selectedReportType}
            >
              <select
                className="tds-field__input"
                aria-label="account name"
                name="tds-field__input"
                value={selectedReportType}
                onChange={(event) => setSelectedReportType(event.target.value)}
              >
                {<option value="All">All</option>}
                {reportTypes &&
                  reportTypes.map((account: any, index: any) => (
                    <option key={index} value={account.Report_Type}>
                      {account.Report_Type}
                    </option>
                  ))}
              </select>
            </TdsCombobox>
          </TdsField>
        </div>
        <div className="saved-parameters-report-name-field">
          <div>
            <TdsField label="Report Name">
              <input
                style={{ height: "2rem" }}
                type="text"
                name="report name"
                value={selectedReportName}
                onChange={(event) => setSelectedReportName(event.target.value)}
              />
            </TdsField>
          </div>
        </div>
      </div>
      <div>
        <div className="saved-parameters-search-btn-div">
          <button
            disabled={isSavedParametersLoading}
            onClick={searchResultsClick}
            className="tds-button--primary tds-button--small saved-parameters-search-btn"
            type="button"
          >
            SEARCH
          </button>
        </div>
      </div>
      {isSavedParametersLoading && <Loader p={50} />}
      {showSearchResults && (
        <SavedParametersSearchResults
          showResults={isSavedParametersSuccess}
          searchResults={selectedSearchResults || []}
          isLoading={isSavedParametersLoading}
        />
      )}
    </div>
  );
};

export default SavedParameters;
