import { Server, Response } from 'miragejs';
import profileData from './data/profile.mock.json';
import agentData from './data/agent.mock.json';
import { API, MOCK_API_BASE_URL } from '../common/constants';

const handleGetProfileRequest = () => profileData;

const handleAgentRequest = () => agentData;

const handlePutPostResponse = (_schema: any, request: { requestBody: string; }) => {
    const { policy: { parties: { name } } } = JSON.parse(request.requestBody);
    if (name === 'FAILURE') return new Response(500, {}, profileData);
    if (name === 'FAILURE-404') return new Response(404, {}, agentData);
    return agentData;
  };

export default function mockServer(): Server {
  const server = new Server({
    environment: 'development',
    routes() {

      this.get(`${MOCK_API_BASE_URL}${API.PROFILE}`, handleGetProfileRequest);

      this.get(`${MOCK_API_BASE_URL}${API.AGENT_LIST}`, handleAgentRequest);

    //   this.post('/api/business/endorsement/policy/bi/:policyNumber', handlePutPostResponse);

      // pass through in cases where you want to hit actual endpoints
      this.passthrough();
      this.passthrough(`https://${process.env.REACT_APP_OKTA_DOMAIN}/**`)

      // this.passthrough("https://jsonplaceholder.typicode.com/**")
    },
  });

  return server;
}