import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';
import { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FilterOption } from '../../../common/types';
import { useGetFilterLookupListQuery } from '../../../features/utils/filterLookupApiSlice';
import { FilterLookupResult } from '../../../features/utils/utils';
import { RootState } from '../../../store';
import { Loader } from '../Common/Loader';

type ReportFilteringResultsProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setShowReportFilteringResultsModal: Dispatch<SetStateAction<boolean>>;
  filter: FilterOption | undefined;
  filters: FilterOption[];
  setFilters: (filters: FilterOption[]) => void;
  finalFocusRef?: React.RefObject<FocusableElement>;
};
export default function WCReportFilteringResults({ isOpen, setIsOpen, setShowReportFilteringResultsModal, filters, filter, setFilters, finalFocusRef }: ReportFilteringResultsProps) {

  const activeFilter = useSelector((state: RootState) => state.filterLookup.activeFilter);
  const [lobSelected, setLobSelected] = useState<string>('WCSpeciality');
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [localFilters, setLocalFilters] = useState(filters);
  const newRef = useRef(null);

  useEffect(() => {
    setLobSelected('WCSpeciality');
  }, [isOpen]);

  const toggleRecord = (record: FilterLookupResult) => {
    if (filter) {
      const currentFilter = localFilters.filter((_filter) => _filter.filterKey === filter.filterKey)[0];
      const excludedFilters = localFilters.filter((_filter) => _filter.filterKey !== filter.filterKey);
      if (currentFilter?.value.split(",").includes(record.Code)) {
        let valueStr = currentFilter?.value.split(",");
        valueStr.splice(valueStr.indexOf(record.Code), 1);
        const newFilter = { ...currentFilter, value: valueStr.join(",") }
        setLocalFilters(([...excludedFilters, newFilter]))
      } else {
        const newFilter = { ...currentFilter, value: currentFilter?.value + (currentFilter?.value === "" ? "" : ",") + record.Code }
        setLocalFilters(([...excludedFilters, newFilter]))
      }
    }
  }
  let isLoading: boolean = false;
  const isError: boolean = false;
  const wcdata: any = {
    WCExSpeciality: useGetFilterLookupListQuery({ LimitingCriteria: { FieldName: activeFilter.dbValue + 'WCExSpeciality' } }),
    WCSpeciality: useGetFilterLookupListQuery({ LimitingCriteria: { FieldName: activeFilter.dbValue + 'WCSpeciality' } }),
    GLExSpeciality: useGetFilterLookupListQuery({ LimitingCriteria: { FieldName: activeFilter.dbValue + 'GLSpeciality' } }),
    GLSpeciality: useGetFilterLookupListQuery({ LimitingCriteria: { FieldName: activeFilter.dbValue + 'GLExSpeciality' } }),
    AUExSpeciality: useGetFilterLookupListQuery({ LimitingCriteria: { FieldName: activeFilter.dbValue + 'AUExSpeciality' } }),
    AUSpeciality: useGetFilterLookupListQuery({ LimitingCriteria: { FieldName: activeFilter.dbValue + 'AUSpeciality' } }),
    FIExSpeciality: useGetFilterLookupListQuery({ LimitingCriteria: { FieldName: activeFilter.dbValue + 'FIExSpeciality' } }),
    FISpeciality: useGetFilterLookupListQuery({ LimitingCriteria: { FieldName: activeFilter.dbValue + 'FISpeciality' } }),
    PMExSpeciality: useGetFilterLookupListQuery({ LimitingCriteria: { FieldName: activeFilter.dbValue + 'PMExSpeciality' } }),
    PMSpeciality: useGetFilterLookupListQuery({ LimitingCriteria: { FieldName: activeFilter.dbValue + 'PMSpeciality' } })
  };
  const selectedValue = isSelected ? lobSelected.split("Speciality").join("ExSpeciality") : lobSelected;
  const filterLookupData = (wcdata[selectedValue])?.data;
  isLoading = (wcdata[selectedValue])?.isLoading;

  const scrollToElement = () => {
    // @ts-ignore
    newRef?.current?.scrollIntoView({
      behavior: 'smooth', block: 'start'
    });
  };


  const addFilterSelections = () => {
    const sortedFilters = localFilters.sort((b, a) => (a?.filterKey ?? 1) - (b?.filterKey ?? 1)).reverse()
    setFilters(sortedFilters)
    setIsOpen(false);
  };


  const allCodes = wcdata[selectedValue]?.data?.result?.map((option: any) => option.Code) ?? [];
  const currentFilter = localFilters.filter((_filter) => _filter.filterKey === filter?.filterKey)[0];
  const _isAllSelected = (filter && currentFilter && (allCodes.length === currentFilter.value.split(",").length)) ? true : false

  // function isAllSelected() {
  //   // scrollToElement();
  //   if (!filter) return false;
  //   const allCodes = wcdata[selectedValue]?.data.result?.map((option: any) => option.Code) ?? [];
  //   const currentFilter = localFilters.filter((_filter) => _filter.filterKey === filter.filterKey)[0];
  //   if (!currentFilter) return false;
  //   if (allCodes.length === currentFilter.value.split(",").length) {
  //     return true;
  //   } else {
  //     return false
  //   }
  // }

  function toggleSelectAll() {
    if (filter) {
      const allCodes = wcdata[selectedValue]?.data.result?.map((option: any) => option.Code) ?? [];
      const currentFilter = localFilters.filter((_filter) => _filter.filterKey === filter.filterKey)[0];
      const excludedFilters = localFilters.filter((_filter) => _filter.filterKey !== filter.filterKey);
      if (_isAllSelected) {
        // Set all to empty
        setLocalFilters(([...excludedFilters, { ...currentFilter, value: "" }]).sort((a, b) => (a.filterKey ?? 1) - (b.filterKey ?? 1)))
      } else {
        // Set all to checked
        const newFilter = { ...currentFilter, value: allCodes.join(",") ?? "" }
        setLocalFilters(([...excludedFilters, newFilter]).sort((a, b) => (a.filterKey ?? 1) - (b.filterKey ?? 1)))
      }
    }
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      motionPreset='slideInBottom'
      onClose={() => { setShowReportFilteringResultsModal(false); }}
      isOpen={isOpen}
      size="lg"
      isCentered
      finalFocusRef={finalFocusRef}
    >
      <ModalOverlay />
      <ModalContent maxW='700px'
      >
        <ModalHeader textStyle="h1" textAlign="center" pb={0}>Report Filtering</ModalHeader>
        <ModalCloseButton border="none" cursor="pointer" aria-label='Dismiss Claim Activity e-Alerts' />
        <Divider />
        <Box p={6}>
          <Box  p={3} pt={0}>Line of Business:</Box>
          <Stack pl={3} direction={['row', 'row']}>
            <Stack alignItems="start" direction='row'>
              <RadioGroup onClick={scrollToElement} value={lobSelected} onChange={setLobSelected} size="sm" defaultValue='WCSpeciality'>
                <Stack direction='column'>
                  <Radio value='WCSpeciality'>Workers Comp</Radio>
                  <Radio value='GLSpeciality'>Liability</Radio>
                  <Radio value='AUSpeciality'>Auto</Radio>
                  <Radio value='FISpeciality'>Fidelity/Burglary</Radio>
                  <Radio value='PMSpeciality'>Property/Marine</Radio>
                </Stack>
              </RadioGroup>
              <Box w={100} />
              <Checkbox
                checked={isSelected}
                variant="basic"
                colorScheme='gray'
                onChange={(e) => {
                  scrollToElement()
                  setIsSelected(e.target.checked)
                }}
              >
                Include Specialty
              </Checkbox>
            </Stack>
          </Stack>
          <Divider />
        </Box>
        <ModalBody >
          <Box minH={250}>
            {(isLoading || isError || !filterLookupData) &&
              <Loader />
            }
            {!isLoading && !isError && filterLookupData && (
              <TableContainer overflowX="unset" overflowY="scroll" h={250}>
                <Table ref={newRef}
                data-testid="account-search-results-table" size="sm" variant='simple'>
                  <Thead
                    style={{ overflowAnchor: "none" }}
                    borderBottomStyle="solid" borderBottomWidth={2} borderBottomColor="black" position="sticky" top={0} zIndex="docked" className='tbl-list-header'>
                    <Tr className='tbl-header-seprator'><Th colSpan={3}>&nbsp;</Th></Tr>
                    <Tr>
                      <Th w={2}>
                        <Checkbox
                          aria-label='select all'
                          colorScheme='gray'
                          onChange={(e) => toggleSelectAll()}
                          isChecked={_isAllSelected}
                        >
                        </Checkbox>
                      </Th>
                      <Th>
                        DESCRIPTION
                      </Th>
                      <Th>
                        CODE
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody data-testid="account-search-results-table-body" className='tbl-list-scroll'>
                    {
                      //@ts-ignore
                      filterLookupData?.result && filterLookupData?.result?.length > 0 && filterLookupData?.result?.map((record, index) => {
                        const { Text, Code } = record;
                        const currentFilter = filter && localFilters.filter((_filter) => _filter.filterKey === filter.filterKey)[0];
                        const isChecked = currentFilter && currentFilter.value.split(",").includes(Code);
                        return (
                          <Tr key={`id-${index}`} >
                            <Td w={2}>
                              <Checkbox
                                aria-label='select account listing'
                                onChange={(e) => toggleRecord(record)}
                                key={Code}
                                colorScheme='gray'
                                isChecked={isChecked}>
                              </Checkbox>
                            </Td>
                            <Td fontSize="sm">
                              {Text}
                            </Td>
                            <Td fontSize="sm">
                              {Code}
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={addFilterSelections}
            colorScheme='brand' ml={3}>
            Select
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
