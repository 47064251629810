import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { clearErrorMessages } from "../../features/errors/errorReducer";
import ErrorMessage from "./ErrorMessage";

interface ErrorHandlerProps {
  errors: string[];
  isOpen: boolean;
}

export function ErrorHandler(props: ErrorHandlerProps) {
  const { errors, isOpen } = props;
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(clearErrorMessages());
  };

  const apiGatewayTimeout: any = localStorage.getItem("apiGatewayTimeout");
  
  return (
    <Modal closeOnOverlayClick={false} onClose={closeModal} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {JSON.parse(apiGatewayTimeout) === 504? "Error: Report Timed Out": "Error"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {ErrorMessage()}
          {errors &&
            errors.map((error, i) => {
              const errorStrings = error.split(" - ");
              const errorMessage = errorStrings[0];
              const errorUrl = errorStrings[1];
              return (
                <Box bgColor="gray.50" p={4} my={2} key={i}>
                  <Text fontSize="md" my={0} fontWeight={600}>
                    {errorMessage}
                  </Text>
                  {errorUrl && (
                    <HStack align="stretch" py={0} my={0}>
                      <Text mr={2} fontSize="xs" py={0} my={0} fontWeight={800}>
                        URL:
                      </Text>
                      <Text fontSize="xs" py={0} my={0} fontStyle="italic">
                        {errorUrl}
                      </Text>
                    </HStack>
                  )}
                </Box>
              );
            })}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
