import { BoxProps, Center, Spinner, VStack, Text } from "@chakra-ui/react"

interface LoaderProps extends BoxProps {
    hasText?: boolean,
    loadingText?: String,
}

export const Loader = (props: LoaderProps) => {
    const { hasText = true, loadingText = 'Loading' } = props;
    return <Center {...props}>
        <VStack {...props}>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='teal.500'
                height={'70px'}
                width={'70px'}
                size="xl" />
            {hasText && <Text fontWeight="bold">{loadingText}</Text>}
        </VStack>
    </Center>
}