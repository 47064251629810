import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  table: {
    thead: {
      td: {
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderLeftColor: "white",
      },
    },
    tbody: {
      td: {
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderLeftColor: "gray.100",
      },
    },
  },
});

export const tableTheme = defineMultiStyleConfig({ baseStyle });
