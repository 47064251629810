import { Routes, Route, useSearchParams } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { RequiredAuth } from '../SecureRoute';
import HomeContainer from '../containers/HomeContainer';
import LoginError from '../components/Errors/LoginError';
import HomeForm from '../components/Shared/HomeForm';
import DetailLoss from '../components/Reports/DetailLoss';
import FinancialSummary from '../components/Reports/FinancialSummary';
import DetailChanges from '../components/Reports/DetailChanges';
import ClaimLagAnalysis from '../components/Reports/ClaimLagAnalysis';
import InsuredRetention from '../components/Reports/InsuredRetention';
import PolicySummary from '../components/Reports/PolicySummary';
import NationalPrograms from '../components/Reports/NationalPrograms';
import SavedParameters from '../components/Reports/SavedParameters';
import ReportLayout from '../components/Layout/ReportLayout';
import DrilldownSummary from '../components/Reports/DrilldownSummary';

const WrapperComponent = () => {
  const [searchParams, _] = useSearchParams();
  const page = searchParams.get("page")
  if (page==="DetailLoss"){return <DetailLoss/>}
  if (page==="FinancialSummary"){return <FinancialSummary/>}
  if (page==="DrilldownSummary"){return <DrilldownSummary/>}
  if (page==="DetailChanges"){return <DetailChanges/>}
  if (page==="ClaimLagAnalysis"){return <ClaimLagAnalysis/>}
  if (page==="InsuredRetention"){return <InsuredRetention/>}
  if (page==="PolicySummary"){return <PolicySummary/>}
  if (page==="NationalPrograms"){return <NationalPrograms/>}
  if (page==="SavedParameters"){return <SavedParameters/>}
  return <HomeForm />
}
const createRoutes = () => {
  return (
    <Routes>
      <Route path='/index.html' element={<LoginCallback errorComponent={LoginError} />} />
      <Route path='/' element={<RequiredAuth />}>
        <Route element={<HomeContainer />}>
          <Route element={<ReportLayout />}>
            <Route index element={<WrapperComponent />} />
          </Route>          
        </Route>
      </Route>
    </Routes>
  );
};

export default createRoutes;
