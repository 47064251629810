import { apiSlice } from '../../app/api/apiSlice';
import { API, REQ_TYPE } from '../../common/constants';
import { APIResult, ClaimActivityData } from '../../common/types';


export const claimActivityDrilldownApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getClaimActivityDrilldown: builder.query<ClaimActivityData[], any>({
        query: (arg) => {
          return {
            method: REQ_TYPE.POST,
            url: API.CLM_ACT_DRILLDOWN,
            body: arg,
          };
        },
        transformResponse: (response: APIResult<ClaimActivityData[]>) => response.result,
      }),
    }),
  });
  
  export const { useGetClaimActivityDrilldownQuery } = claimActivityDrilldownApiSlice;
  