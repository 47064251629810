import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  label: defineStyle({
    _active: {
        fontWeight: "bold"
    },
    _hover: {
        fontWeight: "bold"
    }
  }),
})


const sizes = {
    md: definePartsStyle({
      label: defineStyle({
        fontSize: "sm"
      })
    })
  }
  
const variants = {
  basic: defineStyle({
    label: defineStyle({
      _active: {
          fontWeight: "normal"
      },
      _hover: {
          fontWeight: "normal"
      }
    })
  })
}


export const checkboxTheme = defineMultiStyleConfig({ baseStyle, sizes, variants })