import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterOption } from '../../common/types';
import { BLANK_FILTER } from '../../common/constants';
export interface ActiveFilterState  {
    activeFilter : FilterOption
  }

  const initialState: ActiveFilterState = {
    activeFilter:BLANK_FILTER,
  }

  
export const activeFilterSlice = createSlice({
    name: 'activeFilter',
    initialState,
    reducers: {
        updateActiveFilter: (state: ActiveFilterState, action: PayloadAction<FilterOption>) =>{
            state.activeFilter=action.payload;
        },
        removeActiveFilter: (state: ActiveFilterState, action: PayloadAction<string>) =>{
          state.activeFilter=BLANK_FILTER;
        }
    }
 });

// Action creators
export const { updateActiveFilter, removeActiveFilter } = activeFilterSlice.actions;

export default activeFilterSlice.reducer;
