import { ReactComponent as QuestionCircle118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/question-circle-1-18.svg';
import { ReactComponent as AccountIdentificationAlertCloseIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-12.svg';
import { TdsCheck } from '@trv-tds/react';
import useAuth from '../../../hooks/useAuth';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { useState } from 'react';
import { Text } from '@chakra-ui/react';

const AccountIdentification = () => {
  const { isAgentUser, isInternalUser, isAccountLocationUser } = useAuth();
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [showAccountInfoDismissable, setShowAccountInfoDismissable] = useLocalStorage<boolean>("showAccountInfoDismissable", true);
  const [showAccountInfoDismissableTemporary, setShowAccountInfoDismissableTemporary] = useState(showAccountInfoDismissable);

  return (
    <>
    {!isAccountLocationUser &&(
    <div hidden={!isAgentUser && !isInternalUser} className='homeform-inner-div-account-identification'>
      <Text as="h3" textStyle="h3">
        Account Identification &nbsp;
        <div id='tooltip-example-top' className='docs-tooltip-sample' style={{ display: 'inline' }}>
          <span data-toggle='tooltip' role='button' tabIndex={0} className='tds-tooltip__container' aria-label='more information'>
            <QuestionCircle118 className='tds-icon-18' data-toggle='tooltip' color='#007395' />
            <div role='tooltip' className='tds-tooltip' data-position='bottom' style={{ height: '180px' }}>
              <p style={{ fontWeight: '300', textAlign: 'left', lineHeight: '1.6' }}>
                When choosing Policy number, enter only 8 character <br></br> policy number. Do not enter the policy prefix or policy form. <br></br> Example: GC2J-CAP-123A5678 should be entered as
                <br></br>123A5678.
                <br></br>
                <br></br>Tip for Agents: You can use the Search My Accounts section <br></br>
                below to find a listing of the accounts secured to your ID.
              </p>
            </div>
          </span>
        </div>
      </Text>

      {showAccountInfoDismissableTemporary && (
        <div className='tds-alert--info tds-alert--dismissible' tabIndex={-1} style={{ width: '100%', paddingBottom: 0, marginBottom: '2rem'}}>
          <p className='body-xsmall homeform-dismissable-p' style={{ width: '100%'}} >
          To view a One-Click Report or Claim Activity, select an account identifier, enter at least one value, and click ADD.  Click the One-Click report name or View Claim Activity button.  If your report does not appear, disable your browser’s pop-up blocker in your browser’s settings.
          </p>
          <TdsCheck onTdsChange={()=>setDontShowAgain(!dontShowAgain)} className='do-not-show-again-checkbox' name='do not show this again' checked={dontShowAgain}>
            Do not show this again.
          </TdsCheck>
            <button onClick={() => { 
              setShowAccountInfoDismissableTemporary(false)
              if(dontShowAgain){
               setShowAccountInfoDismissable(false)
              }
              }} aria-label='Dismiss alert.' className='tds-alert__dismiss' data-focus='#my-alert-info-dismissible-trigger'>
              <AccountIdentificationAlertCloseIcon alt='Close Alert' />
            </button>
        </div>
      )}
    </div>
    )}
   </>
    );
};

export default AccountIdentification;
