import { useState } from 'react';
import { TdsTopHat } from '@trv-tds/react';
import ContactUs from '../Shared/ContactUs';
import Help from '../Shared/Help';

const TopHat = () => {
  const [showContactUsDialog, setShowContactUsDialog] = useState<boolean>(false);
  const [showHelpDialog, setShowHelpDialog] = useState<boolean>(false);

  return (
    <TdsTopHat role='region' row-second-level-style='custom' aria-label='help center and contact us'>
      <div role="navigation" className='tds-nav tds-nav--row' aria-label='help and contact information' style={{ backgroundColor: 'transparent' }}>
        <ul className='tds-nav__list' style={{backgroundColor: 'transparent'}}>
          <li className='tds-nav__item' style={{ lineHeight: 0 }}>
            <button onClick={() => setShowHelpDialog(true)} className='tds-nav__link' style={{ textDecoration: 'none', backgroundColor: 'transparent', cursor: 'pointer' }}>
              <span className='tds-nav__item-text' style={{ lineHeight: 1.5, color: '#007395', fontSize: '0.70rem', fontFamily: 'FreightSans, sans-serif' }}>
                HELP CENTER
              </span>
            </button>
          </li>
          <li className='tds-nav__item' style={{ lineHeight: 0 }}>
            <button onClick={() => setShowContactUsDialog(true)} className='tds-nav__link' style={{ textDecoration: 'none', backgroundColor: 'transparent', cursor: 'pointer' }}>
              <span className='tds-nav__item-text' style={{ lineHeight: 1.5, color: '#007395', fontSize: '0.70rem', fontFamily: 'FreightSans, sans-serif' }}>
                CONTACT US
              </span>
            </button>
          </li>
        </ul>
      </div>

      {showContactUsDialog && <ContactUs setShowContactUsDialog={setShowContactUsDialog} />}
      {showHelpDialog && <Help setShowHelpDialog={setShowHelpDialog} />}
    </TdsTopHat>
  );
};

export default TopHat;
