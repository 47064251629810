import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiSlice } from './app/api/apiSlice';
import { rtkQueryErrorLogger } from './app/api/errorMiddleware';
import agentAccountReducer from './components/Shared/AgentAccountSearch/agentAccountSlice';
import profileReducer from './components/Shared/profileSlice';
import authReducer from './features/auth/authSlice';
import claimActivityErrorMessageReducer from './features/claimActivity/claimActivityErrorMsgSlice';
import errorReducer from './features/errors/errorReducer';
import activeFilterReducer from './features/formSections/activeFilterSlice';
import filterSectionWarningMessageReducer from './features/formSections/filterSectionWarningMsgSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    agentAccount: agentAccountReducer,
    profile: profileReducer,
    errors: errorReducer,
    claimActivity: claimActivityErrorMessageReducer,
    filterSection: filterSectionWarningMessageReducer,
    filterLookup: activeFilterReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkQueryErrorLogger).concat(apiSlice.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
