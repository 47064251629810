import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const xl = defineStyle({
  minW: 750,
  minH: 650
})
const lg = defineStyle({
  minW: 750,
})

const sizes = {
  lg: definePartsStyle({ dialog: lg }),
  xl: definePartsStyle({ dialog: xl }),
}

export const modalTheme = defineMultiStyleConfig({
  sizes,
})