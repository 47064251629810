import { FormControl } from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BLANK_FILTER, BLANK_GROUP } from '../../common/constants';
import { FilterOption, GroupOption, Operator, ReportData } from '../../common/types';
import { addAccountErrorMessage, addSuccessMessage, setSubmitClicked, showClaimActivityErrorMessage } from '../../features/claimActivity/claimActivityErrorMsgSlice';
import { useUpdateParametersMutation } from '../../features/reports/saveReportApiSlice';
import { useGetPrefillDataQuery } from '../../features/utils/prefillApiSlice';
import { getHistoryDate, getOper, getOperator, isValidDate, validDateFormat, validYear, yearInRange, getHistoryDateSavedParams } from '../../features/utils/utils';
import { useGetDropdownListQuery } from '../../features/utils/utisApiSlice';
import useAuth from '../../hooks/useAuth';
import useClaimActivityListData from '../../hooks/useClaimActivityListData';
import { RootState } from '../../store';
import { StackContainer } from '../Layout/StackContainer';
import PolicyNumber from '../Shared/AccountIdentification/PolicyNumber';
import AgentAccountSearch from '../Shared/AgentAccountSearch/AgentAccountSearch';
import { AccIdentification } from '../Shared/AgentAccountSearch/agentAccount';
import { resetAgentAllSelectedAccounts, updateAccountIdentification } from '../Shared/AgentAccountSearch/agentAccountSlice';
import { DateSelectionCriteria } from '../Shared/FormSections/DateSelectionCriteria';
import { FilterSection } from '../Shared/FormSections/FilterSection';
import GroupBySection from '../Shared/FormSections/GroupBySection';
import { RankSelector } from '../Shared/FormSections/RankSelector';
import { SubmissionSection } from '../Shared/FormSections/SubmissionSection';
import ReportFilteringResults from '../Shared/ReportFiltering/ReportFilteringResults';
import WCReportFilteringResults from '../Shared/ReportFiltering/WCReportFilteringResults';
import { addDateRequiredErrorMessage, addHistoryDateRequiredErrorMessage } from '../../features/errors/errorReducer';
import { AppContext } from '../../context/appContext';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useLocation } from 'react-router-dom';

const FiancialSummary = () => {

  const { profileData } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const [fromDate, setFromDate] = useState<string>();
  const [toDate, setToDate] = useState<string>();
  const [historyDate, setHistoryDate] = useState<string>("");
  const [dateHasErrors, setDateHasErrors] = useState<boolean>(false);
  const [filters, setStateFilters] = useState<FilterOption[]>([BLANK_FILTER]);
  const [orgLevelData, setOrgLevelData] = useState<any>();
  const [acctOrgLevelData, setAcctOrgLevelData] = useState<any>();
  const [groups, setGroups] = useState<GroupOption[]>([BLANK_GROUP]);
  const [showReportFilteringResultsModal, setShowReportFilteringResultsModal] = useState<boolean>(false);
  const userAccountIdentification: AccIdentification = useSelector((state: RootState) => state.agentAccount.accountIdentification);
  const { claimActivityErrorMessage: claimActivityListError, showClaimActivityError } = useSelector((state: RootState) => state.claimActivity);
  const { accountIdentificationSelections } = userAccountIdentification;
  const [activeFilter, setActiveFilter] = useState<FilterOption | undefined>();
  const chosenFilter: FilterOption = useSelector((state: RootState) => state.filterLookup.activeFilter);
  const showModal = () => setShowReportFilteringResultsModal(true);
  //  const [dropDownElement, setDropDownElement] = useState<string>('Incurred');
  const [groupByHasErrors, setGroupByHasErrors] = useState<boolean>();
  const [resetDates, setResetDates] = useState<boolean | undefined>(false);
  const [reportName, setReportName] = useState<string>("Financial Summary");
  const [reportHeading, setReportHeading] = useState<string>("");
  const [reportTypeSelected, setReportTypeSelected] = useState<string>('pdf');
  const [saveResponse, setSaveResponse] = useState<number>(0);
  const [missingReportName, setMissingReportName] = useState<boolean>(false);
  const [previousSelectionsCount, setPreviousSelectionsCount] = useState<number>(0);
  const [clearMsg, setClearMsg] = useState<boolean>(false);
  const [clearedCriteriaMsg, setClearedCriteriaMsg] = useState<boolean>(false);
  const filteredGroups = [...groups].filter((_group) => (_group.field !== "" || _group.label !== ""))
  const filteredFilters = [...filters].filter((_filter) => (_filter.field !== "" || _filter.dbValue !== ""))
  const [rankingValue, setRankingValue] = useState<string>('No Ranking');
  const { data: dropDownData } = useGetDropdownListQuery();
  const defaultAppConfig = { REACT_APP_DOMAIN_URL: undefined }
  const { appConfig: { REACT_APP_DOMAIN_URL } = defaultAppConfig } = useContext(AppContext);
  const [reportDataSummary, setReportDataSummary] = useLocalStorage<any>("reportDataSummary", {});
  const [defaultSortGroups, setDefaultSortGroups] = useLocalStorage<any>("defaultSortGroups", {});
  const [paramId, setParamId] = useState<number>();

  //Note this is needed because we validate account information with the claim activity service when user enters it in account ID section
  useClaimActivityListData();

  const setFilters = (filters: FilterOption[]) => {
    setStateFilters(filters)
  }
  const ref = useRef<HTMLInputElement>(null);
  const groupRef = useRef<HTMLInputElement>(null);

  const formattedFilters = [...filters].map((filter) => {
    const newFilter = {
      ...filter,
      operatorError: false,
      dateFormatError: false,
      dateInvalidError: false,
      yearInvalidError: false,
      yearFormatError: false,
      invalidDateOperatorError: false,
      multipleValuesGTError: false,
      multipleValuesLTError: false
    }
    if ((newFilter.field === 'Date of Hire (WC)' || newFilter.field === 'Date of Loss' || newFilter.field === 'Date Notice Recv\'d' || newFilter.field === 'Policy Eff Date'
      || newFilter.field === 'Accident Year' || newFilter.field === 'Policy Year') && (newFilter.operator === 'Contains' || newFilter.operator === 'Omits' || newFilter.operator === 'Starts With')) {
      newFilter.invalidDateOperatorError = true;
    }
    if (newFilter.field !== "" && newFilter.value.length > 0 && newFilter.isDirty) {
      if (newFilter.operator === "") {
        newFilter.operatorError = true;
      }
      if (newFilter.field === 'Date of Hire (WC)' || newFilter.field === 'Date of Loss' || newFilter.field === 'Date Notice Recv\'d' || newFilter.field === 'Policy Eff Date') {
        if (newFilter.value.length === 8 && newFilter.value.indexOf("/") === -1) {
          newFilter.value = newFilter.value.substring(0, 2) + "/" + newFilter.value.substring(2, 4) + "/" + newFilter.value.substring(4, 8);
        }
        if (!isValidDate(newFilter.value)) {
          newFilter.dateInvalidError = true;
        }
        if (!validDateFormat(newFilter.value)) {
          newFilter.dateFormatError = true;
        }
      }
    }
    if (newFilter.isDirty && (newFilter.field === "Accident Year" || newFilter.field === "Policy Year")) {
      if (!validYear(newFilter.value)) {
        newFilter.yearFormatError = true;
      }
      if (!yearInRange(newFilter.value)) {
        newFilter.yearInvalidError = true;
      }
    }
    if (newFilter.isDirty && (newFilter.field === "Date of Hire (WC)" ||
      newFilter.field === "Date of Loss" ||
      newFilter.field === "Date Notice Recv'd" ||
      newFilter.field === "Policy Eff Date"
    )) {
      if (!isValidDate(newFilter.value)) {
        newFilter.dateInvalidError = true;
      }
      if (!validDateFormat(newFilter.value)) {
        newFilter.dateFormatError = true;
      }

    }
    if (newFilter.operator === "Greater Than" && filter.value.indexOf(",") > 0) {
      newFilter.multipleValuesGTError = true;
    }
    if (newFilter.operator === "Less Than" && filter.value.indexOf(",") > 0) {
      newFilter.multipleValuesLTError = true;
    }
    return newFilter
  });
  const formattedFilteredFilters = [...formattedFilters].filter((_filter) => (_filter.field !== "" || _filter.dbValue !== ""));
  const filtersHaveErrors = [...formattedFilters].filter(filter => (filter.dateFormatError || filter.dateInvalidError || filter.yearFormatError || filter.yearInvalidError || filter.invalidDateOperatorError || filter.multipleValuesLTError || filter.multipleValuesGTError || (filter.operator === "" && filter.field !== ""))).length > 0;
  useEffect(() => {
    if (previousSelectionsCount > accountIdentificationSelections.length && userAccountIdentification.accountIdentificationMethod === 'Account Number' && (filteredFilters.length > 0 || filters[0]?.field !== "" || filters[0]?.operator !== "" || filters[0]?.value !== "" ||
      filteredGroups.length > 0 || groups[0]?.field !== "" || groups[0]?.label !== "" || rankingValue !== 'No Ranking')) {
      removeBlankFields();
      setFilters([BLANK_FILTER]);
      setGroups([BLANK_GROUP]);
      setResetDates(true);
      setRankingValue('No Ranking');
      setClearedCriteriaMsg(true);
    } else {
      setClearedCriteriaMsg(false);
    }
    setPreviousSelectionsCount(accountIdentificationSelections.length);
  }, [accountIdentificationSelections]);

  useEffect(() => {
    scrollToElement();
  }, [filtersHaveErrors]);

  useEffect(() => {
    dispatch(showClaimActivityErrorMessage(false))
    dispatch(addDateRequiredErrorMessage(false))
    dispatch(addHistoryDateRequiredErrorMessage(false))
    setDateHasErrors(false);
    dispatch(setSubmitClicked(false));
    if (location?.state?.account_id_selections) {
      setReportHeading(location?.state?.report_heading);
      setReportName(location?.state?.report_name);
      setReportTypeSelected(location?.state?.report_type);
      setToDate(location?.state?.to_date);
      setFromDate(location?.state?.from_date);
      const hDt=getHistoryDateSavedParams(location?.state?.history_date);
      setHistoryDate(hDt!==undefined?hDt:"");
      const _filters=getFilters(location?.state?.filters);
      setFilters(_filters);
      setActiveFilter(_filters[0]);
      setRankingValue(getRanking(location?.state?.ranking));
      setGroups(getGroups(location?.state?.groups));
      setReportTypeSelected(location?.state?.report_type);
      dispatch(updateAccountIdentification({
        ...userAccountIdentification,
        accountIdentificationMethod: location?.state?.account_id_method,
        accountIdentificationSelections: location?.state?.account_id_selections
      }))
      setParamId(location?.state?.param_id);
    }
  }, [])
  function getFilters(filters: any[] ){
    let rtnFilters:FilterOption[] = [];
    if(filters){
      filters.forEach((filter,index)=>{
        rtnFilters.push({
          filterKey: index+1,
          field: filter.DisplayName,
          operator: getOper(filter.Operator),
          value: filter.LimitValue,
          dbValue: filter.FieldName,
          isDirty: true,
          operatorError: false,
          dateInvalidError: false,
          dateFormatError: false,
          yearInvalidError: false,
          yearFormatError: false,
          invalidDateOperatorError: false,
          multipleValuesGTError: false,
          multipleValuesLTError: false,
        })
      })
    }
    return rtnFilters.length===0 ? [BLANK_FILTER] : rtnFilters;
  }

  function getGroups(groups: any[] ){
    let rtnGroups:GroupOption[] = [];
    if(groups){
      groups.forEach((group)=>{
        rtnGroups.push({
          field: group.FieldName,
          label: group.Label,
          hasSubtotal: group.Subtotal,
          hasPageBreak: group.PageBreak
        })
      })
    }
    return rtnGroups.length===0? [BLANK_GROUP] : rtnGroups;
  }
  
  function getRanking(selection: string) {
    if (selection === '') {
      return 'No Ranking';
    } else if (selection === "NBROFCLMS") {
      return 'Claim Count';
    } else if (selection === "INCUR") {
      return 'Incurred Dollars';
    } else {
      return "-1";
    }
  }

  const lossCriteria = {
    UserInformation: profileData[0]
  };
  let shouldSkip = (profileData === undefined || profileData === null || profileData?.length === 0) ||
    (profileData?.length > 0 && (
      profileData[0]?.Role.IsInternalUser ||
      (profileData[0]?.Role.IsAccountId === false && profileData[0]?.Role.IsAccountLocationId === false)
    ));

  const { data: prefill_data, isSuccess, isLoading, isError, error, } = useGetPrefillDataQuery(lossCriteria,
    { skip: shouldSkip });
  useEffect(() => {
    if (prefill_data && prefill_data.result && prefill_data.result.Selections && !isLoading && profileData) {
      setAcctOrgLevelData(prefill_data.result);
    }
  }, [prefill_data, isLoading, profileData]);

  const reportData: ReportData = {
    LossesCriteria: {
      LargeLossValue: "",
      LargeLossType: 0,
      ClaimLimitDollarType: 0,
      ClaimLimitOperator: null,
      BeginDollarAmount: null,
      EndDollarAmount: null,
      Orgs: getOrgLevelData(),
      EffYearFrom: 0,
      EffYearTo: 0,
      RankFieldName: getRankingValue(rankingValue),
      HistoryDate: getHistoryDate(historyDate),
      HistoryDateSecond: "0001-01-01T00:00:00",
      Limits: formattedFilteredFilters && formattedFilteredFilters.length > 0 ?
        formattedFilteredFilters.map((_filter) => {
          return {
            FieldName: _filter.dbValue,
            DisplayName: _filter.field,
            Operator: getOperator(_filter.operator) === "-1" ? "EQ" : getOperator(_filter.operator),
            LimitValue: _filter.value,
            LimitType: 0
          }
        })
        : [],
      ReportCategory: 0,
      AccountName: "",
      Selections: getSelections(),
      FromDate: fromDate,
      ToDate: toDate,
      Heading: reportHeading,
      OutputFormat: reportTypeSelected === 'pdf' ? 0 : 1,
      ReportType: 4,
      AccountIdType: getAccountIdType(),
      SortGroups: filteredGroups && filteredGroups.length > 0 ?
        filteredGroups.map((_group) => {
          const orgs = _group.field.split("ORG")
          const isOrg = orgs.length > 1 && orgs[1][0] !== "_"
          const lookupOrgs = ["Accident Result", "Adjusting Office", "Body Part", "Line of Insurance"]
          const _lookupOrgs = [...dropDownData?.lookup?.map((org) => org._value) ?? [], ...lookupOrgs]
          return {
            FieldName: _group.field,
            Label: _group.label,
            Subtotal: _group.hasSubtotal,
            PageBreak: _group.hasPageBreak,
            LookupFieldText:
            isOrg ||
            (!orgLevelData || !orgLevelData.Headers
              ? false
              : orgLevelData.Headers.includes(_group.originalLabel)) ||
              _lookupOrgs.includes(_group.field),          }
        }).reverse()
        : [],
      BaseUrl: `${REACT_APP_DOMAIN_URL}?page=DrilldownSummary&`,
      ShowDrilldown: true,
      ReportName: reportName,
      ParamId: 0,
      InsuredRetentionType: 0,
    },
    UserInformation: profileData && profileData.length ? profileData[0] : undefined
  }

  const saveReportData = {
    ...reportData,
    ParameterCriteria: {
      ParamId: paramId===undefined?0:paramId,
      EventType: paramId===undefined?0:1,
      OverrideOrgCheck: false,
    },
  };

  const [saveData] = useUpdateParametersMutation()

  const scrollToElement = () => {
    if (filtersHaveErrors)
      ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToTop = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  const scrollToGroup = () => {
    if (groupByHasErrors) groupRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  function getOrgLevelData() {
    if (prefill_data && prefill_data.result && prefill_data.result.Orgs) {
      return prefill_data.result.Orgs;
    }
    else {
      return orgLevelData;
    }
  }

  function getSelections() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (profileData[0].Role.IsAccountId === true || profileData[0].Role.IsAccountLocationId === true) {
        if (prefill_data && prefill_data.result && prefill_data.result.Selections) {
          return prefill_data.result.Selections;
        }
      } else {
        return accountIdentificationSelections;
      }
    }
  }

  function getAccountIdType() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (profileData[0].Role.IsAccountId === true || profileData[0].Role.IsAccountLocationId === true) {
        if (prefill_data && prefill_data.result)
          return prefill_data ? prefill_data.result.AccountIdType : "";
      } else {
        if (userAccountIdentification.accountIdentificationMethod === 'Policy Number')
          return 2;
        else if (userAccountIdentification.accountIdentificationMethod === 'Account Number')
          return 1;
        else if (userAccountIdentification.accountIdentificationMethod === 'SAI Number')
          return 4;
        else if (userAccountIdentification.accountIdentificationMethod === 'Producer Code')
          return 3;
        else
          return -1;
      }
    }
  }

  function getRankingValue(selection: string) {
    if (selection === 'No Ranking') {
      return "";
    } else if (selection === 'Claim Count') {
      return "NBROFCLMS";
    } else if (selection === 'Incurred Dollars') {
      return "INCUR";
    } else {
      return "-1";
    }
  }
  const removeBlankFields = () => {
    if (filteredGroups.length === 0) {
      setGroups([BLANK_GROUP])
    } else {
      setGroups(filteredGroups)
    }
    if (filteredFilters.length === 0) {
      setFilters([BLANK_FILTER])
    } else {
      setFilters(filteredFilters)
    }
  }

  const saveForm = async () => {
    setReportDataSummary(reportData);
    setDefaultSortGroups(reportData.LossesCriteria.SortGroups);
    removeBlankFields()
    dispatch(showClaimActivityErrorMessage(true))
    if (claimActivityListError !== "") {
      scrollToTop();
      return true;
    } else if (groupByHasErrors) {
      scrollToGroup()
      return true
    } else {
      if ((profileData && profileData.length > 0 && profileData[0].Role &&
        (profileData[0].Role.IsAccountId !== true && profileData[0].Role.IsAccountLocationId !== true)) && accountIdentificationSelections.length === 0) {
        dispatch(addAccountErrorMessage("Please enter an account identifier to save the report criteria."))
      } else if (reportName.trim() === '') {
        setMissingReportName(true);
      } else {
        setMissingReportName(false);
        const { data }: any = await saveData(saveReportData)
        setSaveResponse(Number(data.result))
        dispatch(addSuccessMessage(""));
        if (data && data.result === "1") {
          dispatch(addSuccessMessage("success"));
        }else if(data && data.result === "2"){
          scrollToTop();
          dispatch(addSuccessMessage("updated"));
        }
      }
    }
  }

  const submitForm = () => {
    // To perform DrilldownSummary request, storing previous payload in localhost to retrive in Drilldown summary page.
    // window.localStorage.setItem('reportDataSummary', JSON.stringify(reportData));
    setReportDataSummary(reportData);
    setDefaultSortGroups(reportData.LossesCriteria.SortGroups);
    removeBlankFields()
    dispatch(showClaimActivityErrorMessage(true))
    if (claimActivityListError !== "") {
      scrollToTop();
      return true;
    } else if (groupByHasErrors) {
      scrollToGroup()
      return true
    } else {
      if (accountIdentificationSelections.length === 0) {
        dispatch(addAccountErrorMessage("Please enter an account identifier to run the report."))
      }
      //@ts-ignore
      const newFilters = [...formattedFilteredFilters]?.map((filter) => {
        if ((filter.field === 'Accident Year' || filter.field === 'Policy Year') && !validYear(filter.value)) {
          filter.yearFormatError = true;
          filter.isDirty = true;
        } else {
          filter.yearFormatError = false;
        }
        if ((filter.field === 'Date of Hire (WC)' || filter.field === 'Date of Loss' || filter.field === 'Date Notice Recv\'d' || filter.field === 'Policy Eff Date') && !isValidDate(filter.value)) {
          filter.dateInvalidError = true;
          filter.isDirty = true;
        } else {
          filter.dateInvalidError = false;
        }

        if ((filter.field === 'Date of Hire (WC)' || filter.field === 'Date of Loss' || filter.field === 'Date Notice Recv\'d' || filter.field === 'Policy Eff Date') && !validDateFormat(filter.value)) {
          filter.dateFormatError = true;
          filter.isDirty = true;
        } else {
          filter.dateFormatError = false;
        }
        return filter;
      })
      const _newFilters = newFilters.length===0 ? [BLANK_FILTER] : newFilters
      setFilters(_newFilters);
      const hasErrors =
        newFilters.filter(
          (filter) =>
            filter.dateFormatError ||
            filter.dateInvalidError ||
            filter.yearFormatError ||
            filter.yearInvalidError ||
            filter.invalidDateOperatorError ||
            (filter.operator === "" && filter.field !== "")
        ).length > 0 ||
        (accountIdentificationSelections.length === 0 && profileData[0]?.Role.IsAccountId === false && profileData[0]?.Role.IsAccountLocationId === false);
      return hasErrors;
    }
  }

  const resetForm = () => {
    removeBlankFields()
    //    resetLargeLossFilter();
    setFilters([BLANK_FILTER]);
    setGroups([BLANK_GROUP]);
    //reset dates
    setResetDates(false);
    // Used to get around the useEffect pattern in DateSelectionCriteria
    setTimeout(() => {
      setResetDates(true);
    }, 1)
    setDateHasErrors(false);
    setRankingValue('No Ranking');
    //remove id's and reset to policy number
    dispatch(updateAccountIdentification({ ...userAccountIdentification, ['accountIdentificationMethod']: 'Policy Number' }));
    dispatch(resetAgentAllSelectedAccounts());
    setMissingReportName(false);
    setSaveResponse(0);
    clearWarningMessages();
    dispatch(addSuccessMessage(""));
  }


  function clearWarningMessages() {
    setClearMsg(true);
  }
  return (<div>
    <PolicyNumber clearMsg={clearMsg} setClearMsg={setClearMsg} clearedCriteriaMsg={clearedCriteriaMsg} setClearedCriteriaMsg={setClearedCriteriaMsg} hasCustomFilters={userAccountIdentification.accountIdentificationMethod === "Account Number" && (filteredFilters.length > 0 || filters[0]?.field !== "" || filters[0]?.operator !== "" || filters[0]?.value !== "" ||
      filteredGroups?.length > 0 || groups[0]?.field !== "" || groups[0]?.label !== "" || rankingValue !== 'No Ranking')}
    />
    <AgentAccountSearch />
    <DateSelectionCriteria
      hasDouble={false}
      reset={resetDates}
      setReset={setResetDates}
      fromDate={fromDate}
      setFromDate={setFromDate}
      toDate={toDate}
      setToDate={setToDate}
      historyDate={historyDate}
      setHistoryDate={setHistoryDate}
      setDateHasErrors={setDateHasErrors}
      dateHasErrors={dateHasErrors}
      disabledProducer={true}
    />
    <FormControl ref={ref} >
      <StackContainer spacing={6} title="Optional Report Criteria" >
        <FilterSection
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          onShowModal={showModal}
          filters={formattedFilters}
          setFilters={setFilters}
          setOrgLevelData={setOrgLevelData}
          acctOrgLevelData={acctOrgLevelData}
          pageName="FinancialSummary"
        />
        <div ref={groupRef}>
          <GroupBySection
            groups={groups}
            setGroups={setGroups}
            setGroupByHasErrors={setGroupByHasErrors}
            setOrgLevelData={setOrgLevelData}
            acctOrgLevelData={acctOrgLevelData}
          />
        </div>
        <RankSelector rankingValue={rankingValue} setRankingValue={setRankingValue} />
      </StackContainer>
    </FormControl>
    <SubmissionSection
      onResetForm={resetForm}
      onSaveForm={saveForm}
      onSubmitForm={submitForm}
      reportName={reportName}
      setReportName={setReportName}
      reportHeading={reportHeading}
      setReportHeading={setReportHeading}
      reportTypeSelected={reportTypeSelected}
      setReportTypeSelected={setReportTypeSelected}
      missingReportNameError={missingReportName}
      setMissingReportName={setMissingReportName}
      reportData={reportData}
      saveResponse={saveResponse}
      setSaveResponse={setSaveResponse}
      disableButtons={dateHasErrors || filtersHaveErrors || (claimActivityListError !== "" && showClaimActivityError)}
      formHasErrors={filtersHaveErrors}
      paramId={paramId}
    />
    {showReportFilteringResultsModal && chosenFilter.field !== 'Accident Cause' && (
      <ReportFilteringResults
        finalFocusRef={ref}
        setShowReportFilteringResultsModal={setShowReportFilteringResultsModal}
        isOpen={showReportFilteringResultsModal}
        setIsOpen={setShowReportFilteringResultsModal}
        setFilters={setFilters}
        filters={filters}
        filter={activeFilter}
      />)}
    {showReportFilteringResultsModal && chosenFilter.field === 'Accident Cause' &&
      (<WCReportFilteringResults
        finalFocusRef={ref}
        setShowReportFilteringResultsModal={setShowReportFilteringResultsModal}
        isOpen={showReportFilteringResultsModal}
        setIsOpen={setShowReportFilteringResultsModal}
        setFilters={setFilters}
        filters={filters}
        filter={activeFilter}
      />)
    }
  </div>
  );
};

export default FiancialSummary;
