import { Box, Checkbox, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { ReactComponent as SearchResultsDeleteAlertCloseIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-12.svg';
import { TdsField, TdsLoadingSpinner } from '@trv-tds/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedQuery, ReportData, SavedParameterSearchResult} from '../../../common/types';
import useAuth from '../../../hooks/useAuth';
import { useGetCriteriaSavedParametersMutation, useGetReportSavedParametersMutation, useSavedParametersMutation } from '../../../features/savedParameters/savedParametersApiSlice';
import saveAs from 'file-saver';
import { setSavedParametersErrorMessage } from '../../../features/claimActivity/claimActivityErrorMsgSlice';
import { useDispatch } from 'react-redux';
import { useUpdateParametersMutation } from '../../../features/reports/saveReportApiSlice';
type SavedParametersSearchResultsProps = {
  searchResults: SavedParameterSearchResult[]
  showResults: boolean
  isLoading: boolean
};

const SavedParametersSearchResults = ({ searchResults, showResults, isLoading }: SavedParametersSearchResultsProps) => {
  const { profileData } = useAuth();
  const [showRenameForm, setShowRenameForm] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [deleteReport, setDeleteReport] = useState<boolean>(false);
  const [checkedRow, setCheckedRow] = useState<number>();
  const [submitLoading, setSubmitLoading] = useState(false)
  const navigate = useNavigate();
  const [postGetReportSavedParamsData] = useGetReportSavedParametersMutation();
  const [getCriteriaSavedParamsData] = useGetCriteriaSavedParametersMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showResults) {
      setCheckedRow(undefined)
    }

  }, [showResults])


  // useEffect(() => {
  //   if (deleteReport) {
  //     setDeleteReport(false);
  // ParameterCriteria": { 
  //   "ParamId": checkedRow.PARAM_ID, 
  //     "EventType": 3, 
  //     "OverrideOrgCheck": false 
  //     } 
//   }

  // }, [deleteReport])

  // const _lossCriteria: AuthenticatedQuery = {
  //   // UserInformation: isDefaultUser
  //   //   ? DEFAULT_SAVED_PARAMETERS_USER
  //   //   : profileData[0],
  //   UserInformation: profileData[0]
  // };

  // const [
  //   loadSavedParameters,
  //   {
  //     data: savedParametersData,
  //     isLoading: isSavedParametersLoading,
  //     isError: isSavedParametersError,
  //     isSuccess: isSavedParametersSuccess,
  //     error: getSavedParametersError,
  //   },
  // ] = useGetReportSavedParametersMutation();


    async function openPDF(pId: number) {
      const submitReportData={
            UserInformation: profileData[0],
            ParameterCriteria: {
              ParamId: pId,
              OutputFormat:0,
              OverrideOrgCheck: false
            }
      }
      setSubmitLoading(true)
      if (submitReportData) {
      //@ts-ignore
        const { data }: any =await postGetReportSavedParamsData(submitReportData);
        const moreThanSixMb: number = Number(data?.messages[0].code) || 0;
        const windowParams = `left=200, top=200, toolbar=0, status=0, width=${screen.width + (-200)}, height=${window.innerHeight}`;
        if (data && data.result) {
          if (moreThanSixMb == 1) {
            dispatch(setSavedParametersErrorMessage(""));
              console.log('Report file size is more than 6 MB.', data.result);
              const pdfURL: string = data.result;
              window.open(pdfURL, 'report.pdf', windowParams);
          }else{
            dispatch(setSavedParametersErrorMessage(""));
              const blob = base64toBlob(`data:application/pdf;base64, ${data.result}`);
              const fileURL = window.URL.createObjectURL(blob);
              console.log('URL ::', fileURL.substring(5));
              window.open(fileURL, 'primary', windowParams);
          }
        }else if(data?.messages[0].code !== '0'){
          dispatch(setSavedParametersErrorMessage(data?.messages[0].description));
        }
      }
      setSubmitLoading(false)
    }

    async function openXLSX(pId: number) {
      const submitReportData={
        UserInformation: profileData[0],
         ParameterCriteria: {
          ParamId: pId,
          OutputFormat:1,
         OverrideOrgCheck: false
        }
     }
     setSubmitLoading(true)
      //@ts-ignore
      const { data }: any = await postGetReportSavedParamsData(submitReportData);
      const moreThanSixMb: number = Number(data?.messages[0].code) || 0;
      if (data && data.result) {
        if (moreThanSixMb == 1) {
          dispatch(setSavedParametersErrorMessage(""));
          const linkSource = data.result;
          const fileName = `report.xlsx`;
          saveAs(linkSource, fileName);
      } else {
        dispatch(setSavedParametersErrorMessage(""));
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.result}`;
          const fileName = `report.xlsx`;
          saveAs(linkSource, fileName);
        }
       }else if(data?.messages[0].code !== '0'){
      dispatch(setSavedParametersErrorMessage(data?.messages[0].description));
    }
    setSubmitLoading(false)
    // }
  }


  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

 
  const rowProps = {
    borderRight: "1px",
    borderBottom: "1px",
    borderColor: "gray.100",
    width: "120px",
    fontSize: "xs",
    wordWrap: 'break-word',
  }

  async function modify(row: any) {
      const submitReportData={
        UserInformation: profileData[0],
        ParameterCriteria: {
          ParamId: row.PARAM_ID,
          OverrideOrgCheck: false
        }
      }
        setSubmitLoading(true)
        //@ts-ignore
        const { data }: any = await getCriteriaSavedParamsData(submitReportData);
        if (data && data.result) {
          setSubmitLoading(false);
          if (row.RPT_TYPE_NM === 'Detail Loss') {
              navigate('?page=DetailLoss',{state: {
                account_id_selections: data.result.Selections,
                account_id_method: getAccountIdType(data.result.AccountIdType),
                report_name: data.result.ReportName,
                report_heading: data.result.Heading,
                report_type: data.result.OutputFormat===0 ? 'pdf' : 'excel',
                to_date: data.result.ToDate,
                from_date: data.result.FromDate,
                history_date: data.result.HistoryDate,
                filters: data.result.Limits,
                large_loss_value: data.result.LargeLossValue,
                large_loss_type: getLargeLossType(data.result.LargeLossType),
                groups: data.result.SortGroups,
                param_id: data.result.ParamId,
            }});
          }else if(row.RPT_TYPE_NM === 'Financial Summary'){
            navigate('?page=FinancialSummary',{state: {
              account_id_selections: data.result.Selections,
              account_id_method: getAccountIdType(data.result.AccountIdType),
              report_name: data.result.ReportName,
              report_heading: data.result.Heading,
              report_type: data.result.OutputFormat===0 ? 'pdf' : 'excel',
              to_date: data.result.ToDate,
              from_date: data.result.FromDate,
              history_date: data.result.HistoryDate,
              filters: data.result.Limits,
              ranking: data.result.RankFieldName,
              groups: data.result.SortGroups,
              param_id: data.result.ParamId,
          }});
        }else if(row.RPT_TYPE_NM === 'Detail Changes'){
          navigate('?page=DetailChanges',{state: {
            account_id_selections: data.result.Selections,
            account_id_method: getAccountIdType(data.result.AccountIdType),
            report_name: data.result.ReportName,
            report_heading: data.result.Heading,
            report_type: data.result.OutputFormat===0 ? 'pdf' : 'excel',
            to_date: data.result.ToDate,
            from_date: data.result.FromDate,
            history_date: data.result.HistoryDate,
            history_date_second: data.result.HistoryDateSecond,
            filters: data.result.Limits,
            claim_limit_type: data.result.ClaimLimitDollarType,
            claim_limit_operator: data.result.ClaimLimitOperator,
            claim_limit_amount: data.result.BeginDollarAmount,
            claim_limit_end_amount: data.result.EndDollarAmount,
            groups: data.result.SortGroups,
            param_id: data.result.ParamId,
        }});
      }else if(row.RPT_TYPE_NM === 'Claim Lag'){
        navigate('?page=ClaimLagAnalysis',{state: {
          account_id_selections: data.result.Selections,
          account_id_method: getAccountIdType(data.result.AccountIdType),
          report_name: data.result.ReportName,
          report_heading: data.result.Heading,
          report_type: data.result.OutputFormat===0 ? 'pdf' : 'excel',
          to_date: data.result.ToDate,
          from_date: data.result.FromDate,
          filters: data.result.Limits,
          groups: data.result.SortGroups,
          param_id: data.result.ParamId,
      }});
      }
        }
  }


 
            
  function getAccountIdType(type: number){
    if(type === 1){
      return "Account Number";
    }else if(type === 2){
      return "Policy Number";
    }else if(type === 3){
      return "Producer Code";
    }else if(type === 4){
      return "SAI Number";
    }
  }

  function getLargeLossType(type: number){
    if(type === 0){
      return "Incurred";
    }else if(type === 1){
      return "Paid";
    }else if(type === 2){
      return "Outstanding";
    }
  }

  function submit(row: any) {
    if(row.RPT_CRIT_FORMAT_CD.trim() === 'PDF'){
    openPDF(row.PARAM_ID);
    }else if(row.RPT_CRIT_FORMAT_CD.trim() === 'XLS'){
      openXLSX(row.PARAM_ID);
    }
  }
  const [saveData] = useUpdateParametersMutation();

  async function deleteParam(row: any) {
    const submitReportData={
      UserInformation: profileData[0],
      ParameterCriteria: {
        ParamId: row.PARAM_ID,
        EventType: 3, 
        OverrideOrgCheck: false
      }
    };
    setSubmitLoading(true)
    const { data }: any = await saveData(submitReportData);
//    const { data }: any = await useUpdateParametersMutation(submitReportData);
    if (data && data.result) {
      setSubmitLoading(false);
    }
  }

  return (
    <div className='saved-parameter-search-results-main-div'>
      {showDeleteAlert && (
        <div className='tds-alert--warning tds-alert--dismissible saved-parameter-search-results-delete-div' tabIndex={-1}>
          <h2 className='tds-alert__title' id='dismissible-warning-alert'>
            DELETE REPORT
          </h2>
          <p style={{ marginTop: '0.5rem' }}>Are you sure you want to remove this report from your library?</p>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between center', margin: '0 auto' }}>
            <button onClick={() => setDeleteReport(true)} className='tds-button--secondary tds-button--small' type='button' style={{ height: '2rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
              DELETE
            </button>
            <button onClick={() => setShowDeleteAlert(false)} className='tds-button--tertiary tds-button--small' type='button' style={{ height: '2rem' }}>
              CANCEL
            </button>
          </div>

          <button onClick={() => setShowDeleteAlert(false)} aria-label='Dismiss alert.' className='tds-alert__dismiss' data-focus='#my-alert-info-dismissible-trigger'>
            <SearchResultsDeleteAlertCloseIcon alt='Close Alert' />
          </button>
        </div>
      )}

      {((!searchResults || searchResults.length === 0) && !isLoading) &&
        <Text fontSize='2xl'>No Saved Parameters found</Text>
      }

      {searchResults && searchResults.length > 0 &&
        <TableContainer
          whiteSpace="break-spaces"
        >
          <Table
            size="sm"
            variant='unstyled'
            layout="fixed"
          >
            <Thead
              mt={-4}
              pt={4}
              position="sticky"
              top={0}
              backgroundColor="white"
              boxShadow="inset 0 -2px 0 #000000"
              zIndex={1}
              display="table"
              width="100%"
            >
              <Tr>
                <Td colSpan={6}>
                  <Text as="h3" textStyle="h3">
                    Search Results
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Th
                  width="24px"
                ></Th>
                <Th
                  width="100px"
                >
                  ACCOUNT NAME
                </Th>
                <Th
                  width="180px"
                >
                  REPORT NAME
                </Th>
                <Th width="120px">
                  REPORT TYPE
                </Th>
                <Th width="150px">
                  ACCOUNT IDENTIFICATION
                </Th>
                <Th
                  width="60px"
                >
                  PGM CODE
                </Th>
              </Tr>
            </Thead>
            <Tbody
              maxHeight="400px"
              display="block"
              overflowY="auto"
              mt={4}
            >
              {
                searchResults.map((record: any, index) => {
                  const { RPT_PGMCODE_TXT, RPT_ACCT_NM, RPT_NM, RPT_TYPE_NM, RPT_SELECTION_TXT, RPT_PGMCODE_TXT1 } = record;
                  const accountIdentificationNumbers = RPT_SELECTION_TXT.split(",")?.map((string: string) => string?.replace(/\s/g, ''))
                  const splitPGMCodes = RPT_PGMCODE_TXT?.split(",");
                  const firstPgmCode = RPT_PGMCODE_TXT1 === "Y" && splitPGMCodes && !!splitPGMCodes[0] ? splitPGMCodes[0] : "N/A"
                  return (
                    <Tr
                      display="table"
                      width="100%"
                      style={{ tableLayout: 'fixed' }}

                    >
                      <Td {...rowProps} alignContent="center" padding="5px" width="24px" maxWidth="24px">
                        <input type="checkbox"
                          // size="sm"
                          data-testid='select-row'
                          checked={checkedRow === index}
                          onChange={(e) => {
                            if (checkedRow === index) {
                              setCheckedRow(undefined)
                            } else {
                              setCheckedRow(index)
                            }
                          }}

                        />
                      </Td>
                      <Td {...rowProps} isTruncated width="100px" maxWidth="220px" whiteSpace="break-spaces">
                        {RPT_ACCT_NM}
                      </Td>
                      <Td {...rowProps} width="180px" maxWidth="180px"  >
                        {RPT_NM}

                      </Td>
                      <Td {...rowProps} isTruncated width="120px" maxWidth="120px" whiteSpace="break-spaces">
                        {RPT_TYPE_NM}
                      </Td>
                      <Td {...rowProps} isTruncated width="150px" maxWidth="150px">
                        <Tooltip label={RPT_SELECTION_TXT.includes(",") ? RPT_SELECTION_TXT?.split(",").join(", ") : null}>
                          <Box overflow="hidden" textOverflow="ellipsis">
                            {`${accountIdentificationNumbers[0]}${accountIdentificationNumbers[1] ? "," : ""}${accountIdentificationNumbers[1] ?? ''}`}
                            {accountIdentificationNumbers[2] && <br />}
                            {`${accountIdentificationNumbers[2] ?? ''}${accountIdentificationNumbers[3] ? "," : ""}${accountIdentificationNumbers[3] ?? ''}`}
                          </Box>
                        </Tooltip>
                      </Td>
                      <Td {...rowProps} isTruncated maxWidth="60px">
                        <Tooltip label={RPT_PGMCODE_TXT1 === "Y" ? RPT_PGMCODE_TXT : null}>
                          {firstPgmCode}
                        </Tooltip>
                      </Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </TableContainer>
      }
    {submitLoading && <TdsLoadingSpinner data-testid='spinner' label="Processing" size="xxx-large" label-displayed overlay centered></TdsLoadingSpinner>}
    {searchResults && searchResults.length > 0 &&
        <div className='saved-parameters-search-results-div'>
          <button disabled={checkedRow === undefined} 
            className='tds-button--secondary tds-button--small saved-parameters-search-btn' 
            // isLoading={submitLoading}
            data-testid='submit-button'
            style={{ height: '2rem' }} 
            type='button'
            onClick={(e) => {checkedRow!==undefined ? submit(searchResults[checkedRow]) : submit("")}}>
            SUBMIT
          </button>
          {/* <button onClick={(e) => { modify(searchResults.map((record: any, index) => {
//          const { RPT_PGMCODE_TXT, RPT_ACCT_NM, RPT_NM, RPT_TYPE_NM, RPT_SELECTION_TXT, RPT_PGMCODE_TXT1 } = record;
            if (checkedRow === index) {
              return searchResults.[index];
            }}))
          }} 
          disabled={checkedRow === undefined} 
          className='tds-button--secondary tds-button--small homeform-act-search-btn' 
          style={{ height: '2rem' }} 
          type='button'> */}
          <button 
          onClick={(e) => { checkedRow!==undefined? modify(searchResults[checkedRow]) : modify("") }}
            disabled={checkedRow === undefined}
            className='tds-button--secondary tds-button--small homeform-act-search-btn'
            style={{ height: '2rem' }}
            type='button'>

            MODIFY
          </button>
          <button disabled={checkedRow === undefined} className='tds-button--secondary tds-button--small homeform-act-search-btn' style={{ height: '2rem' }} type='button'>
            COPY
          </button>
          <button disabled={checkedRow === undefined} onClick={() => setShowRenameForm(true)} className='tds-button--secondary tds-button--small homeform-act-search-btn' style={{ height: '2rem' }} type='button'>
            RENAME
          </button>
          <button disabled={checkedRow === undefined} 
          // onClick={() => setShowDeleteAlert(true)} 
          onClick={(e) => { checkedRow!==undefined? deleteParam(searchResults[checkedRow]) : deleteParam("") }}
          className='tds-button--secondary tds-button--small homeform-act-search-btn' style={{ height: '2rem' }} type='button'>
            DELETE
          </button>
        </div>
      }

      {
        showRenameForm && (
          <div className='saved-parameter-search-results-rename-div'>
            <div style={{ width: '56.5%' }}>
              <TdsField>
                <input style={{ height: '2rem' }} type='text' aria-label='policy number' />
              </TdsField>
            </div>

            <button style={{ width: '18%', height: '2rem' }} className='tds-button--primary tds-button--small' type='button'>
              UPDATE
            </button>
            <button onClick={() => setShowRenameForm(false)} style={{ width: '18%', height: '2rem' }} className='tds-button--tertiary tds-button--small' type='button'>
              CANCEL
            </button>
          </div>
        )
      }
    </div >
  );
};
export default SavedParametersSearchResults;
