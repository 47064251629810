import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccIdentification } from './agentAccount';
import { accountIdentificationOptions } from '../../../common/constants';
type AgentAccountState = {
  accountIdentification : AccIdentification;
  accountIdentificationMethod: String;
};
const initialState: AgentAccountState = {
  accountIdentification: {
    accountIdentificationMethod: accountIdentificationOptions.PolicyNumber,
    accountIdentificationValue: '',
    accountIdentificationSelections: [],
  },
  accountIdentificationMethod: accountIdentificationOptions.PolicyNumber
};

export const agentAccountSlice = createSlice({
  name: 'agentAccount',
  initialState,
  reducers: {
    // add your non-async reducers here
    addSelectedAgentAccount: (state: AgentAccountState, action: PayloadAction<string>) => {
      let agentAllSelectedAccounts = [...state.accountIdentification.accountIdentificationSelections];
      agentAllSelectedAccounts.push(action.payload);
      state.accountIdentification = {
        ...state.accountIdentification,
        accountIdentificationSelections: agentAllSelectedAccounts,
      }
    },
    addAccountIdentification: (state: AgentAccountState, action: PayloadAction<AccIdentification>) => {
      let agentAllSelectedAccounts = [...state.accountIdentification.accountIdentificationSelections];
      agentAllSelectedAccounts.push(action.payload.accountIdentificationValue);
      state.accountIdentification = {
        ...action.payload,
        accountIdentificationValue: '',
        accountIdentificationSelections: agentAllSelectedAccounts,
      }
    },
    removeSelectedAgentAccount: (state: AgentAccountState, action: PayloadAction<string>) => {
      state.accountIdentification.accountIdentificationSelections = state.accountIdentification.accountIdentificationSelections.filter((item) => item !== action.payload);
    },
    resetAgentAllSelectedAccounts: (state: AgentAccountState) => {
      state.accountIdentification.accountIdentificationSelections = [];
      state.accountIdentification.accountIdentificationValue="";
    },
    updateAccountIdentification: (state: AgentAccountState, action: PayloadAction<AccIdentification>) => {
      state.accountIdentification = action.payload;
    },
    updateAccountIdentificationMethod: (state: AgentAccountState, action: PayloadAction<String>) => {
      state.accountIdentificationMethod = action.payload;
    },
  }
});

// Action creators
export const { addSelectedAgentAccount, addAccountIdentification, removeSelectedAgentAccount, resetAgentAllSelectedAccounts, updateAccountIdentification, updateAccountIdentificationMethod } = agentAccountSlice.actions;

export default agentAccountSlice.reducer;
