import { Stack, Button } from "@chakra-ui/react"
import { CirclePlusIcon } from "../../Icons/CirclePlusIcon"

export const AddButton = ({text, onClick, disabled, ...props}: {text: string, onClick: Function, disabled: boolean}) => {
    return <Stack  alignItems="start" direction='column'>
      <Button {...props} className='filter-grouping-add-button'  isDisabled={disabled} onClick={(e)=>{
        e.stopPropagation()
        onClick()
      } 
    } style={{ backgroundColor: 'transparent', padding: '15px 5px' }} leftIcon={<CirclePlusIcon fontSize={24}
      aria-hidden={false} />} variant='noHover'>{text}</Button>
    </Stack>
  }
  