import { apiSlice } from '../../app/api/apiSlice';
import { API, REQ_TYPE } from '../../common/constants';

export const filterLookupApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFilterLookupList: builder.query<any, any>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.FILTER_LOOKUP,
          body: arg,
        };
      },
      transformResponse: (response: any) => response,
    }),
    getOrganizationLevelData: builder.query<any, any>({
      query: (arg) => {
        return {
          body: arg,
          method: REQ_TYPE.POST,
          url: API.ORG_LEVEL_DATA,
        };
      },
      transformResponse: (response: any) => response.result,
    }),

  }),
});

export const { endpoints, useGetFilterLookupListQuery, useGetOrganizationLevelDataQuery } = filterLookupApiSlice;
