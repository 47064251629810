import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HTTP, MOCK_API_BASE_URL } from '../../common/constants';
const { CONTENT_TYPE, APP_JSON } = HTTP;

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  const response = await fetch('./config.json');
  const { appConfig: { REACT_APP_API_PRESENTATION_URL } } = await response.json();
  const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MOCK_API_TRUE ? MOCK_API_BASE_URL : REACT_APP_API_PRESENTATION_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const oktaLocalStorage = localStorage.getItem('okta-token-storage');
      if (oktaLocalStorage) {
        const oktaLocalStorageParsed = JSON.parse(oktaLocalStorage);
        if (oktaLocalStorageParsed && oktaLocalStorageParsed.accessToken) {
          const { accessToken: userAccessToken } = oktaLocalStorageParsed.accessToken;
          headers.set('Authorization', `Bearer ${userAccessToken}`);
        }
      }
      headers.set(CONTENT_TYPE, APP_JSON);
      return headers;
    },
  });
  return await baseQuery(args, api, extraOptions);
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
