import { FormControl, FormLabel, Input, InputGroup, InputLeftElement, Select, Stack, Text } from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { BLANK_FILTER, BLANK_GROUP } from '../../common/constants';
import { FilterOption, GroupOption, Operator, ReportData } from '../../common/types';
import { StackContainer } from '../Layout/StackContainer';
import { FilterSection } from '../Shared/FormSections/FilterSection';
import { SubmissionSection } from '../Shared/FormSections/SubmissionSection';
import PolicyNumber from '../Shared/AccountIdentification/PolicyNumber';
import AgentAccountSearch from '../Shared/AgentAccountSearch/AgentAccountSearch';
import useAuth from '../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { AccIdentification } from '../Shared/AgentAccountSearch/agentAccount';
import { RootState } from '../../store';
import { AppContext } from '../../context/appContext';
import useClaimActivityListData from '../../hooks/useClaimActivityListData';
import { getLargeLossType, isValidDate, largeLimitOptions, validDateFormat, validYear, yearInRange } from '../../features/utils/utils';
import { useGetPrefillDataQuery } from '../../features/utils/prefillApiSlice';
import { addAccountErrorMessage, addSuccessMessage, setSubmitClicked, showClaimActivityErrorMessage } from '../../features/claimActivity/claimActivityErrorMsgSlice';
import { useUpdateParametersMutation } from '../../features/reports/saveReportApiSlice';
import { addDateRequiredErrorMessage } from '../../features/errors/errorReducer';
import { resetAgentAllSelectedAccounts, updateAccountIdentification } from '../Shared/AgentAccountSearch/agentAccountSlice';
import DateSelectionCriteria from '../Shared/FormSections/DateSelectionCriteria';
import { TdsField } from '@trv-tds/react';
import GroupBySection from '../Shared/FormSections/GroupBySection';
import ReportFilteringResults from '../Shared/ReportFiltering/ReportFilteringResults';
import WCReportFilteringResults from '../Shared/ReportFiltering/WCReportFilteringResults';
import YearSelectionCriteria from '../Shared/FormSections/YearSelectionCriteria';
import { useGetDropdownListQuery } from '../../features/utils/utisApiSlice';

const PolicySummary = () => {

  const [largeLossValue, setLargeLossValue] = useState("");
  const { profileData } = useAuth();
  const dispatch = useDispatch();
  const [fromYear, setFromYear] = useState<string>();
  const [toYear, setToYear] = useState<string>();
  const [dateHasErrors, setDateHasErrors] = useState<boolean>(false);
  const [filters, setStateFilters] = useState<FilterOption[]>([BLANK_FILTER]);
  const [orgLevelData, setOrgLevelData] = useState<any>();
  const [acctOrgLevelData, setAcctOrgLevelData] = useState<any>();
  const [groups, setGroups] = useState<GroupOption[]>([BLANK_GROUP]);
  const [showReportFilteringResultsModal, setShowReportFilteringResultsModal] =
    useState<boolean>(false);
  const userAccountIdentification: AccIdentification = useSelector(
    (state: RootState) => state.agentAccount.accountIdentification
  );
  const { data: dropDownData } = useGetDropdownListQuery();
  const { claimActivityErrorMessage: claimActivityListError, showClaimActivityError } = useSelector((state: RootState) => state.claimActivity);
  const { accountIdentificationSelections } = userAccountIdentification;
  const [activeFilter, setActiveFilter] = useState<FilterOption | undefined>();
  const chosenFilter: FilterOption = useSelector(
    (state: RootState) => state.filterLookup.activeFilter
  );
  const showModal = () => setShowReportFilteringResultsModal(true);
  const [dropDownElement, setDropDownElement] = useState<string>("Incurred");
  const [resetDates, setResetDates] = useState<boolean | undefined>(false);
  const [reportName, setReportName] = useState<string>("Policy Summary");
  const [reportHeading, setReportHeading] = useState<string>("");
  const [reportTypeSelected, setReportTypeSelected] = useState<string>("pdf");
  const [saveResponse, setSaveResponse] = useState<number>(0);
  const [missingReportName, setMissingReportName] = useState<boolean>(false);
  const [previousSelectionsCount, setPreviousSelectionsCount] =
    useState<number>(0);
  const [clearMsg, setClearMsg] = useState<boolean>(false);
  const [clearedCriteriaMsg, setClearedCriteriaMsg] = useState<boolean>(false);
  const filteredGroups = [...groups].filter(
    (_group) => _group.field !== "" || _group.label !== ""
  );
  const filteredFilters = [...filters].filter(
    (_filter) => _filter.field !== "" || _filter.dbValue !== ""
  );
  const defaultAppConfig = { REACT_APP_ST_CLAIM_REPORT_URL: undefined }
  const { appConfig: { REACT_APP_ST_CLAIM_REPORT_URL } = defaultAppConfig } = useContext(AppContext)

  //Note this is needed because we validate account information with the claim activity service when user enters it in account ID section
  useClaimActivityListData();

  const setFilters = (filters: FilterOption[]) => {
    setStateFilters(filters);
  };
  const ref = useRef<HTMLInputElement>(null);
  const topRef = useRef<HTMLInputElement>(null);

  const formattedFilters = [...filters].map((filter) => {
    const newFilter = {
      ...filter,
      operatorError: false,
      dateFormatError: false,
      dateInvalidError: false,
      yearInvalidError: false,
      yearFormatError: false,
      invalidDateOperatorError: false,
      multipleValuesGTError: false,
      multipleValuesLTError: false,
    };
    if ((newFilter.field === "Policy Year") &&
      (newFilter.operator === "Contains" ||
        newFilter.operator === "Omits" ||
        newFilter.operator === "Starts With")
    ) {
      newFilter.invalidDateOperatorError = true;
    }
    if (
      newFilter.field !== "" &&
      newFilter.value.length > 0 &&
      newFilter.isDirty
    ) {
      if (newFilter.operator === "") {
        newFilter.operatorError = true;
      }
    }
    if (newFilter.isDirty && newFilter.field === "Policy Year") {
      let years=newFilter.value.split(",");
      years.forEach((year)=>{
      if (!validYear(year)) {
        newFilter.yearFormatError = true;
      }
      if (!yearInRange(year)) {
        newFilter.yearInvalidError = true;
      }
      });
    }
   if (
      filter.operator === "Greater Than" &&
      filter.value.indexOf(",") > 0
    ) {
      newFilter.multipleValuesGTError = true;
    }
    if (
      filter.operator === "Less Than" &&
      filter.value.indexOf(",") > 0
    ) {
      newFilter.multipleValuesLTError = true;
    }
    return newFilter;
  });
  const formattedFilteredFilters = [...formattedFilters].filter(
    (_filter) => _filter.field !== "" || _filter.dbValue !== ""
  );
  const filtersHaveErrors =
    [...formattedFilters].filter(
      (filter) =>
        filter.dateFormatError ||
        filter.dateInvalidError ||
        filter.yearFormatError ||
        filter.yearInvalidError ||
        filter.invalidDateOperatorError ||
        filter.multipleValuesLTError ||
        filter.multipleValuesGTError ||
        (filter.operator === "" && filter.field !== "")
    ).length > 0;
  useEffect(() => {
    if (
      previousSelectionsCount > accountIdentificationSelections.length &&
      userAccountIdentification.accountIdentificationMethod ===
      "Account Number" &&
      (filteredFilters.length > 0 ||
        filters[0]?.field !== "" ||
        filters[0]?.operator !== "" ||
        filters[0]?.value !== "" ||
        filteredGroups.length > 0 ||
        groups[0]?.field !== "" ||
        groups[0]?.label !== "" ||
        largeLossValue !== "" ||
        dropDownElement !== "Incurred")
    ) {
      removeBlankFields();
      setFilters([BLANK_FILTER]);
      setGroups([BLANK_GROUP]);
      resetLargeLossFilter();
      setClearedCriteriaMsg(true);
    } else {
      setClearedCriteriaMsg(false);
    }
    console.log(clearedCriteriaMsg);
    setPreviousSelectionsCount(accountIdentificationSelections.length);
  }, [accountIdentificationSelections]);

  useEffect(() => {
    scrollToElement();
  }, [filtersHaveErrors]);
  const lossCriteria = {
    UserInformation: profileData[0],
  };

  let shouldSkip = (profileData === undefined || profileData === null || profileData?.length === 0) ||
    (profileData?.length > 0 && (
      profileData[0]?.Role.IsInternalUser ||
      (profileData[0]?.Role.IsAccountId === false && profileData[0]?.Role.IsAccountLocationId === false)
    ));

  const {
    data: prefill_data,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetPrefillDataQuery(lossCriteria, {
    skip: shouldSkip
  });

  useEffect(() => {
    if (
      prefill_data &&
      prefill_data.result &&
      prefill_data.result.Selections &&
      !isLoading
    ) {
      setAcctOrgLevelData(prefill_data.result);
    }
  }, [prefill_data, isLoading, profileData]);

  useEffect(() => {
    dispatch(showClaimActivityErrorMessage(false))
    setResetDates(true);
    dispatch(setSubmitClicked(false));
  }, [])

  const reportData: ReportData = {
    LossesCriteria: {
      LargeLossValue:
        getLargeLossType(dropDownElement) === 4 ? "" : largeLossValue,
      LargeLossType: getLargeLossType(dropDownElement),
      ClaimLimitDollarType: 0,
      ClaimLimitOperator: null,
      BeginDollarAmount: null,
      EndDollarAmount: null,
      Orgs: getOrgLevelData(),
      EffYearFrom: fromYear?Number.parseInt(fromYear):0,
      EffYearTo: toYear?Number.parseInt(toYear):0,
      RankFieldName: "",
      HistoryDate: "0001-01-01T00:00:00",
      Limits:
        formattedFilteredFilters && formattedFilteredFilters.length > 0
          ? formattedFilteredFilters.map((_filter) => {
            return {
              FieldName: _filter.dbValue,
              DisplayName: _filter.field,
              Operator:
                getOperator(_filter.operator) === "-1"
                  ? "EQ"
                  : getOperator(_filter.operator),
              LimitValue: _filter.value,
              LimitType: 0,
            };
          })
          : [],
      ReportCategory: 0,
      AccountName: "",
      Selections: getSelections(),
      FromDate: "0001-01-01T00:00:00",
      ToDate: "0001-01-01T00:00:00",
      Heading: reportHeading,
      OutputFormat: reportTypeSelected === "pdf" ? 0 : 1,
      ReportType: 16,
      AccountIdType: getAccountIdType(),
      SortGroups:
        filteredGroups && filteredGroups.length > 0
          ? filteredGroups
            .map((_group) => {
              const orgs = _group.field.split("ORG");
              const isOrg = orgs.length > 1 && orgs[1][0] !== "_";
              const lookupOrgs = [
                "Accident Result",
                "Adjusting Office",
                "Body Part",
                "Line of Insurance",
              ];
              const _lookupOrgs = [...dropDownData?.lookup?.map((org) => org._value) ?? [], ...lookupOrgs]
              return {
                FieldName: _group.field,
                Label: _group.label,
                Subtotal: _group.hasSubtotal,
                PageBreak: _group.hasPageBreak,
                LookupFieldText:
                  isOrg ||
                  (!orgLevelData || !orgLevelData.Headers
                    ? false
                    : orgLevelData.Headers.includes(_group.originalLabel)) ||
                    _lookupOrgs.includes(_group.field),
              };
            })
            .reverse()
          : [],
          BaseUrl: REACT_APP_ST_CLAIM_REPORT_URL,
      ShowDrilldown: true,
      ReportName: reportName,
      ParamId: 0,
      InsuredRetentionType: 0,
    },
    UserInformation:
      profileData && profileData.length ? profileData[0] : undefined,
  };

  
  const saveReportData = {
    ...reportData,
    ParameterCriteria: {
      ParamId: 0,
      EventType: 0,
      OverrideOrgCheck: false,
    },
  };
  const [saveData] = useUpdateParametersMutation();

  const scrollToElement = () => {
    if (filtersHaveErrors) ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToTop = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  function getHistoryDate(hDate: String) {
    if (hDate && hDate.length === 7) {
      let month = Number.parseInt(hDate.substring(0, 2));
      let year = Number.parseInt(hDate.substring(3, 7));
      let endD = new Date(year, month, 0);
      console.log("date is" + endD.toString());
      return "" + endD.toISOString().substring(0, 10);
    }
  }

  function getOperator(oper: String): Operator {
    if (oper === "Equal To") return "EQ";
    else if (oper === "Not Equal To") return "NE";
    else if (oper === "Less Than") return "LT";
    else if (oper === "Greater Than") return "GT";
    else if (oper === "Contains") return "Contains";
    else if (oper === "Omits") return "Omits";
    else if (oper === "Starts With") return "StartsWith";
    else return "-1";
  }

  function getOrgLevelData() {
    if (prefill_data && prefill_data.result && prefill_data.result.Orgs) {
      return prefill_data.result.Orgs;
    } else {
      return orgLevelData;
    }
  }
  function getSelections() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (
        profileData[0].Role.IsAccountId === true ||
        profileData[0].Role.IsAccountLocationId === true
      ) {
        if (
          prefill_data &&
          prefill_data.result &&
          prefill_data.result.Selections
        ) {
          return prefill_data.result.Selections;
        }
      } else {
        return accountIdentificationSelections;
      }
    }
  }

  function getAccountIdType() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (
        profileData[0].Role.IsAccountId === true ||
        profileData[0].Role.IsAccountLocationId === true
      ) {
        if (prefill_data && prefill_data.result)
          return prefill_data ? prefill_data.result.AccountIdType : "";
      } else {
        if (
          userAccountIdentification.accountIdentificationMethod ===
          "Policy Number"
        )
          return 2;
        else if (
          userAccountIdentification.accountIdentificationMethod ===
          "Account Number"
        )
          return 1;
        else if (
          userAccountIdentification.accountIdentificationMethod === "SAI Number"
        )
          return 4;
        else if (
          userAccountIdentification.accountIdentificationMethod ===
          "Producer Code"
        )
          return 3;
        else return -1;
      }
    }
  }

  const removeBlankFields = () => {
    if (filteredGroups.length === 0) {
      setGroups([BLANK_GROUP]);
    } else {
      setGroups(filteredGroups);
    }
    if (filteredFilters.length === 0) {
      setFilters([BLANK_FILTER]);
    } else {
      setFilters(filteredFilters);
    }
  };

  const saveForm = async () => {
    removeBlankFields();
    dispatch(showClaimActivityErrorMessage(true))
    if (claimActivityListError !== "") {
      scrollToTop();
      return true;
    } else {
      if ((profileData && profileData.length > 0 && profileData[0].Role && 
        (profileData[0].Role.IsAccountId !== true && profileData[0].Role.IsAccountLocationId !== true)) && accountIdentificationSelections.length === 0) {
      dispatch(
          addAccountErrorMessage(
            "Please enter an account identifier to save the report criteria."
          )
        );
      } else if (reportName.trim() === "") {
        setMissingReportName(true);
      } else {
        setMissingReportName(false);
        const { data }: any = await saveData(saveReportData);
        setSaveResponse(Number(data.result));
        dispatch(addSuccessMessage(""));
        if (data && data.result === "1") {
          dispatch(addSuccessMessage("success"));
        }
      }
    }
  };

  const submitForm = async () => {
    removeBlankFields();
    dispatch(showClaimActivityErrorMessage(true))
    if (claimActivityListError !== "") {
      scrollToTop();
      return true;
    } else {
      if (accountIdentificationSelections.length === 0) {
        dispatch(
          addAccountErrorMessage(
            "Please enter an account identifier to run the report."
          )
        );
      }
      //@ts-ignore
      const newFilters = [...formattedFilteredFilters]?.map((_filter) => {
        if (
          (_filter.field === "Accident Year" ||
            _filter.field === "Policy Year") &&
          !validYear(_filter.value)
        ) {
          _filter.yearFormatError = true;
          _filter.isDirty = true;
        } else if (_filter.yearFormatError) {
          _filter.yearFormatError = false;
        }
        if (
          (_filter.field === "Date of Hire (WC)" ||
            _filter.field === "Date of Loss" ||
            _filter.field === "Date Notice Recv'd" ||
            _filter.field === "Policy Eff Date") &&
          !isValidDate(_filter.value)
        ) {
          _filter.dateInvalidError = true;
          _filter.isDirty = true;
        } else {
          _filter.dateInvalidError = false;
        }

        if (
          (_filter.field === "Date of Hire (WC)" ||
            _filter.field === "Date of Loss" ||
            _filter.field === "Date Notice Recv'd" ||
            _filter.field === "Policy Eff Date") &&
          !validDateFormat(_filter.value)
        ) {
          _filter.dateFormatError = true;
          _filter.isDirty = true;
        } else {
          _filter.dateFormatError = false;
        }
        return _filter;
      });
      const _newFilters = newFilters.length===0 ? [BLANK_FILTER] : newFilters
      setFilters(_newFilters);
      const hasErrors =
        newFilters.filter(
          (filter) =>
            filter.dateFormatError ||
            filter.dateInvalidError ||
            filter.yearFormatError ||
            filter.yearInvalidError ||
            filter.invalidDateOperatorError ||
            (filter.operator === "" && filter.field !== "")
        ).length > 0 || (accountIdentificationSelections.length === 0 && profileData[0]?.Role.IsAccountId === false && profileData[0]?.Role.IsAccountLocationId === false);
      return hasErrors;
    }
  };

  const resetForm = () => {
    removeBlankFields();
    dispatch(showClaimActivityErrorMessage(false))
    resetLargeLossFilter();
    setFilters([BLANK_FILTER]);
    setGroups([BLANK_GROUP]);
    //reset dates
    setResetDates(false);
    // Used to get around the useEffect pattern in DateSelectionCriteria
    setTimeout(() => {
      setResetDates(true);
    }, 1);
    setDateHasErrors(false);
    //remove id's and reset to policy number
    dispatch(
      updateAccountIdentification({
        ...userAccountIdentification,
        ["accountIdentificationMethod"]: "Policy Number",
      })
    );
    dispatch(resetAgentAllSelectedAccounts());
    setMissingReportName(false);
    setSaveResponse(0);
    clearWarningMessages();
    dispatch(addSuccessMessage(""));
  };

  function resetLargeLossFilter() {
    setLargeLossValue("");
    setDropDownElement("Incurred");
  }

  function clearWarningMessages() {
    setClearMsg(true);
  }
  
  
  return (<div ref={topRef}>
    <PolicyNumber
      clearMsg={clearMsg}
      setClearMsg={setClearMsg}
      clearedCriteriaMsg={clearedCriteriaMsg}
      setClearedCriteriaMsg={setClearedCriteriaMsg}
      hasCustomFilters={
        userAccountIdentification.accountIdentificationMethod ===
        "Account Number" &&
        (filteredFilters.length > 0 ||
          filters[0]?.field !== "" ||
          filters[0]?.operator !== "" ||
          filters[0]?.value !== "" ||
          filteredGroups.length > 0 ||
          groups[0]?.field !== "" ||
          groups[0]?.label !== "" ||
          largeLossValue !== "" ||
          dropDownElement !== "Incurred")
      }
      presetAccountIdentificationOptions={["Policy Number", "Account Number", "SAI Number"]}

    />
    <AgentAccountSearch />
    <YearSelectionCriteria
       reset={resetDates}
      setReset={setResetDates}
      fromYear={fromYear}
      setFromYear={setFromYear}
      toYear={toYear}
      setToYear={setToYear}
      setDateHasErrors={setDateHasErrors}
      dateHasErrors={dateHasErrors}
      disabledProducer={true}
    />
    <FormControl ref={ref}>
      <StackContainer spacing={6} title="Optional Report Criteria">
        <FilterSection
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          onShowModal={showModal}
          filters={formattedFilters}
          setFilters={setFilters}
          setOrgLevelData={setOrgLevelData}
          acctOrgLevelData={acctOrgLevelData}
          setAcctOrgLevelData={setAcctOrgLevelData}
          pageName='PolicySummary'
        />
      </StackContainer>
    </FormControl>
    <SubmissionSection
      onResetForm={resetForm}
      onSaveForm={saveForm}
      onSubmitForm={submitForm}
      reportName={reportName}
      setReportName={setReportName}
      reportHeading={reportHeading}
      setReportHeading={setReportHeading}
      reportTypeSelected={reportTypeSelected}
      setReportTypeSelected={setReportTypeSelected}
      missingReportNameError={missingReportName}
      setMissingReportName={setMissingReportName}
      reportData={reportData}
      saveResponse={saveResponse}
      setSaveResponse={setSaveResponse}
      disableButtons={dateHasErrors || filtersHaveErrors || (claimActivityListError !== "" && showClaimActivityError)}
      formHasErrors={filtersHaveErrors}
    />
    {showReportFilteringResultsModal &&
      chosenFilter.field !== "Accident Cause" && (
        <ReportFilteringResults
          finalFocusRef={ref}
          setShowReportFilteringResultsModal={
            setShowReportFilteringResultsModal
          }
          isOpen={showReportFilteringResultsModal}
          setIsOpen={setShowReportFilteringResultsModal}
          setFilters={setFilters}
          filters={filters}
          filter={activeFilter}
        />
      )}
    {showReportFilteringResultsModal &&
      chosenFilter.field === "Accident Cause" && (
        <WCReportFilteringResults
          finalFocusRef={ref}
          setShowReportFilteringResultsModal={
            setShowReportFilteringResultsModal
          }
          isOpen={showReportFilteringResultsModal}
          setIsOpen={setShowReportFilteringResultsModal}
          setFilters={setFilters}
          filters={filters}
          filter={activeFilter}
        />
      )}
  </div>
  );
};
export default PolicySummary;
