import { useAppConfig } from '../../context/appContext';

function OktaConfig() {
	const AppContext = useAppConfig();
	return {
		issuer: AppContext.oktaConfig?.issuer,
		clientId: AppContext.oktaConfig?.clientId,
		redirectUri: `${window.location.origin}/index.html`,
		pkce: AppContext.oktaConfig?.pkce,
	};
}

export default OktaConfig;