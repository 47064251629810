import { apiSlice } from '../../app/api/apiSlice';
import { API, REQ_TYPE } from '../../common/constants';

export const prefillApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPrefillData: builder.query<any, any>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.PREFILL_DATA,
          body: arg,
        };
      },
      transformResponse: (response: any) => response,
    }),
  }),
});

export const { endpoints, useGetPrefillDataQuery } = prefillApiSlice;
