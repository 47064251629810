import { Button, Tooltip } from "@chakra-ui/react"
import { CircleMinusIcon } from "../../Icons/CircleMinusIcon"

export const RemoveButton = ({ text, onClick, hidden, ...props }: { text: string, onClick: Function, hidden: boolean }) => {
  return <Tooltip>
    <Button {...props} style={{ padding: '15px 10px' }} color="orange.500" _hover={{ color: 'red.500' }} onClick={(e) => {
      e.stopPropagation()
      onClick()
    }} leftIcon={<CircleMinusIcon fontSize={24}
      aria-hidden={true} hidden={hidden} />} variant='customLink'></Button>
  </Tooltip>
}
