import React from 'react';
import { TdsField } from '@trv-tds/react';

const SAI = () => {
  return (
    <div>
      <div className='sai-inner-div-policy-search'>
        <div className='sai-selection-div-outer'>
          <div className='sai-selection-div-inner'>
            <div className='sai-form-policy-input'>
              <TdsField label='Add up to four SAI #s'>
                <input style={{ height: '2rem' }} type='text' aria-label='policy number' />
              </TdsField>
            </div>

            <div className='sai-number-add-button-container'>
              <button className='tds-button--primary tds-button--small' type='button' style={{ width: '100%', height: '2rem' }}>
                ADD
              </button>
            </div>
          </div>
        </div>

        <div className='sai-number-display-div'>
          <div className='tds-field policy-number-textarea-div' style={{ marginBottom: 0 }}>
            <textarea style={{ marginTop: 0 }} aria-label='policy numbers' className='tds-field__input' rows={4}></textarea>
          </div>

          <p className='body-xsmall wireframe-p-font-regular' style={{ marginBottom: '0.5rem' }}>
            (Added 0 of 4)
          </p>
        </div>

        <div className='sai-number-display-buttons-div'>
          <div className='sai-number-button-container'>
            <button className='tds-button--secondary tds-button--small sai-number-btns' style={{ marginBottom: '1rem' }} type='button'>
              REMOVE SELECTED
            </button>
            <button className='tds-button--secondary tds-button--small sai-number-btns' type='button'>
              CLEAR ALL
            </button>
          </div>
        </div>
      </div>

      <div className='sai-number-save-alert-div'>
        <button className='tds-button--primary tds-button--small sai-number-save-alert-btn' type='button'>
          SAVE ALERT
        </button>
      </div>
    </div>
  );
};

export default SAI;
