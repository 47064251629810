import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys)

const baseStyle = definePartsStyle({
  control: {
    width: '1.2rem',
    height: '1.2rem',
    _checked: {
      background: "gray.500",
      borderColor: "gray.500",
    }
  },
})

export const radioTheme = defineMultiStyleConfig({ baseStyle })