import { apiSlice } from '../../app/api/apiSlice';
import { API, REQ_TYPE } from '../../common/constants';
import { DowndownData } from './utils';

export const utilsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDropdownList: builder.query<DowndownData, void>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.DROP_DOWN_LIST,
        };
      },
      transformResponse: (response: any) => response.DataSet,
    }),


  }),
});

export const { useGetDropdownListQuery } = utilsApiSlice;
