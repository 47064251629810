import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { TdsField } from "@trv-tds/react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StackContainer } from "../../Layout/StackContainer";
import { addSuccessMessage } from "../../../features/claimActivity/claimActivityErrorMsgSlice";
import { AccIdentification } from "../AgentAccountSearch/agentAccount";
import { RootState } from "../../../store";
import { addDateRequiredErrorMessage, addDateRequiredToErrorMessage } from "../../../features/errors/errorReducer";

type DateSelectionProps = {
  reset?: boolean;
  disabledProducer?: boolean;
  setReset?: Dispatch<SetStateAction<boolean | undefined>>;
  fromYear?: any;
  setFromYear: Function;
  toYear?: any;
  setToYear: Function;
  blankDates?: boolean;
  dateHasErrors: boolean;
  setDateHasErrors: Function;
  pageName?: string;
  dateRangeRequiredMsg?: boolean;
  setDateRangeRequiredMsg?: Function;
  dateRangeToRequiredMsg?: boolean;
  setDateRangeToRequiredMsg?: Function;
};

export const YearSelectionCriteria = ({
  reset,
  setReset,
  fromYear,
  setFromYear,
  toYear,
  setToYear,
  setDateHasErrors,
  pageName,
}: DateSelectionProps) => {

  const [displayToBeforeFromYearMsg, setDisplayToBeforeFromYearMsg] = useState<boolean>(false);
  const [displayFromAfterToYearMsg, setDisplayFromAfterToYearMsg] = useState<boolean>(false);
  const [fromHasErrors, setFromHasErrors] = useState<boolean>(false);
  const [toHasErrors, setToHasErrors] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [dateRangeRequiredMsg, setDateRangeRequiredMsg] = useState<boolean>(false);
  const [dateRangeToRequiredMsg, setDateRangeToRequiredMsg] = useState<boolean>(false);

  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const dateRequiredErrorMessage: boolean = useSelector((state: RootState) => state.errors.hasDateRequiredErrorMessage);
  const dateRequiredToErrorMessage: boolean = useSelector((state: RootState) => state.errors.hasDateRequiredToErrorMessage);


    useEffect(() =>{
      if(!isDirty)
        resetDates();
    });

  useEffect(() => {
    if (reset) {
      resetDates();
      if (setReset) {
        setReset(false);
        setFromHasErrors(false);
        setToHasErrors(false);
        setDisplayToBeforeFromYearMsg(false);
        setDisplayFromAfterToYearMsg(false);
        dispatch(addDateRequiredErrorMessage(false));
        dispatch(addDateRequiredToErrorMessage(false));
        if(setDateRangeRequiredMsg)
          setDateRangeRequiredMsg(false);
        if(setDateRangeToRequiredMsg)
          setDateRangeToRequiredMsg(false);
        setIsDirty(false)
      }
      setFromYear("");
    }
  }, [reset, setReset]);

  console.log('range required:'+dateRequiredErrorMessage);
  console.log("range:"+dateRangeRequiredMsg);
  useEffect(() => {
    if(dateRequiredErrorMessage || dateRequiredToErrorMessage){
      setDateRangeRequiredMsg(dateRequiredErrorMessage);
      setDateRangeToRequiredMsg(dateRequiredToErrorMessage);
      scrollToElement();
    }else{
      setDateRangeRequiredMsg(false);
      setDateRangeToRequiredMsg(false);
    }
  }, [dateRequiredErrorMessage, dateRequiredToErrorMessage])

   useEffect(() => {
    scrollToElement();
  }, [fromHasErrors, toHasErrors, displayToBeforeFromYearMsg, displayFromAfterToYearMsg, dateRequiredErrorMessage]);

  
  const scrollToElement = () => {
    if (fromHasErrors || toHasErrors || displayToBeforeFromYearMsg ||displayFromAfterToYearMsg || dateRequiredErrorMessage)
      ref?.current?.scrollIntoView({ behavior: "smooth" });
  };


  function resetDates() {
    if(pageName==='NationalPrograms'){
      setToYear('');
      setFromYear('');
    }else{
      let curr = new Date();
      curr.setDate(curr.getDate());
      let toDateStr = curr.toISOString().substring(0, 4);
      setToYear(toDateStr);
      curr.setFullYear(curr.getFullYear() - 3)
      let dateStr = curr.toISOString().substring(0, 4);
      setFromYear(dateStr);
    }
      setIsDirty(false);
  }

  const fromYearHandler = (event: any) => {
    setIsDirty(true);
    dispatch(addSuccessMessage(""));
    let prevValue = fromYear;
    const regex = new RegExp("^[0-9]+$");
    if (event?.target?.value?.length === 5) return;
    if (regex.test(event?.target?.value)) {
        setFromYear(event.target.value.substring(0, 4));
      } else {
        setFromYear(prevValue);
    } if (event?.target?.value?.length === 0) {
      setDateRangeRequiredMsg(true);
      setFromYear(event.target.value);
    }
    setDateHasErrors(toHasErrors || fromHasErrors || displayFromAfterToYearMsg || displayToBeforeFromYearMsg );
  };

  const toYearHandler = (event: any) => {
    setIsDirty(true);
    dispatch(addSuccessMessage(""));
    let prevValue = fromYear;
    const regex = new RegExp("^[0-9]+$");
    if (event.target.value.length === 5) return;
    if (regex.test(event.target.value)) {
        setToYear(event.target.value.substring(0, 4));
       } else {
        setToYear(prevValue);
    } if (event.target.value.length === 0) {
      setDateRangeToRequiredMsg(true);
      setToYear(event.target.value);
    }
    setDateHasErrors( toHasErrors || fromHasErrors || displayFromAfterToYearMsg || displayToBeforeFromYearMsg );
  };

  function checkToBeforeFrom(fmDt: string,toDt: string){
    if(fmDt?.length===4 && toDt?.length===4 && fmDt>toDt){
      setDisplayToBeforeFromYearMsg(true);
      setToHasErrors(true);
    }else{
      setDisplayToBeforeFromYearMsg(false);
      setDisplayFromAfterToYearMsg(false);
      setToHasErrors(false);
    }
  }

  function checkFromAfterTo(fmDt: string,toDt: string){
    if(fmDt?.length===4 && toDt?.length===4 && fmDt>toDt){
      setDisplayFromAfterToYearMsg(true);
      setFromHasErrors(true);
      return true;
    }else{
      setDisplayFromAfterToYearMsg(false);
      setDisplayToBeforeFromYearMsg(false);
      setFromHasErrors(false);
      return false;
    }
  }

  const fromYearLengthHandler = (event: any) => {
    setIsDirty(true);
    var fmYear=parseInt(fromYear,10);
    var toYr=parseInt(toYear,10);
    checkFromAfterTo(fromYear, toYear);
    if (( toYr < 1900 || toYr > 2100 ) && isDirty) {
      setToHasErrors(true);
    }else{
      setToHasErrors(false);
    }
    setDateRangeRequiredMsg(event.target.value.length < 4);
    //setDateRangeToRequiredMsg(toYear.length!==4);
    setFromHasErrors ( fmYear < 1900 || fmYear > 2100);
    setDateHasErrors( toHasErrors || fmYear < 1900 || fmYear > 2100 || event.target.value.length < 4 || (fromYear?.length===4 && toYear?.length===4 && fromYear>toYear));
  };

  const toYearLengthHandler = (event: any) => {
    setIsDirty(true);
    var fmYear=parseInt(fromYear,10);
    var toYr=parseInt(toYear, 10);
    checkToBeforeFrom(fromYear, toYear);
    if ((fromYear.length!==4|| fmYear < 1900 || fmYear > 2100 ) && isDirty) {
      setFromHasErrors(true);
    }else{
      setFromHasErrors(false);
    }
    setDateRangeToRequiredMsg(event.target.value.length < 4);
    //setDateRangeRequiredMsg(fromYear.length!=4);
    setToHasErrors(toYr < 1900 || toYr > 2100);
    setDateHasErrors( event.target.value.length < 4 || fromHasErrors || toYr < 1900 || toYr > 2100 || (fromYear?.length===4 && toYear?.length===4 && fromYear>toYear));
  };


  return (
    <StackContainer
      onClick={() => {
        dispatch(addSuccessMessage(""));
      }}
      stackProps={{ width: "100%" }}
      spacing={8}
      direction="row"
      title="Date Selection Criteria"
    >

      <FormControl width={ "40%"} ref={ref}>
        {pageName && pageName==='NationalPrograms'?(
        <FormLabel
          color={fromHasErrors || toHasErrors || displayToBeforeFromYearMsg || displayFromAfterToYearMsg || dateRangeRequiredMsg || dateRangeToRequiredMsg ? "#874600" : "black"}
          textStyle="label"
        >
          Policy Effective Year (YYYY)
        </FormLabel>) :
        (<FormLabel
          color={fromHasErrors || toHasErrors || displayToBeforeFromYearMsg || displayFromAfterToYearMsg || dateRangeRequiredMsg || dateRangeToRequiredMsg ? "#874600" : "black"}
          textStyle="label"
        >
          Policy Year Range (YYYY)
        </FormLabel>
        )}      
        <Stack mt={3} direction="row">
          <Box width={110}>
            <TdsField
              aria-label="from_year_field"
              alert={fromHasErrors || displayFromAfterToYearMsg
                  ? "error"
                  : "false"
              }
            >
              <input
                type="text"
                style={{ height: "40px", width: "110px" }}
                aria-label="dl_history_date_selection"
                data-testid="from_year"
                color={fromHasErrors || displayFromAfterToYearMsg
                    ? "#EF7A00"
                    : "black"
                }
                size={6}
                onChange={fromYearHandler}
                onBlur={fromYearLengthHandler}
                value={fromYear}
                disabled={false}
              ></input>

              <div className="tds-field--error" style={{ marginBottom: 0 }}>
                <div
                  id="date-selection-invalid-from-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!(fromHasErrors)}
                >
                  <p style={{ fontSize: "14px" }}>Invalid date</p>
                </div>
                <div
                  id="date-selection-from-after-to-error"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  data-testid="from-after-to-year"
                  hidden={
                    !(displayFromAfterToYearMsg)
                  }
                >
                  <p style={{ fontSize: "14px" }}>To Year must be equal or after From Year</p>
                </div>
                <div
                  id="date-selection-rang-required-error"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  data-testid="required-range"
                  hidden={
                    !(dateRangeRequiredMsg)
                  }
                >
                  <p style={{ fontSize: "14px" }}>Date range required</p>
                </div>

              </div>
            </TdsField>
          </Box>
          <Text as="h4" textStyle="h4" pt={2}> 
          TO
          </Text>
            <Box width={150}>
              <TdsField
                aria-label="to-date-field"
                alert={toHasErrors || displayToBeforeFromYearMsg
                    ? "error"
                    : "false"
                }
              >
                <input
                  type="text"
                  style={{ height: "40px", width: "110px" }}
                  aria-label="to-date-selection"
                  data-testid="to_date"
                  color={toHasErrors || displayToBeforeFromYearMsg
                      ? "#EF7A00"
                      : "black"
                  }
                  size={6}
                  onChange={toYearHandler}
                  onBlur={toYearLengthHandler}
                  value={toYear}
                  disabled={false}
                ></input>
             <div className="tds-field--error" style={{ marginBottom: 0 }}>
                <div
                  id="date-selection-invalid-to-date"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  hidden={!(toHasErrors)}
                >
                  <p style={{ fontSize: "14px" }}>Invalid date</p>
                </div>
                <div
                  id="date-selection-to-before-from-error"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  data-testid="to-before-from-year"
                  hidden={
                    !(displayToBeforeFromYearMsg)
                  }
                >
                  <p style={{ fontSize: "14px" }}>To Year must be equal or after From Year</p>
                </div>
                <div
                  id="date-selection-rang-required-error"
                  aria-atomic="true"
                  className="tds-field__message"
                  role="alert"
                  data-testid="required-range"
                  hidden={
                    !(dateRangeToRequiredMsg)
                  }
                >
                  <p style={{ fontSize: "14px" }}>Date range required</p>
                </div>

              </div>
              </TdsField>
            </Box>

        </Stack>
      </FormControl>
    </StackContainer>
  );
};
export default YearSelectionCriteria;
