import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface ClaimActivityErrorMessageState {
  claimActivityErrorMessage: string
  accountErrorMessage: string
  successMessage: string
  showClaimActivityError: boolean
  wasNationalProgramsClicked: string
  submitClicked: boolean
  savedParametersErrorMessage: string
}

const initialState: ClaimActivityErrorMessageState = {
  claimActivityErrorMessage: "",
  accountErrorMessage: "",
  successMessage: "",
  showClaimActivityError: false,
  wasNationalProgramsClicked: "",
  submitClicked: false,
  savedParametersErrorMessage: "" 
}


export const claimActivityErrorMsgSlice = createSlice({
  name: 'claimActivityErrorMessage',
  initialState,
  reducers: {
    showClaimActivityErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<boolean>) => {
      state.showClaimActivityError = action.payload;
    },
    addErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.claimActivityErrorMessage = action.payload;
    },
    removeErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.claimActivityErrorMessage = "";
    },
    addAccountErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.accountErrorMessage = action.payload;
    },
    removeAccountErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.accountErrorMessage = "";
    },
    addSuccessMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.successMessage = action.payload;
    },
    setWasNationalProgramsClicked: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.wasNationalProgramsClicked = action.payload;
    },
    setSubmitClicked: (state: ClaimActivityErrorMessageState, action: PayloadAction<boolean>) => {
      state.submitClicked = action.payload;
    },
    setSavedParametersErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.savedParametersErrorMessage = action.payload;
    }
  }
});

// Action creators
export const { addErrorMessage, removeErrorMessage, removeAccountErrorMessage, addAccountErrorMessage, addSuccessMessage, showClaimActivityErrorMessage, setWasNationalProgramsClicked, setSubmitClicked, setSavedParametersErrorMessage} = claimActivityErrorMsgSlice.actions;

export default claimActivityErrorMsgSlice.reducer;
