import { Box, FormControl, Input, InputGroup, InputLeftElement, Select, Text } from "@chakra-ui/react"
import { TdsCombobox, TdsField } from "@trv-tds/react"
import { rankingOptions } from "../../../features/utils/utils"
type RankSelectorProps = {
    rankingValue: string;
    setRankingValue?: Function;
  };
  
export const RankSelector = ({rankingValue, setRankingValue}: RankSelectorProps) => (
    <FormControl>
            <Box maxW={220} pl={0}>
            <TdsField label='Ranking' >
            <Text as="p" fontSize="xs" mb={4}>
                Rank results from highest to lowest by:
            </Text>
                <select  aria-label='Ranking' name='tds-field__input' value={rankingValue}  
                style={{ width: '200px', height: '2rem', verticalAlign: 'top', padding: '0 0 0 .75rem', fontSize: '1rem' }}
                onChange={(event) => setRankingValue?setRankingValue(event.target.value):''} >
                    {Object.values(rankingOptions).map((element, index) => (
                       <option key={index} value={element}>
                          {element}
                        </option>
                    ))}
                </select>
                </TdsField>
            </Box>
    </FormControl>
)
