import { useContext, useState } from 'react';
import { Box, FormControl, FormErrorMessage, FormLabel, IconButton, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { AppContext } from '../../context/appContext';

type WelcomeBannerProps = {
  userName: string | null;
};

const WelcomeBanner = ({ userName }: WelcomeBannerProps) => {
  const [claimNumberValue, setClaimNumberValue] = useState<string>('');
  const [showClaimNumberLengthError, setShowClaimNumberLengthError] = useState<boolean>(false);
  const defaultAppConfig = { REACT_APP_ST_CLAIM_PORTAL_URL: undefined, REACT_APP_ST_ETRACER_URL: undefined }
  const { appConfig: { REACT_APP_ST_CLAIM_PORTAL_URL, REACT_APP_ST_ETRACER_URL } = defaultAppConfig } = useContext(AppContext)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setShowClaimNumberLengthError(false);
    setClaimNumberValue(event.target.value);
  };

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event && event.key && event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  const handleSearchButtonClick = (event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      event.preventDefault();
    }

    if (claimNumberValue.length !== 7) {
      setShowClaimNumberLengthError(true);
    } else {
      setShowClaimNumberLengthError(false);
      claimStatusSearch();
    }
  };

  const claimStatusSearch = () => {
    let baseUrl = REACT_APP_ST_CLAIM_PORTAL_URL;
    let region = "";
    if (baseUrl && baseUrl.search("travelers.com")>0){
      region='External';
    }else{
      region='Internal';
    }
    let searchParams = `?clm=${claimNumberValue}&UserType=`+region;
    let url = baseUrl + searchParams;
    let newWindow = window.open(url, 'ClaimStatus', 'width=document.body.clientWidth,height=document.body.clientHeight,scrollbars=yes,menubar=no,resizable=yes,directories=no,status=yes,location=no');
    newWindow?.focus();
  };

  return (
    <Box display="flex" mt={1} justifyContent="space-between" width="100%" height="100px" flexWrap="nowrap" px={.1}>
        <Text alignItems="center" as="h2" textStyle="h2">
          Welcome, {userName}
        </Text>
      <FormControl
        isInvalid={showClaimNumberLengthError}
        maxW={250}
      >
        <FormLabel textStyle="label" fontWeight={400}>Search Claim Number</FormLabel>
        <InputGroup mt={-2} size='sm'>
          <Input
            pr='4.5rem'
            type='text'
            maxLength={7}
            onKeyUp={(event) => handleEnterKey(event)}
            onChange={(event) => handleChange(event)}
          />
          <InputRightElement w={12} >
            <IconButton
              onClick={handleSearchButtonClick}
              variant='outlineRight'
              aria-label='submit search'
              size='sm'
              icon={<SearchIcon />}
            />
          </InputRightElement>
        </InputGroup>
        {showClaimNumberLengthError && <FormErrorMessage>Claim Number must be 7 characters.</FormErrorMessage>}
      </FormControl>
    </Box>
  );
};

export default WelcomeBanner;
