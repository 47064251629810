import { apiSlice } from '../../app/api/apiSlice';
import { API, REQ_TYPE } from '../../common/constants';

export const agentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAgentList: builder.query<any, any>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.ACCOUNT_LIST,
          body: arg,
        };
      },
//      transformResponse: (response: any) => response.result.Nodes[1]["diffgr:diffgram"].NewDataSet.Table,
      transformResponse: (response: any) => response,
    }),

  }),
});

export const { useGetAgentListQuery } = agentApiSlice;
