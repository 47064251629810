import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInformation } from '../../common/types';
export type ProfileState = {
  roleCodes: any[];
  accountTypes: any[];
  allProfileData: UserInformation[];
};
const initialState: ProfileState = {
  roleCodes: [],
  accountTypes: [],
  allProfileData: [],
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // add your non-async reducers here
    addRoleCode: (state: ProfileState, action: PayloadAction<any>) => {
      state.roleCodes=action.payload;
    },
    addAccountType: (state: ProfileState, action: PayloadAction<any>) => {
      state.accountTypes.push(action.payload);
    },
    addAllProfileData:(state: ProfileState, action: PayloadAction<any>) => {
      state.allProfileData.push(action.payload);
    }, 
  }
});

// Action creators
export const { addRoleCode, addAccountType, addAllProfileData } = profileSlice.actions;

export default profileSlice.reducer;
