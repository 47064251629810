import { apiSlice } from '../../app/api/apiSlice';
import { API, REQ_TYPE } from '../../common/constants';


export const saveReportApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
      updateParameters: builder.mutation<any, any>({
        query: (arg) => {
          return {
            method: REQ_TYPE.POST,
            url: API.UPDATE_PARAM,
            body: arg,
          };
        },
        transformResponse: (response: any) => response,
      }),
    }),
  });
  
  export const { useUpdateParametersMutation } = saveReportApiSlice;
  


  