import { useSelector } from 'react-redux';
import { AccountTypes } from '../features/auth/auth';
import { RootState } from '../store';
import { useEffect } from 'react';

const useAuth = () => {
  const accountTypes = useSelector((state: RootState) => state.profile.accountTypes);
  const roleCodes = useSelector((state: RootState) => state.profile.roleCodes);
  const profileData = useSelector((state: RootState) => state.profile.allProfileData);
  let isAgentUser = false;
  let isAccountLocationUser = false;
  let isNationalAccountUser = false;
  let isInternalUser = false;
  let isTracerUser = false;
  let isAccountId = false;

  useEffect(() => {
    if (profileData.length > 0) {
      const { IsEcarmaUser, IsAgent, IsInternalUser } = profileData[0].Role;
      window.localStorage.setItem('profile-role', JSON.stringify({IsEcarmaUser, IsAgent, IsInternalUser}));
    }
  },[profileData]);
  //note for testing these can be negated
  if (accountTypes && accountTypes.length > 0) {
    isAgentUser = accountTypes.includes(AccountTypes.Agent);
    isAccountLocationUser = accountTypes.includes(AccountTypes.LocAccount);
    isNationalAccountUser = accountTypes.includes(AccountTypes.NationalAccount);
    isInternalUser = accountTypes.includes(AccountTypes.InternalUser);
    isTracerUser = accountTypes.includes(AccountTypes.TracerUser);
    isAccountId = accountTypes.includes(AccountTypes.AccountUser)
  }

  return { isAgentUser, isAccountLocationUser, isNationalAccountUser, isInternalUser, roleCodes, profileData, isTracerUser, isAccountId };
};

export default useAuth;
