import { Dispatch, SetStateAction } from 'react';
import SAI from '../AccountIdentification/SAI';
import { ReactComponent as ClaimActivityEmailMaintenanceCloseIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-12.svg';
import { Text } from '@chakra-ui/react';

type ClaimActivityEmailMaintenanceProps = {
  setShowClaimActivityEmailMaintenanceDialog: Dispatch<SetStateAction<boolean>>;
};
const ClaimActivityEmailMaintenance = ({ setShowClaimActivityEmailMaintenanceDialog }: ClaimActivityEmailMaintenanceProps) => {
  let renderClaimActivityEmailMaintenanceTableData: any = null;
  const renderClaimActivityEmailMaintenanceTableHeader = [
    <thead>
      <tr role='row'>
        <th key='0' scope='col' className='tds-data-table__cell--align-left' role='columnheader'>
          <span className='tds-sr-only'>Select Column</span>
        </th>
        <th key='1' scope='col' className='tds-data-table__cell--align-left' role='columnheader'>
          ACCOUNT NAME
        </th>
        <th key='2' scope='col' className='tds-data-table__cell--align-left' role='columnheader'>
          SAI #
        </th>
      </tr>
    </thead>,
  ];

  const claimActivityEmailMaintenanceRecords = [
    { Id: 1, AccountName: 'MANAGEMENT & TRAINING CORP', SAINumber: '1947L4020' },
    { Id: 2, AccountName: 'BURN, CONTACT W/WELDING OBJECTS', SAINumber: '4633N3010, 1947L4020' },
  ];

  if (claimActivityEmailMaintenanceRecords) {
    renderClaimActivityEmailMaintenanceTableData = claimActivityEmailMaintenanceRecords.map((record, index) => {
      const { Id, AccountName, SAINumber } = record;

      return (
        <tr key={Id}>
          <td className='tds-data-table__cell--align-center tds-data-table__stacked-cell--align-center'>
            <div className='tds-check tds-check--no-label' data-row-selector='true'>
              <input type='checkbox' name='select-agent-account' aria-label='select email maintenance record' />
              <label className='tds-check__label' title='select email maintenance record'>
                Select Row
              </label>
            </div>
          </td>
          <td className='tds-data-table__cell--align-left' style={{ fontSize: '0.95rem' }}>
            {AccountName}
          </td>
          <td className='tds-data-table__cell--align-left' style={{ fontSize: '0.95rem' }}>
            {SAINumber}
          </td>
        </tr>
      );
    });
  }

  return (
    <div role='dialog' className='tds-modal tds-modal__dialog--large' aria-label='claim activity email maintenance dialog'>
      <div role='document' className='tds-modal__dialog-container claim-activity-email-maintenance-dialog-container'>
        <div className='tds-modal__dialog-header'>
          <h2 className='tds-modal__dialog-title' style={{ color: '#46494d' }}>
            Claim Activity Email Maintenance
          </h2>
        </div>

        <div className='tds-modal__dialog-body'>
          <div style={{ borderBottom: '1px solid #dadbdb', marginTop: '1rem', marginBottom: '1rem' }}>
            <SAI />
          </div>

          <table className='tds-data-table tds-data-table--stacked-up-to-sm tds-data-table--header-dividers tds-data-table--dividers tds-data-table--hilite-row' style={{ width: '100%' }}>
            <caption>
              <Text as="h4" textStyle="h4">
                My Saved Alerts
              </Text>

            </caption>
            {renderClaimActivityEmailMaintenanceTableHeader}
            <tbody>{renderClaimActivityEmailMaintenanceTableData}</tbody>
          </table>

          <div className='sai-delete-sel-btn-div'>
            <button className='tds-button--secondary tds-button--small sai-delete-sel-btn' type='button'>
              DELETE SELECTED
            </button>
          </div>

          <div className='sai-close-btn-div'>
            <button onClick={() => setShowClaimActivityEmailMaintenanceDialog(false)} className='tds-button--primary tds-button--small sai-close-btn' type='button'>
              CLOSE
            </button>
          </div>

          <button onClick={() => setShowClaimActivityEmailMaintenanceDialog(false)} className='tds-modal__dismiss' aria-label='Dismiss Claim Activity e-Alerts' type='button'>
            <ClaimActivityEmailMaintenanceCloseIcon alt='Close Alert' />
          </button>
        </div>
      </div>
    </div>
  );
};
export default ClaimActivityEmailMaintenance;
