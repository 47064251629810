import { apiSlice } from '../../app/api/apiSlice';
import { API, REQ_TYPE } from '../../common/constants';
import { AccountSearchRequest } from '../../common/types';

export const submitReportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    submitParameters: builder.mutation<AccountSearchRequest, any>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.SUBMIT_REPORT,
          body: arg,
        };
      },
      transformResponse: (response: any) => response,
    }),
    submitListingReport: builder.mutation<any, AccountSearchRequest>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.SUBMIT_ACCOUNT_REPORT,
          body: arg,
        };
      },
      transformResponse: (response: any) => response,
    }),
  }),
});

export const { useSubmitParametersMutation, useSubmitListingReportMutation } = submitReportApiSlice;
