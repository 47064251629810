import { TdsField } from '@trv-tds/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSuccessMessage, removeAccountErrorMessage, removeErrorMessage, showClaimActivityErrorMessage, setWasNationalProgramsClicked, setSubmitClicked } from '../../../features/claimActivity/claimActivityErrorMsgSlice';
import useAuth from '../../../hooks/useAuth';
import { RootState } from '../../../store';
import { AccIdentification } from '../AgentAccountSearch/agentAccount';
import { addAccountIdentification, removeSelectedAgentAccount, resetAgentAllSelectedAccounts, updateAccountIdentification } from '../AgentAccountSearch/agentAccountSlice';


type PolicyNumberProps = {
  clearMsg: boolean;
  setClearMsg: Function;
  hasCustomFilters: boolean;
  clearedCriteriaMsg: boolean;
  setClearedCriteriaMsg: Function;
  presetAccountIdentificationOptions?: string[];
  pageName?: string;
}

const defaultAccountIdentificationOptions = [
  "Policy Number", "Account Number", "SAI Number",
  "Producer Code"
]

const PolicyNumber = ({ clearMsg, setClearMsg, hasCustomFilters, clearedCriteriaMsg, setClearedCriteriaMsg,
   presetAccountIdentificationOptions = defaultAccountIdentificationOptions, pageName, }: PolicyNumberProps) => {
  const DEFAULT_ACCOUNT_IDENTIFICATION_MAX_LENGTH = 9;
  const dispatch = useDispatch();
  const { isAgentUser, isInternalUser } = useAuth();
  const [enableAddButton, setEnableAddButton] = useState<boolean>(false);
  const [hidePolicyNumberInstructions, setHidePolicyNumberInstructions] = useState<boolean>(false);
  const [showMaxValuesEnteredMsg, setShowMaxValuesEnteredMsg] = useState<boolean>(false);
  const [showSaiNumberFormatError, setShowSaiNumberFormatError] = useState<boolean>(false);
  const [showAccountNumberFormatError, setShowAccountNumberFormatError] = useState<boolean>(false);
  const [showAccountIdentificationCharacterLengthError, setShowAccountIdentificationCharacterLengthError] = useState<boolean>(false);
  const [showAccountNumberBlankError, setShowAccountNumberBlankError] = useState<boolean>(false);
  const [showRestrictedProducerCodeError, setShowRestrictedProducerCodeError] = useState<boolean>(false);
  const [showRemoveAllValuesEnteredMsg, setShowRemoveAllValuesEnteredMsg] = useState<boolean>(false);
  const [showDuplicateValuesMsg, setShowDuplicateValuesMsg] = useState<boolean>(false);
  const [accountIdentificationMaxLength, setAccountIdentificationMaxLength] = useState(DEFAULT_ACCOUNT_IDENTIFICATION_MAX_LENGTH)
  let [accountIdentificationValueSelected, setAccountIdentificationValueSelected] = useState<string>('');
  const userAccountIdentification: AccIdentification = useSelector((state: RootState) => state.agentAccount.accountIdentification);
  const [accountIdentification, setAccountIdentification] = useState<AccIdentification>(userAccountIdentification);
  const { claimActivityErrorMessage: claimActivityListError, accountErrorMessage, showClaimActivityError, wasNationalProgramsClicked, submitClicked } = useSelector((state: RootState) => state.claimActivity);
  const [clearedOptionalRptCriteriaMsg, setClearedOptionalRptCriteriaMsg] = useState<boolean>(false);
  const accountIdentificationSelectionsDivRef = useRef<null | HTMLDivElement>(null);
  let maxAccountIdentificationEntries = 4;
  let enteredVal = "";
  const topRef = useRef<HTMLInputElement>(null);

  const accountIdentificationOptions = presetAccountIdentificationOptions.map(option => { return { value: option, label: option } })

  const scrollAccountIdentificationError = () => {
    if (accountIdentificationSelectionsDivRef.current) {
      accountIdentificationSelectionsDivRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }
  const scrollToTop = () => {
    if (claimActivityListError!=="") topRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (claimActivityListError && claimActivityListError !== "" && showClaimActivityError) {
      scrollToTop();    
    }
  }, [claimActivityListError]);


  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    if (clearMsg && clearMsg === true) {
      clearErrors();
      setClearMsg(false);
    }
  }, [clearMsg]);

  useEffect(() => {
    if(pageName==='NationalPrograms'){
      clearAccountIdentification();
        dispatch(setWasNationalProgramsClicked("true"));
    }
    if(wasNationalProgramsClicked==="true"){
      clearAccountIdentification();
        dispatch(setWasNationalProgramsClicked(""));
    }
    if (accountIdentification && (!accountIdentification.accountIdentificationSelections || accountIdentification.accountIdentificationSelections.length === 0)) {
      clearErrors()
      if(pageName==='NationalPrograms'){
        handleClearAllButtonClick();
        setAccountIdentification({ ...accountIdentification, accountIdentificationMethod: "Producer Code", accountIdentificationSelections: [], accountIdentificationValue: "" });
        dispatch(updateAccountIdentification({ ...accountIdentification, accountIdentificationMethod: "Producer Code", accountIdentificationSelections: [], accountIdentificationValue: "" }));
        //   setAccountIdentification({ ...accountIdentification, ['accountIdentificationMethod']: 'Producer Code' });
        // dispatch(updateAccountIdentification({ ...accountIdentification, ['accountIdentificationMethod']: 'Producer Code' }));
      }else{
        setAccountIdentification({ ...accountIdentification, ['accountIdentificationMethod']: 'Policy Number' });
        dispatch(updateAccountIdentification({ ...accountIdentification, ['accountIdentificationMethod']: 'Policy Number' }));
      }
     }
  }, [])

  useEffect(() => {
    if (accountErrorMessage !== "") {
      scrollAccountIdentificationError();
    }
  }, [accountErrorMessage])


  useEffect(() => {
    setAccountIdentification(userAccountIdentification);
    if (userAccountIdentification.accountIdentificationMethod !== 'Policy Number') {
      setHidePolicyNumberInstructions(true);
    }else{
      setHidePolicyNumberInstructions(false);

    }
    if(pageName==='NationalPrograms'){
      if ((userAccountIdentification.accountIdentificationSelections.length === 5 && (userAccountIdentification.accountIdentificationMethod === 'PRODUCER CODE' || userAccountIdentification.accountIdentificationMethod === 'Producer Code')) ||
      (userAccountIdentification.accountIdentificationSelections.length === 4 && (userAccountIdentification.accountIdentificationMethod === 'SAI NUMBER' || userAccountIdentification.accountIdentificationMethod === 'SAI Number')) ||      
      (userAccountIdentification.accountIdentificationSelections.length === 8 && (userAccountIdentification.accountIdentificationMethod === 'POLICY NUMBER' || userAccountIdentification.accountIdentificationMethod === 'Policy Number'))) {
      setEnableAddButton(false);
    } else {
      setEnableAddButton(true);
    }

    }else{
    if ((userAccountIdentification.accountIdentificationSelections.length === 1 && (userAccountIdentification.accountIdentificationMethod === 'PRODUCER CODE' || userAccountIdentification.accountIdentificationMethod === 'Producer Code')) ||
      userAccountIdentification.accountIdentificationSelections.length === 4) {
      setEnableAddButton(false);
    } else {
      setEnableAddButton(true);
    }
    }
    if (userAccountIdentification.accountIdentificationSelections.length === 0) {
      dispatch(removeErrorMessage(""));
    }
  }, [userAccountIdentification]);

  useEffect(() => {
    calcMaxLength(accountIdentification.accountIdentificationMethod)

    if (!accountIdentificationOptions.map(v => v.value).includes(accountIdentification.accountIdentificationMethod)) {
      clearAccountIdentification()
    }
  }, [accountIdentification.accountIdentificationMethod])

  const restrictSpaceSpecial = (event: React.KeyboardEvent<HTMLInputElement>) => {
    var regex = new RegExp('^[a-zA-Z0-9]+$');
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };
  const handleOutsideClick = (e: any) => {
    if (e.target.textContent !== 'CLEAR ALL') {
      if (clearedCriteriaMsg || showRemoveAllValuesEnteredMsg) {
        console.log(clearedOptionalRptCriteriaMsg);
        if (showRemoveAllValuesEnteredMsg) {
          setClearMsg(true);
        }
        if (clearedCriteriaMsg)
          setClearedCriteriaMsg(!clearedCriteriaMsg)
      }
    }
  };

  const checkRestrictedProducerCodes = () => {
    let restrictedCodes = [
      'COLAA',
      'G1100',
      '00710',
      '00701',
      '00714',
      '00005',
      '00801',
      '0004J',
      '00802',
      '0004T',
      '00807',
      '0005G',
      '00820',
      '00627',
      '00821',
      '00650',
      '00827',
      '00653',
      'K8300',
      'CHB56',
    ];

    if (
      restrictedCodes.includes(accountIdentification.accountIdentificationValue.toUpperCase()) ||
      accountIdentification.accountIdentificationValue.startsWith('NA') ||
      accountIdentification.accountIdentificationValue.startsWith('na')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const calcMaxEntries = () => {
    switch (accountIdentification.accountIdentificationMethod) {
      case 'Policy Number' || 'POLICY NUMBER':
      case 'SAI Number' || 'SAI NUMBER':
      case 'Account Number' || 'ACCOUNT NUMBER':
        maxAccountIdentificationEntries = 4;
        return maxAccountIdentificationEntries;
      case 'Producer Code' || 'PRODUCER CODE':
        maxAccountIdentificationEntries = 1;
        return maxAccountIdentificationEntries;
      default:
      //do nothing
    }
  };
  
  const calcMaxNatPrgmsMaxEntries = () => {
    switch (accountIdentification.accountIdentificationMethod) {
      case 'Policy Number' || 'POLICY NUMBER':
        maxAccountIdentificationEntries = 8;
        return maxAccountIdentificationEntries;
      case 'SAI Number' || 'SAI NUMBER':
        maxAccountIdentificationEntries = 4;
        return maxAccountIdentificationEntries;
      case 'Producer Code' || 'PRODUCER CODE':
        maxAccountIdentificationEntries = 5;
        return maxAccountIdentificationEntries;
      default:
      //do nothing
    }
  };

  const handleMaxEntries = () => {
    switch (accountIdentification.accountIdentificationMethod) {
      case 'Policy Number' || 'POLICY NUMBER':
      case 'SAI Number' || 'SAI NUMBER':
      case 'Account Number' || 'ACCOUNT NUMBER':
        if (accountIdentification.accountIdentificationSelections.length === 4) {
          setEnableAddButton(false);
        }
        break;
      case 'Producer Code' || 'PRODUCER CODE':
        if (accountIdentification.accountIdentificationValue !== null && accountIdentification.accountIdentificationValue !== "") {
          setEnableAddButton(false);
        }
        break;
      default:
      //do nothing
    }
  };

  const handleMaxEntriesNatPrgms = () => {
    switch (accountIdentification.accountIdentificationMethod) {
      case 'Policy Number' || 'POLICY NUMBER':
        if (accountIdentification.accountIdentificationSelections.length === 8) {
          setEnableAddButton(false);
        }
        break;
      case 'SAI Number' || 'SAI NUMBER':
        if (accountIdentification.accountIdentificationSelections.length === 4) {
          setEnableAddButton(false);
        }
        break;
      case 'Producer Code' || 'PRODUCER CODE':
        if (accountIdentification.accountIdentificationSelections.length === 5) {
          setEnableAddButton(false);
        }
        break;
      default:
      //do nothing
    }
  };

  const checkForDuplicateAccountIdentificationValues = (idValue: string) => {
    let foundDuplicate = false;

    for (let i = 0; i < accountIdentification.accountIdentificationSelections.length; i++) {
      if (accountIdentification.accountIdentificationSelections[i] === idValue.toUpperCase()) {
        foundDuplicate = true;
        break;
      }
    }
    return foundDuplicate;
  };

  const checkSaiFormat = (value: string) => {
    var alpha = /^([a-zA-Z])$/;
    return !alpha.test(value.charAt(4));
  }

  const checkZeros = (value: string) => {
    let retVal = false;

    switch (accountIdentification.accountIdentificationMethod) {
      case 'Policy Number' || 'POLICY NUMBER':
        if (value === '00000000') {
          retVal = true;
        }
        break;
      case 'SAI Number' || 'SAI NUMBER':
        if (value === '000000000') {
          retVal = true;
        }
        break;
      case 'Producer Code' || 'PRODUCER CODE':
        if (value === '00000') {
          retVal = true;
        }
        break;
      default:
      //do nothing
    }

    return retVal;
  };

  const handleValidation = () => {
    clearErrors();
    setAccountIdentification({
      ...accountIdentification,
      accountIdentificationValue: accountIdentification.accountIdentificationValue.trim(),
    });
    enteredVal = accountIdentification.accountIdentificationValue.trim();
    let hasErrors = false;
    if(pageName==='NationalPrograms'){
      switch (accountIdentification.accountIdentificationMethod) {
        case 'Policy Number' || 'POLICY NUMBER':
          if (accountIdentification.accountIdentificationSelections.length === 8) {
            setShowMaxValuesEnteredMsg(true);
            setEnableAddButton(false);
            return;
          }
          break;
        case 'SAI Number' || 'SAI NUMBER':
          if (accountIdentification.accountIdentificationSelections.length === 4) {
            setShowMaxValuesEnteredMsg(true);
            setEnableAddButton(false);
            return;
          }
          break;
        case 'Producer Code' || 'PRODUCER CODE':
          if (accountIdentification.accountIdentificationSelections.length === 5) {
            setShowMaxValuesEnteredMsg(true);
            setEnableAddButton(false);
            return;
          }
          break;
        default:
        //do nothing
      }
  
    }else{
    switch (accountIdentification.accountIdentificationMethod) {
      case 'Policy Number' || 'POLICY NUMBER':
      case 'SAI Number' || 'SAI NUMBER':
      case 'Account Number' || 'ACCOUNT NUMBER':
        if (accountIdentification.accountIdentificationSelections.length === 4) {
          setShowMaxValuesEnteredMsg(true);
          setEnableAddButton(false);
          return;
        }
        break;
      case 'Producer Code' || 'PRODUCER CODE':
        if (accountIdentification.accountIdentificationSelections.length === 1) {
          setShowMaxValuesEnteredMsg(true);
          setEnableAddButton(false);
          return;
        }
        break;
      default:
      //do nothing
    }
  }

    switch (accountIdentification.accountIdentificationMethod) {
      case 'Policy Number' || 'POLICY NUMBER':
        if (accountIdentification.accountIdentificationValue.trim().length < 8 || accountIdentification.accountIdentificationValue.trim().length > 8) {
          setShowAccountIdentificationCharacterLengthError(true);
        } else {
          if (checkZeros(accountIdentification.accountIdentificationValue.trim())) {
            setShowAccountIdentificationCharacterLengthError(true);
            return;
          }

          setShowAccountIdentificationCharacterLengthError(false);

          if (checkForDuplicateAccountIdentificationValues(accountIdentification.accountIdentificationValue.trim())) {
            setShowDuplicateValuesMsg(true);
          } else {
            setShowDuplicateValuesMsg(false);
            dispatch(addAccountIdentification({
              ...accountIdentification,
              accountIdentificationValue: accountIdentification.accountIdentificationValue.trim().toUpperCase(),
            }));
            setAccountIdentification({
              ...accountIdentification,
              accountIdentificationValue: '',
            });
          }
        }
        break;
      case 'SAI Number' || 'SAI NUMBER':
        if (accountIdentification.accountIdentificationValue.trim().length < 9 || accountIdentification.accountIdentificationValue.trim().length > 9) {
          setShowAccountIdentificationCharacterLengthError(true);
        } else {
          if (checkZeros(accountIdentification.accountIdentificationValue.trim())) {
            setShowAccountIdentificationCharacterLengthError(true);
            hasErrors = true;
            return;
          }
          if (checkSaiFormat(accountIdentification.accountIdentificationValue.trim())) {
            setShowSaiNumberFormatError(true);
            hasErrors = true;
            return;
          } else {
            setShowSaiNumberFormatError(false);
          }

          setShowAccountIdentificationCharacterLengthError(false);

          if (checkForDuplicateAccountIdentificationValues(accountIdentification.accountIdentificationValue.trim())) {
            setShowDuplicateValuesMsg(true);
            hasErrors = true;
          } else {
            setShowDuplicateValuesMsg(false);
          }
          if (!hasErrors) {
            dispatch(addAccountIdentification({
              ...accountIdentification,
              accountIdentificationValue: accountIdentification.accountIdentificationValue.trim().toUpperCase(),
            }));
            setAccountIdentification({
              ...accountIdentification,
              accountIdentificationValue: '',
            });
          }
        }
        break;
      case 'Account Number' || 'ACCOUNT NUMBER':
        if (accountIdentification.accountIdentificationValue.trim().length <= 0) {
          setShowAccountNumberBlankError(true);
        } else if (accountIdentification.accountIdentificationValue.trim().length === 9) {
          setShowAccountNumberBlankError(false);

          if (enteredVal.substring(0, 3) === "000") {
            setShowAccountNumberFormatError(false);
          } else if (enteredVal !== "") {
            setShowAccountNumberFormatError(true);
            hasErrors = true;
            return;
          }
          if (checkForDuplicateAccountIdentificationValues(accountIdentification.accountIdentificationValue.trim())) {
            setShowDuplicateValuesMsg(true);
            hasErrors = true;
          } else {
            setShowDuplicateValuesMsg(false);
          }
          if (!hasErrors) {
            dispatch(addAccountIdentification({
              ...accountIdentification,
              accountIdentificationValue: accountIdentification.accountIdentificationValue.trim().toUpperCase(),
            }));
            setAccountIdentification({
              ...accountIdentification,
              accountIdentificationValue: '',
            });
          }
        } else if (accountIdentification.accountIdentificationValue.trim().length > 0 && accountIdentification.accountIdentificationValue.trim().length < 9) {
          setShowAccountNumberBlankError(false);

          let zeroString = '';
          let zeroStringLength = DEFAULT_ACCOUNT_IDENTIFICATION_MAX_LENGTH - accountIdentification.accountIdentificationValue.length;

          for (let i = 0; i < zeroStringLength; i++) {
            zeroString += '0';
          }

          let updatedAccountNumber = zeroString + accountIdentification.accountIdentificationValue.trim();


          setAccountIdentification({
            ...accountIdentification,
            ...{ accountIdentificationValue: updatedAccountNumber },
          });

          if (checkForDuplicateAccountIdentificationValues(updatedAccountNumber)) {
            setShowDuplicateValuesMsg(true);
          } else {
            setShowDuplicateValuesMsg(false);
            dispatch(addAccountIdentification({
              ...accountIdentification,
              accountIdentificationValue: updatedAccountNumber.toUpperCase(),
            }));
            setAccountIdentification({
              ...accountIdentification,
              accountIdentificationValue: '',
            });
          }
        }
        break;
      case 'Producer Code' || 'PRODUCER CODE':
        if (accountIdentification.accountIdentificationValue.trim().length < 5 || accountIdentification.accountIdentificationValue.trim().length > 5) {
          setShowAccountIdentificationCharacterLengthError(true);
        } else if (accountIdentification.accountIdentificationValue.trim().length === 5) {
          if (checkZeros(accountIdentification.accountIdentificationValue.trim())) {
            setShowAccountIdentificationCharacterLengthError(true);
            return;
          }

          setShowAccountIdentificationCharacterLengthError(false);

          if (checkRestrictedProducerCodes()) {
            setShowRestrictedProducerCodeError(true);
          } else {
            if (checkForDuplicateAccountIdentificationValues(accountIdentification.accountIdentificationValue.trim())) {
              setShowDuplicateValuesMsg(true);
            } else {
              setShowDuplicateValuesMsg(false);
              dispatch(addAccountIdentification({
                ...accountIdentification,
                accountIdentificationValue: accountIdentification.accountIdentificationValue.trim().toUpperCase(),
              }));
              setAccountIdentification({
                ...accountIdentification,
                accountIdentificationValue: '',
              });
            }
          }
        }
        break;
      default:
        setShowAccountIdentificationCharacterLengthError(false);
        setShowAccountNumberBlankError(false);
        setShowRestrictedProducerCodeError(false);
        setShowDuplicateValuesMsg(false);
    }

    if(pageName==='NationalPrograms'){
      handleMaxEntriesNatPrgms();
    }else{
      handleMaxEntries();
    }
  };

  function clearErrors() {
    dispatch(addSuccessMessage(""));
    dispatch(removeErrorMessage(""))
    setShowAccountNumberFormatError(false);
    setShowSaiNumberFormatError(false);
    setShowMaxValuesEnteredMsg(false);
    setShowRemoveAllValuesEnteredMsg(false);
    setShowAccountIdentificationCharacterLengthError(false);
    setShowAccountNumberBlankError(false);
    setShowRestrictedProducerCodeError(false);
    setShowDuplicateValuesMsg(false);
    dispatch(removeAccountErrorMessage(""))
  }
  const clearAccountIdentification = () => {
    if(pageName==='NationalPrograms'){
      setAccountIdentification({ ...accountIdentification, accountIdentificationMethod: "Producer Code", accountIdentificationSelections: [], accountIdentificationValue: "" });
      dispatch(updateAccountIdentification({ ...accountIdentification, accountIdentificationMethod: "Producer Code", accountIdentificationSelections: [], accountIdentificationValue: "" }));
    }else{
      setAccountIdentification({ ...accountIdentification, accountIdentificationMethod: "Policy Number", accountIdentificationSelections: [], accountIdentificationValue: "" });
      dispatch(updateAccountIdentification({ ...accountIdentification, accountIdentificationMethod: "Policy Number", accountIdentificationSelections: [], accountIdentificationValue: "" }));
    }
    setHidePolicyNumberInstructions(false);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    clearErrors();
    if(pageName==='NationalPrograms'){
      if ((userAccountIdentification.accountIdentificationSelections.length === 8 && (userAccountIdentification.accountIdentificationMethod === 'POLICY NUMBER' || userAccountIdentification.accountIdentificationMethod === 'Policy Number')) ||
      (userAccountIdentification.accountIdentificationSelections.length === 4 && (userAccountIdentification.accountIdentificationMethod === 'SAI NUMBER' || userAccountIdentification.accountIdentificationMethod === 'SAI Number')) ||
      (userAccountIdentification.accountIdentificationSelections.length === 5 && (userAccountIdentification.accountIdentificationMethod === 'PRODUCER CODE' || userAccountIdentification.accountIdentificationMethod === 'Producer Code'))) {
        setEnableAddButton(false);
      } else {
        setEnableAddButton(true);
      }
    }else{
      if ((userAccountIdentification.accountIdentificationSelections.length === 1 && (userAccountIdentification.accountIdentificationMethod === 'PRODUCER CODE' || userAccountIdentification.accountIdentificationMethod === 'Producer Code')) ||
        userAccountIdentification.accountIdentificationSelections.length === 4) {
        setEnableAddButton(false);
      } else {
        setEnableAddButton(true);
      }
    }
    if (
      event &&
      event.target &&
      event.target.name &&
      event.target.value &&
      event.target.name === 'accountIdentificationMethod' &&
      event.target.value !== accountIdentification.accountIdentificationMethod &&
      accountIdentification.accountIdentificationSelections &&
      accountIdentification.accountIdentificationSelections.length > 0
    ) {
      event.target.value = accountIdentification.accountIdentificationMethod;
      setShowRemoveAllValuesEnteredMsg(true);
      if (clearMsg)
        setClearMsg(false);
      return;
    }

    if (event && event.target && event.target.name && event.target.value && event.target.name === 'accountIdentificationMethod' && event.target.value !== 'Policy Number') {
      setHidePolicyNumberInstructions(true);
    }

    if (event && event.target && event.target.name && event.target.value && event.target.name === 'accountIdentificationMethod' && event.target.value === 'Policy Number') {
      setHidePolicyNumberInstructions(false);
    }
    setAccountIdentification({ ...accountIdentification, [event.target.name]: event.target.value });
    dispatch(updateAccountIdentification({ ...accountIdentification, [event.target.name]: event.target.value }));
  };

  const handleAddButtonClick = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    event.preventDefault();
    handleValidation();
  };

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event && event.key && event.key === 'Enter') {
      handleAddButtonClick(event);
    }
  };

  const handleRemoveSelectedButtonClick = (event?: React.MouseEvent<HTMLElement>) => {
    clearErrors();
    dispatch(setSubmitClicked(false));
    dispatch(showClaimActivityErrorMessage(false));
    let filteredAccountIdentificationSelections: string[] = [];

    if (accountIdentification && accountIdentification.accountIdentificationSelections && accountIdentification.accountIdentificationSelections.length > 0) {
      filteredAccountIdentificationSelections = accountIdentification.accountIdentificationSelections.filter((element) => {
        return element !== accountIdentificationValueSelected;
      });
    }

    //accountIdentification.accountIdentificationSelections = filteredAccountIdentificationSelections;
    setAccountIdentification({
      ...accountIdentification,
      accountIdentificationMethod: userAccountIdentification.accountIdentificationMethod,
      accountIdentificationSelections: userAccountIdentification.accountIdentificationSelections,
    });

    dispatch(updateAccountIdentification(accountIdentification));
    dispatch(removeSelectedAgentAccount(accountIdentificationValueSelected));

    const selectedAccountIdentificationValueFound = filteredAccountIdentificationSelections.find((element) => element === accountIdentificationValueSelected);
    const enteredAccountIdentificationValueFound = filteredAccountIdentificationSelections.find((element) => element === accountIdentification.accountIdentificationValue);

    if (!selectedAccountIdentificationValueFound) {
      setShowMaxValuesEnteredMsg(false);
      setEnableAddButton(true);
    }

    if (!enteredAccountIdentificationValueFound) {
      setShowDuplicateValuesMsg(false);
    }

    if (filteredAccountIdentificationSelections.length === 0) {
      setShowRemoveAllValuesEnteredMsg(false);
    }

    let accountIdentificationSelectionsDivIDChildNodes = document?.getElementById('accountIdentificationSelectionsDivID')?.childNodes;
    if (accountIdentificationSelectionsDivIDChildNodes) {
      for (let i = 0; i < accountIdentificationSelectionsDivIDChildNodes.length; i++) {
        (accountIdentificationSelectionsDivIDChildNodes[i] as HTMLElement).style.backgroundColor = '#FFFFFF';
      }
    }

    accountIdentificationValueSelected = '';
    setAccountIdentificationValueSelected('');
  };

  const handleClearAllButtonClick = (event?: React.MouseEvent<HTMLElement>) => {
    clearErrors();
    dispatch(setSubmitClicked(false));
    dispatch(showClaimActivityErrorMessage(false));
    setAccountIdentification({
      ...accountIdentification,
      accountIdentificationValue: '',
      accountIdentificationSelections: [],
    });
    dispatch(resetAgentAllSelectedAccounts());

    setEnableAddButton(true);
    setShowRemoveAllValuesEnteredMsg(false);
    setShowDuplicateValuesMsg(false);
    accountIdentificationValueSelected = '';
    setAccountIdentificationValueSelected('');
  };

  const calcMaxLength = (accountIdentificationMethod: string) => {
    switch (accountIdentificationMethod) {
      case 'Policy Number' || 'POLICY NUMBER':
        setAccountIdentificationMaxLength(8)
        break;
      case 'SAI Number' || 'SAI NUMBER':
        setAccountIdentificationMaxLength(9)
        break;
      case 'Account Number' || 'ACCOUNT NUMBER':
        setAccountIdentificationMaxLength(9)
        break;
      case 'Producer Code' || 'PRODUCER CODE':
        setAccountIdentificationMaxLength(5)
        break;
      default:
        setAccountIdentificationMaxLength(9)
    }
  };

  const handleAccountIdentificationValueSelection = (event: any) => {
    setAccountIdentificationValueSelected(event.target.textContent.trim());
    accountIdentificationValueSelected = event.target.textContent.trim();

    event.target.style.backgroundColor = '#0091eb66';
    let accountIdentificationSelectionsDivIDChildNodes = document.getElementById('accountIdentificationSelectionsDivID')?.childNodes;

    if (accountIdentificationSelectionsDivIDChildNodes) {
      for (let i = 0; i < accountIdentificationSelectionsDivIDChildNodes.length; i++) {
        if (accountIdentificationSelectionsDivIDChildNodes[i]?.textContent?.trim() !== accountIdentificationValueSelected) {
          (accountIdentificationSelectionsDivIDChildNodes[i] as HTMLElement).style.backgroundColor = '#FFFFFF';
        }
      }
    }
  };

  return (
    <div>
      {(isAgentUser || isInternalUser) && (
        <div className='policy-number-start-div'>
          <div ref={topRef} className='tds-alert-error' hidden={((!showClaimActivityError || claimActivityListError === "") && !submitClicked) || accountIdentification.accountIdentificationSelections.length === 0}>
            {claimActivityListError}
          </div>

          <div className='homeform-inner-div-policy-search'>
            <div className='policy-selection-div-outer'>
              <div className='policy-selection-div-inner'>
                <div className='home-form-policy-input home-form-policy-input-dropdown' style={{ marginRight: '3%' }}>
                  <TdsField>
                    <span slot='label' aria-label='account identification method' aria-hidden={true} hidden={true}>
                      Account Identification Method
                    </span>
                    <select
                      style={{ width: '100%', height: '2rem', verticalAlign: 'top', padding: '0 0 0 0.75rem', fontSize: '1rem' }}
                      onChange={(event) => handleChange(event)}
                      name='accountIdentificationMethod'
                      value={accountIdentification.accountIdentificationMethod}
                    >
                      {accountIdentificationOptions.length > 0 && accountIdentificationOptions?.map((element, index) => (
                        <option key={index} value={element?.value}>
                          {element?.value}
                        </option>
                      ))}

                    </select>
                  </TdsField>
                </div>

                <div className='home-form-policy-input'>
                  <TdsField alert={(showAccountIdentificationCharacterLengthError || showSaiNumberFormatError || showAccountNumberFormatError || showAccountNumberBlankError || showRestrictedProducerCodeError) ? 'error' : 'false'}>
                    <span aria-hidden='true' hidden slot='label'></span>
                    <input
                      data-testid="policy-number-input"
                      style={{ height: '2rem' }}
                      type='text'
                      aria-label='account identification'
                      maxLength={accountIdentificationMaxLength}
                      name='accountIdentificationValue'
                      value={accountIdentification.accountIdentificationValue}
                      onKeyPress={(event) => restrictSpaceSpecial(event)}
                      onKeyUp={(event) => handleEnterKey(event)}
                      onChange={(event) => handleChange(event)}
                    ></input>
                    <div className='tds-field--error' style={{ marginBottom: 0 }} >
                      <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!showAccountIdentificationCharacterLengthError}>
                        <p style={{ fontSize: '0.8rem' }}>
                          {accountIdentification.accountIdentificationMethod} must be {accountIdentificationMaxLength} characters.
                        </p>
                      </div>
                      <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!showSaiNumberFormatError}>
                        <p style={{ fontSize: '0.8rem' }}>
                          {accountIdentification.accountIdentificationMethod} must be {accountIdentificationMaxLength} characters with alpha in 5th position.
                        </p>
                      </div>

                      <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!showAccountNumberFormatError}>
                        <p style={{ fontSize: '0.8rem' }}>
                          Account Number {accountIdentification.accountIdentificationValue} is invalid.
                        </p>
                      </div>

                      <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!showAccountNumberBlankError}>
                        <p style={{ fontSize: '0.8rem' }}>Account Number cannot be blank.</p>
                      </div>

                      <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!showRestrictedProducerCodeError}>
                        <p style={{ fontSize: '0.8rem' }}>Restricted Producer Code. Please make another selection.</p>
                      </div>
                    </div>
                  </TdsField>
                </div>

                <div className='policy-number-add-button-container'>
                  <button
                    className='tds-button--primary tds-button--small'
                    type='button'
                    data-testid="add-button"
                    style={{ width: '100%', height: '2rem', marginTop: '5px' }}
                    disabled={!enableAddButton}
                    onClick={(event) => handleAddButtonClick(event)}
                  >
                    ADD
                  </button>
                </div>
              </div>

              <div style={{ width: '100%' }} hidden={hidePolicyNumberInstructions}>
                <p className='body-xsmall wireframe-p-font-italic'>
                  For a Policy Number, enter the last portion of the policy number only. For example: GC2J-CAP-123A5678, the policy number to be entered would be 123A5678.
                </p>
              </div>
            </div>

            <div className='policy-number-display-div'>
              <div className='tds-field tds-field--error'>
                <div
                  data-testid="policy-number-data"
                  className={`policy-number-display-inner-div ${showRemoveAllValuesEnteredMsg && `tds-field__input`}`}
                  tabIndex={0}
                  aria-label='account identification selections'
                  role='list'
                  ref={accountIdentificationSelectionsDivRef}
                  id='accountIdentificationSelectionsDivID'
                  style={{ height: '110px', border: '1px solid #909294', borderRadius: '2%', color: '#46494d', overflow: 'auto' }}
                >
                  {accountIdentification.accountIdentificationSelections.map(function (element, index) {
                    return (
                      <p key={element} onClick={(event) => handleAccountIdentificationValueSelection(event)} style={{ cursor: 'default', marginBottom: 0 }} role='listitem'>
                        {element}
                      </p>
                    );
                  })}
                </div>
                <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!showMaxValuesEnteredMsg}>
                  <p style={{ fontSize: '0.8rem' }}>
                    Max {accountIdentification.accountIdentificationSelections.length} of {pageName==='NationalPrograms'?calcMaxNatPrgmsMaxEntries():calcMaxEntries()} values entered.
                  </p>
                </div>

                <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!(showRemoveAllValuesEnteredMsg && !hasCustomFilters && !clearMsg)}>
                  <p style={{ fontSize: '0.8rem' }}>Remove selected account values before changing Account Identification type.</p>
                </div>
                <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!(showRemoveAllValuesEnteredMsg && hasCustomFilters && !clearMsg)}>
                  <p style={{ fontSize: '0.8rem' }}>Remove selected account values before changing Account Identification type.  Optional Report Criteria will be reset.</p>
                </div>

                <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!showDuplicateValuesMsg}>
                  <p style={{ fontSize: '0.8rem' }}>Value has already been entered.</p>
                </div>
              </div>

              <p className='body-xsmall wireframe-p-font-regular' style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
                (Added {accountIdentification.accountIdentificationSelections.length} of {pageName==='NationalPrograms'?calcMaxNatPrgmsMaxEntries():calcMaxEntries()})
              </p>
              <div className='tds-field--error' style={{ marginBottom: 10 }} >
                <div aria-atomic='true' className='tds-field__message' role='alert' hidden={accountErrorMessage === ""}>
                  <p style={{ fontSize: '0.8rem' }}>
                    {accountErrorMessage}
                  </p>
                </div>
              </div>
              <div className='tds-field--error' style={{ marginBottom: 10 }} >
                <div aria-atomic='true' className='tds-field__message' role='alert' hidden={clearedCriteriaMsg === false}>
                  <p style={{ fontSize: '0.8rem' }}>
                    Optional Report Criteria has been reset
                  </p>
                </div>
              </div>
              <div className='policy-number-button-container'>
                <button
                  className='tds-button--secondary tds-button--small policy-nbr-remove-sel-btn'
                  style={{ height: '2rem' }}
                  disabled={accountIdentificationValueSelected === ''}
                  type='button'
                  onClick={(event) => handleRemoveSelectedButtonClick(event)}
                >
                  REMOVE SELECTED
                </button>
                <button
                  className='tds-button--secondary tds-button--small policy-nbr-clr-all-btn'
                  style={{ height: '2rem' }}
                  disabled={accountIdentification.accountIdentificationSelections.length <= 0}
                  type='button'
                  onClick={(event) => handleClearAllButtonClick(event)}
                >
                  CLEAR ALL
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PolicyNumber;