import * as React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Button, BoxProps, TableContainer } from "@chakra-ui/react";
import { ReactComponent as ArrowUpIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/arrow-up-1-12.svg';
import { ReactComponent as ArrowDownIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/arrow-down-1-12.svg';
import { ReactComponent as ArrowUpDownIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/arrow-double-1-12.svg';
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    ColumnDef,
    SortingState,
    getSortedRowModel,
    RowData
} from "@tanstack/react-table";

export type DataTableProps<Data extends object> = {
    data: Data[];
    columns: ColumnDef<Data, any>[];
    onRowClick: (data: any) => void;
};

declare module '@tanstack/table-core' {
    interface ColumnMeta<TData extends RowData, TValue> {
        isNumeric: boolean
    }
}

const tableColumns: BoxProps = {
    textAlign: "left",
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
    borderLeftColor: "gray.100",
    padding: 2,
    fontSize: "xs"
}

const tableBottomColumns: BoxProps = {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "gray.100",
}

export function DataTable<Data extends object>({
    data,
    columns,
    onRowClick
}: DataTableProps<Data>) {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting
        }
    });

    return (
        <TableContainer
            whiteSpace="break-spaces"
            borderStyle="solid"
            borderWidth={1}
            borderColor="gray.100"
        >
            <Table
                size="sm"
                variant='unstyled'
                layout="fixed"
            >
                <Thead
                    borderBottom="1px"
                    borderColor="black"
                >
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header, index) => {
                                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                                const meta: any = header.column.columnDef.meta;
                                const columnStyles: BoxProps = index === (headerGroup.headers.length - 1) ? { ...tableColumns, textAlign: 'right', width: '170px' } : meta?.isNumeric ? { ...tableColumns, width: '120px' } : { p: 0, pl: 4, width: "240px" }

                                return (
                                    <Th
                                        {...columnStyles}
                                        key={header.id}
                                        onClick={header.column.getToggleSortingHandler()}
                                        isNumeric={meta?.isNumeric}
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}

                                        <chakra.span pl="2">
                                            {header.column.getIsSorted() ? (
                                                header.column.getIsSorted() === "desc" ? (
                                                    <ArrowDownIcon aria-label="sorted descending" />
                                                ) : (
                                                    <ArrowUpIcon aria-label="sorted ascending" />
                                                )
                                            ) : <ArrowUpDownIcon aria-label="no sorting" />}
                                        </chakra.span>
                                    </Th>
                                );
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row) => (
                        <Tr key={row.id}>
                            {row.getVisibleCells().map((cell, index) => {
                                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                                const meta: any = cell.column.columnDef.meta;
                                const content: any = cell.column.columnDef.cell;
                                const columnStyles: BoxProps = index === row.getVisibleCells().length - 1 ? { ...tableColumns, ...tableBottomColumns, textAlign: 'right' } : meta?.isNumeric ? { ...tableColumns, ...tableBottomColumns } : { ...tableBottomColumns }
                                const isDisabled = (row.getVisibleCells()[1].getValue() as number) === 0;
                                return (
                                    <Td key={cell.id}
                                        isNumeric={meta?.isNumeric}
                                        {...columnStyles}
                                    >
                                        {meta?.isNumeric ? (
                                            flexRender(content, cell.getContext())
                                        ) : (
                                            <Button
                                                onClick={() => isDisabled ? null : onRowClick(row.original)}
                                                color={isDisabled ? 'black' : '#007395'}
                                                variant="regularLink"
                                                size="xs"
                                                fontSize=".75rem"
                                                ml={-2.5}
                                                _hover={{
                                                    fontSize: ".75rem",
                                                    cursor: isDisabled ? "default" : "pointer"
                                                }}
                                            >
                                                {flexRender(content, cell.getContext())}
                                            </Button>
                                        )}
                                    </Td>
                                );
                            })}
                        </Tr>
                    ))}
                </Tbody>
            </Table >
        </TableContainer>
    );
}