import { useNavigate  } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import OktaConfig from './components/auth/oktaConfig';
import useAuth from './hooks/useAuth';
import { useLocation } from 'react-router-dom';

// const RMISOktaAuthOptions = {
//   issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/aus1vxtwa89Wh7Kcz0h8`,
  
//   //issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/aus1wl833sqqiA1Yg0h8`,//rmis loss reports auth server
//   //issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/aus16frbv1j0oH99s0h8`,//original auth server - bond and SI shared auth server
//   clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
//   //redirectUri: 'https://bi-rmis-lrptui-uat.thc.travp.net',
//   redirectUri: window.location.origin + '/index.html', // Should work for both local and remote.
//   // redirectUri:'http://localhost:3000/login/callback' ,	// For local testing.
//   pkce: true,
// };

// const oktaAuth = new OktaAuth(RMISOktaAuthOptions);

type SecurityWrapperProps = {
  children: React.ReactNode;
};
function SecurityWrapper({ children }: SecurityWrapperProps) {
  const oktaAuth = new OktaAuth(OktaConfig());
  console.log('oktaAuth::', oktaAuth);
  const navigate = useNavigate();
  const { roleCodes, isInternalUser } = useAuth();
  const location=useLocation();
  const { search } = location;

if(search==='?page=NationalPrograms' && !hasNatProgramsRoleCode()){
  navigate('./');
}

const restoreOriginalUri = async (oktaAuth: unknown, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
   };
  function hasNatProgramsRoleCode(){
    const hasRoleCode=roleCodes.filter((element) =>{ 
      return element.toUpperCase() === 'ELRS1' || element.toUpperCase() === 'NEPVBILOSS' || element.toUpperCase() === 'NLOSSONLY';
    })
    return hasRoleCode.length>0;
  }

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };



  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // Trigger OKTA authentication
      await triggerLogin();
    }
  };

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
}

export default SecurityWrapper;
