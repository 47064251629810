import { apiSlice } from '../../app/api/apiSlice';
import { API, REQ_TYPE } from '../../common/constants';
import { AccountSearchRequest, AuthenticatedGetQuery, AuthenticatedQuery, SavedParameterResponse } from '../../common/types';


export const savedParametersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSavedParameters: builder.query<any, any>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.SAVED_PARAMS,
          body: arg,
        };
      },
      transformResponse: (response: any) => response.result,
    }),
    savedParameters: builder.mutation<SavedParameterResponse, AuthenticatedQuery>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.SAVED_PARAMS,
          body: arg,
        };
      },
      transformResponse: (response: any) => response.result,
    }),
    getReportSavedParameters: builder.mutation<any, AuthenticatedGetQuery>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.GET_REPORTS_SAVED_PARAMS,
          body: arg,
        };
      },
      transformResponse: (response: any) => response,
    }),
    getCriteriaSavedParameters: builder.mutation<any, AuthenticatedGetQuery>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.GET_CRITERIA_SAVED_PARAMS,
          body: arg,
        };
      },
      transformResponse: (response: any) => response,
    }),
    // submitParameters: builder.mutation<AccountSearchRequest, any>({
    //   query: (arg) => {
    //     return {
    //       method: REQ_TYPE.POST,
    //       url: API.SUBMIT_REPORT,
    //       body: arg,
    //     };
    //   },
    //   transformResponse: (response: any) => response,
    // }),

  }),
});

export const { useGetSavedParametersQuery, useSavedParametersMutation, useGetReportSavedParametersMutation, useGetCriteriaSavedParametersMutation } = savedParametersApiSlice;
