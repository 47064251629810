import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import { addErrorMessage } from "../../features/errors/errorReducer";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    const genericMassage: any = action.payload?.messages;
    if (genericMassage && genericMassage[0].code == 100 && genericMassage[0].description == "Not found") {
      localStorage.removeItem('apiGatewayTimeout');
      const {
        payload: {
          messages: [{ description }],
        },
        meta: {
          baseQueryMeta: {
            request: { url },
          },
        },
      } = action;
      const apiMessage = `${description} - ${url}`;
      api.dispatch(addErrorMessage(apiMessage));
      console.log("🚀 ~ apiMessage:", apiMessage)
    }

    if (isRejectedWithValue(action)) {
      localStorage.removeItem('apiGatewayTimeout');
      const {
        payload: {
          data: { message },
        },
        meta: {
          baseQueryMeta: {
            request: { url },
          },
        },
      } = action;
      let apiMessage = `${message} - ${url}`;
      if (action?.payload?.originalStatus !== undefined) {
        window.localStorage.setItem('apiGatewayTimeout', JSON.stringify(504));
        const timeoutMessage = "Endpoint request timed out";
        apiMessage = `${timeoutMessage} - ${url}`
      }
      api.dispatch(addErrorMessage(apiMessage));
      console.log("🚀 ~ apiMessage:", apiMessage)
    }

    return next(action);
  };
