import { Dispatch, SetStateAction, useState } from 'react';
import ClaimActivityEmailMaintenance from './ClaimActivityEmailMaintenance';
import { ReactComponent as ClaimActivityEAlertsCloseIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-12.svg';
import useAuth from '../../../hooks/useAuth';
type ClaimsActivityEAlertsProps = {
  setShowClaimsActivityEAlertDialog: Dispatch<SetStateAction<boolean>>;
};
const ClaimsActivityEAlerts = ({ setShowClaimsActivityEAlertDialog }: ClaimsActivityEAlertsProps) => {
  const [showClaimActivityEmailMaintenanceDialog, setShowClaimActivityEmailMaintenanceDialog] = useState(false);
  const { isAgentUser, isInternalUser, isAccountLocationUser } = useAuth();

  function claimEmailActivities() {
    setShowClaimActivityEmailMaintenanceDialog(true);
  }

  return (
    //Need to add Agent and account user popup
    <div hidden= {!isInternalUser} role='dialog' className='tds-modal tds-modal__dialog--medium' aria-label='claim activity email alerts modal'>
      <div role='document' className='tds-modal__dialog-container'>
        <div className='tds-modal__dialog-header'>
          <h2 className='tds-modal__dialog-title' style={{ color: '#46494d' }}>
            Claims Activity e-Alerts
          </h2>
        </div>

        <div className='tds-modal__dialog-body' style={{ paddingBottom: '2rem' }}>
          <div className='tds-modal__dialog-body-content'>
            <p style={{ fontSize: '1.1rem' }}>
              e-Alerts: This will send you a daily email alert for any claim activity<br></br>
              on the accounts in your personal book of business to your email<br></br>address: Dtran@Travelers.com.
            </p>

            <p style={{ fontSize: '1.1rem' }}>Thank you for showing interest in e-Alerts.</p>

            <p style={{ fontSize: '1.1rem' }}>Do you want to subscribe to Daily claim activity e-Alerts?</p>
          </div>

          <div className='claims-activity-e-alerts-button-div'>
            <div style={{ margin: '0 auto' }}>
              <button onClick={() => claimEmailActivities()} className='tds-button--primary claims-activity-e-alerts-button' style={{ marginBottom: '1rem' }} type='button'>
                
                YES
              </button>
            </div>

            <div style={{ margin: '0 auto' }}>
              <button onClick={() => setShowClaimsActivityEAlertDialog(false)} className='tds-button--secondary claims-activity-e-alerts-button' type='button'>
                
                NO
              </button>
            </div>
          </div>

          <button onClick={() => setShowClaimsActivityEAlertDialog(false)} className='tds-modal__dismiss' aria-label='Dismiss Claim Activity e-Alerts' type='button'>
            <ClaimActivityEAlertsCloseIcon alt='Close Alert' />
          </button>
        </div>
      </div>
      {showClaimActivityEmailMaintenanceDialog && <ClaimActivityEmailMaintenance setShowClaimActivityEmailMaintenanceDialog={setShowClaimActivityEmailMaintenanceDialog} />}
    </div>
  );
};

export default ClaimsActivityEAlerts;
