function ErrorMessage() {
  const profileDataRole: any = localStorage.getItem("profile-role");
  const apiGatewayTimeout: any = localStorage.getItem("apiGatewayTimeout");
  console.log('Error Message parse: ', JSON.parse(apiGatewayTimeout));

  if (profileDataRole) {
    const { IsEcarmaUser, IsAgent } = JSON.parse(profileDataRole);
    if (JSON.parse(apiGatewayTimeout) === 504) {
      return "Your request has exceeded the maximum amount of time allotted. Adjust dates and/or filters to reduce the data being requested.";
    } else if (IsEcarmaUser) {
      return "An error has been encountered.  Please review or contact your RMIS Information Account Executive for assistance.";
    } else if (IsAgent) {
      return "An error has been encountered.  Please review or contact Agency Automation at (800) 842-2522 for assistance.";
    } else {
      return "An error has been encountered.  Please review.";
    }
  }
  return "An error has been encountered.  Please review.";
}

export default ErrorMessage;
