import { TdsField } from '@trv-tds/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AccIdentification } from '../AgentAccountSearch/agentAccount';
import { RootState } from '../../../store';


type ProgramCodeProps = {
  programCodeSelections:string[];
  setProgramCodeSelections: Function;
};

export const ProgramCodes = ({
  programCodeSelections,
  setProgramCodeSelections,
}: ProgramCodeProps) => {
  let enteredVal = "";
  const [enableAddButton, setEnableAddButton] = useState<boolean>(false);
  const [showMaxValuesEnteredMsg, setShowMaxValuesEnteredMsg] = useState<boolean>(false);
  const [showProgramCodeCharacterLengthError, setShowProgramCodeCharacterLengthError] = useState<boolean>(false);
  const [showDuplicateValueError,setShowDuplicateValueError] = useState<boolean>(false);
  const [resetProgramCodeMsg,setResetProgramCodeMsg] = useState<boolean>(false);
  const [programCode, setProgramCode] = useState<string>();
  let [programCodeValueSelected,setProgramCodeValueSelected] = useState<string>('');
  const programCodeMaxLength=3;

  const userAccountIdentification: AccIdentification = useSelector(
    (state: RootState) => state.agentAccount.accountIdentification
  );
  
  const { accountIdentificationSelections } = userAccountIdentification;

  useEffect(() => {
    clearErrors();
    setResetProgramCodeMsg(false);
    if(userAccountIdentification.accountIdentificationMethod === 'Producer Code' && userAccountIdentification.accountIdentificationSelections.length>0 && programCodeSelections.length<15){
      setEnableAddButton(true);
    }else if(userAccountIdentification.accountIdentificationSelections.length===0 && programCodeSelections.length>0){
      setResetProgramCodeMsg(true);
      setProgramCodeSelections([]);
      setProgramCode('');
      setEnableAddButton(false);  
    }else if(userAccountIdentification.accountIdentificationMethod !== 'Producer Code'){
        setEnableAddButton(false);
    }else if(userAccountIdentification.accountIdentificationMethod === 'Producer Code' && userAccountIdentification.accountIdentificationSelections.length === 0){
      setEnableAddButton(false);
    }
  }, [userAccountIdentification]);

  const handleAddButtonClick = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    event.preventDefault();
    handleValidation();
  };
  
  function clearErrors() {
    setShowProgramCodeCharacterLengthError(false);
    setShowDuplicateValueError(false);
    setResetProgramCodeMsg(false);
  }

  const handleValidation = () => {
    clearErrors();
    enteredVal = programCode?programCode.toUpperCase().trim():"";
    if (programCodeSelections?.length === 15) {
       setShowMaxValuesEnteredMsg(true);
       setEnableAddButton(false);
       return;
    }
  
    if(enteredVal.length<3){
      setShowProgramCodeCharacterLengthError(true);
      return;
    }else{
      setShowProgramCodeCharacterLengthError(false);
    }
    if(checkForDuplicateValues(enteredVal)){
      setShowDuplicateValueError(true);
      return;
    }else{
      setShowDuplicateValueError(false);
    }
    programCodeSelections?.push(enteredVal);
    setProgramCodeSelections(programCodeSelections);
    setProgramCode("");
    handleMaxEntries();
  };

  const checkForDuplicateValues = (idValue: string) => {
    let foundDuplicate = false;

    for (let i = 0; i < programCodeSelections.length; i++) {
      if (programCodeSelections[i] === idValue.toUpperCase()) {
        foundDuplicate = true;
        break;
      }
    }
    return foundDuplicate;
  };

  const handleMaxEntries = () => {
    if(programCodeSelections.length>=15)
      setEnableAddButton(false);
  };

  const handleProgramCodeValueSelection = (event: any) => {
     setProgramCodeValueSelected(event.target.textContent.trim());
     programCodeValueSelected = event.target.textContent.trim();

    event.target.style.backgroundColor = '#0091eb66';
    let programCodeSelectionsDivIDChildNodes = document.getElementById('programCodeSelectionsDivID')?.childNodes;

    if (programCodeSelectionsDivIDChildNodes) {
      for (let i = 0; i < programCodeSelectionsDivIDChildNodes.length; i++) {
        if (programCodeSelectionsDivIDChildNodes[i]?.textContent?.trim() !== programCodeValueSelected) {
          (programCodeSelectionsDivIDChildNodes[i] as HTMLElement).style.backgroundColor = '#FFFFFF';
        }
      }
    }
  };

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event && event.key && event.key === 'Enter' && enableAddButton) {
      handleAddButtonClick(event);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
//    event.preventDefault();
    clearErrors();
    let prevValue=programCode;
      const regex = new RegExp('^[a-zA-Z0-9]+$');
    if (regex.test(event.target.value) || event.target.value.length === 0) {
       setProgramCode( event.target.value );
    }else{
      setProgramCode(prevValue);
    }
  };

  const handleClearAllButtonClick = (event?: React.MouseEvent<HTMLElement>) => {
    clearErrors();
    setProgramCodeSelections( []);

    setEnableAddButton(true);
  };

  const handleRemoveSelectedButtonClick = (event?: React.MouseEvent<HTMLElement>) => {
    clearErrors();
    let filteredAccountIdentificationSelections: string[] = [];
    if (programCodeSelections && programCodeSelections.length > 0) {
      filteredAccountIdentificationSelections = programCodeSelections.filter((element) => {
        return element !== programCodeValueSelected;
      });
    }
    setProgramCodeSelections(filteredAccountIdentificationSelections);
    const programCodeValueFound = filteredAccountIdentificationSelections.find((element) => element === programCodeValueSelected);
    if (!programCodeValueFound) {
      setShowMaxValuesEnteredMsg(false);
      setEnableAddButton(true);
    }
    let programCodeSelectionsDivIDChildNodes = document?.getElementById('programCodeSelectionsDivID')?.childNodes;
    if (programCodeSelectionsDivIDChildNodes) {
      for (let i = 0; i < programCodeSelectionsDivIDChildNodes.length; i++) {
        (programCodeSelectionsDivIDChildNodes[i] as HTMLElement).style.backgroundColor = '#FFFFFF';
      }
    }
     programCodeValueSelected = '';
     setProgramCodeValueSelected('');
  };

  return (
    <div className='homeform-inner-div-policy-search'>
      <div className='policy-selection-div-outer'>
        <div className='policy-selection-div-inner'>
          <div className='home-form-policy-input program-codes-input-label' style={{ marginRight: '3%', fontWeight: 'bold' }}>
            <span slot='label'>Program Code(s)</span>
          </div>

          <div className='home-form-policy-input program-codes-input'>
            <TdsField alert={(showProgramCodeCharacterLengthError?'error':'false')}>
              <span aria-hidden='true' hidden slot='label'></span>
              <input type='text' 
              aria-label='program codes' 
              maxLength={programCodeMaxLength}                     
              name='programCodeInput'
              value={programCode}  
              onKeyUp={(event) => handleEnterKey(event)}
              onChange={(event) => handleChange(event)}
              />
            <div className='tds-field--error' style={{ marginBottom: 0 }} >
                <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!showProgramCodeCharacterLengthError}>
                    <p style={{ fontSize: '0.8rem' }}>
                       Program Codes must be 3 alpha-numeric characters.
                    </p>
                </div>
            </div>
            <div className='tds-field--error' style={{ marginBottom: 0 }} >
                <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!resetProgramCodeMsg}>
                    <p style={{ fontSize: '0.8rem' }}>
                       Program Code(s) have been reset
                    </p>
                </div>
            </div>
            
            </TdsField>
          </div>
         <div className='policy-number-add-button-container program-codes-add-btn-div'>
         <button className='tds-button--primary tds-button--small' 
           type='button' 
           style={{ width: '100%', height: '2rem' }}                    
           disabled={!enableAddButton}
           onClick={(event) => handleAddButtonClick(event)}
         >
           ADD
         </button>
         </div>
         </div>
      </div>

        <div className='policy-number-display-div'>
              <div className='tds-field tds-field--error'>
                <div
                  data-testid="policy-number-data"
                  className={`policy-number-display-inner-div`}
                  tabIndex={0}
                  aria-label='account identification selections'
                  role='list'
                  id='programCodeSelectionsDivID'
                  style={{ height: '110px', border: '1px solid #909294', borderRadius: '2%', color: '#46494d', overflow: 'auto' }}
                >
                  {programCodeSelections && programCodeSelections?.map(function (element, index) {
                    return (
                      <p key={element} onClick={(event) => handleProgramCodeValueSelection(event)} style={{ cursor: 'default', marginBottom: 0 }} role='listitem'>
                        {element}
                      </p>
                    );
                  })}
             </div>
            <div className='tds-field--error' style={{ marginBottom: 0 }} >
                <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!showDuplicateValueError}>
                    <p style={{ fontSize: '0.8rem' }}>
                       Value has already been added.
                    </p>
                </div>
            </div>
        </div>
                


        <p className='body-xsmall wireframe-p-font-regular' style={{ marginBottom: '0.5rem' }}>
          (Added {programCodeSelections.length} of 15)
        </p>
        <div aria-atomic='true' className='tds-field__message' role='alert' hidden={!showMaxValuesEnteredMsg}>
                  <p style={{ fontSize: '0.8rem' }}>
                    Max {programCodeSelections.length} of 15 values entered.
                  </p>
                </div>
         <div className='policy-number-button-container'>
         <button 
         className='tds-button--secondary tds-button--small policy-nbr-remove-sel-btn' 
         style={{ height: '2rem' }} 
         type='button'
         disabled={programCodeValueSelected === '' || programCodeSelections.length <= 0}
         onClick={(event) => handleRemoveSelectedButtonClick(event)}
         >
            REMOVE SELECTED
          </button>
          <button 
            className='tds-button--secondary tds-button--small policy-nbr-clr-all-btn' 
            style={{ height: '2rem' }} 
            type='button'
            disabled={programCodeSelections.length <= 0}                   
            onClick={(event) => handleClearAllButtonClick(event)}
>
            CLEAR ALL
          </button>
        </div>
      </div>
    </div>
  );
};
export default ProgramCodes;
