import { apiSlice } from '../../app/api/apiSlice';
import { API, REQ_TYPE } from '../../common/constants';


export const claimActivityApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getClaimActivityList: builder.query<any, any>({
        query: (arg) => {
          return {
            method: REQ_TYPE.POST,
            url: API.CLAIM_ACTIVITY,
            body: arg,
          };
        },
        transformResponse: (response: any) => response,
      }),
    }),
  });
  
  export const { useGetClaimActivityListQuery } = claimActivityApiSlice;
  