import { ChakraProvider, defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppWrapper } from './context/appWrapper';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import './styling/index.scss';
import { inputTheme } from './theme/input';
import { radioTheme } from './theme/radio';
import { checkboxTheme } from './theme/checkbox';
import { AppContext } from './context/appContext';
import axios from 'axios';
import { modalTheme } from './theme/modalTheme';
import { tableTheme } from './theme/table';


const colors = {
  brand: {
    500: '#e01719',
    600: '#af1214',
  },
  gray: {
    100: '#dbdcdc',
    200: '#46494d99',
    400: '#909294',
    500: '#46494d',
  },
  blue: {
    500: '#0091eb',
  },
  teal: {
    500: '#007395'
  },
  orange: {
    500: '#ef7a00'
  }
}


const textStyles = {
  labelSpecial: {
    fontFamily: 'FreightSans, sans-serif',
    textTransform: 'uppercase',
    'font-weight': 700,
    letterSpacing: '1.5px',
    lineHeight: '19.2px'
  },
  label: {
    fontWeight: 600,
    padding: 0,
    margin: '0px 0px 0.375rem',
    verticalAlign: 'middle'
  },
  h1: {
    fontSize: '2.5rem',
    color: 'gray.500',
    fontWeight: 700,
  },
  h2: {
    fontSize: '1.5rem',
    color: 'gray.500',
    fontWeight: 600,
    marginBottom: '1rem'
  },
  h3: {
    fontSize: '1.3rem',
    color: 'gray.500',
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h4: {
    fontSize: '1rem',
    color: 'gray.500',
    fontWeight: 600,
    marginBottom: '.5rem'
  }
}

const baseStyle = defineStyle({
  borderRadius: '0.1875rem',
  textTransform: 'uppercase',
  fontFamily: 'FreightSans, sans-serif',
  borderColor: 'transparent',
  color: 'white',
  fontWeight: 600,
  borderWidth: '0.0625rem',
  letterSpacing: '.1rem',
  lineHeight: 1.5,
  minWidth: 0,
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  transitionDuration: '.15s',
  transitionProperty: 'background-color, color, transform, box-shadow',
  transitionTimingFunction: 'ease-in-out',
  textAlign: 'center',
  appearance: 'button',
  margin: 0,
  _hover: {
    transform: 'scale(1.02)',
  }
});

const outlineStyles = {
  borderColor: 'brand.500',
  color: 'brand.500',
  backgroundColor: 'transparent',
  _hover: {
    borderColor: 'brand.600',
    color: 'brand.600',
    backgroundColor: 'transparent',
  }
}
const linkStyle = {
  fontFamily: 'fonts.body',
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  textTransform: 'none',
  fontWeight: 600,
  letterSpacing: 'none',
}
const customLinkStyle = {
  ...linkStyle, ...{
    color: 'teal.500',
    fontSize: '1rem',
    fontWeight: 600,
    _hover: {
      color: 'teal.600'
    }
  }
}

const regularLink = defineStyle(customLinkStyle)
const customLink = defineStyle(customLinkStyle)
const capsLink = defineStyle({ ...customLinkStyle, ...{ textTransform: 'uppercase' } })

const solid = defineStyle({
  backgroundColor: 'brand.500',
  _hover: {
    backgroundColor: 'brand.600',
  }
})

const outline = defineStyle(outlineStyles)
const outlineRight = defineStyle({ ...outlineStyles, ...{ width: 12, fontSize: '1.2rem', borderLeftRadius: 0 } });

const sizes = {
  md: defineStyle({
    fontSize: '.75rem',
    height: '1rem',
    padding: '1rem 1.4rem',
  }),
  lg: defineStyle({
    fontSize: '1rem',
    height: '30px',
    minWidth: '5rem',
  })
}
const noHoverStyle = {
  ...customLinkStyle,
  _hover: {
    transform: 'scale(1)',
  }

}
const noHover = defineStyle(noHoverStyle)

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants: { outline, outlineRight, customLink, regularLink, solid, capsLink, noHover },
  sizes,
})

export const headingTheme = defineStyleConfig({
  baseStyle,
  variants: { outline, outlineRight, customLink },
  sizes,
})


const inlineForm = defineStyle({
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'spaceBetween',
  marginLeft: '3.5%',
  marginRight: 0,
})

export const containerTheme = defineStyleConfig({
  variants: { inlineForm },
  sizes,
})

const theme = extendTheme({
  colors,
  textStyles,
  fonts: {
    body: "BattersonSans,sans-serif",

  },
  components: {
    IconButton: buttonTheme,
    Button: buttonTheme,
    Container: containerTheme,
    Heading: headingTheme,
    Input: inputTheme,
    Radio: radioTheme,
    Checkbox: checkboxTheme,
    Modal: modalTheme,
    Table: tableTheme
  },
})


const container = document.getElementById('root');
const root = createRoot(container!);

axios.get("/config.json", {
  headers: {
    "Content-Type": "application/json"
  }
}).then(res => {
  if (res.data) {
    console.log('res data', res.data);
    root.render(
      <React.StrictMode>
        <ChakraProvider resetCSS={true} resetScope="chakraScope" theme={theme}>
          <AppContext.Provider value={res.data}>
            <Provider store={store}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </Provider>
          </AppContext.Provider>
        </ChakraProvider>
      </React.StrictMode>
    )
  }
}).catch(() => {
  console.error('Error retrieving application config');
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
