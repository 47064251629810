import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
export type AuthState = {
  user: any;
  token: any;
};
const initialState: AuthState = {
  user: null,
  token: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // add your non-async reducers here
    setCredentials: (state: AuthState, action: PayloadAction<any>) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
    },
    logOut: (state: AuthState, action: PayloadAction<any>) => {
      state.user = null;
      state.token = null;
    },
  },
});

// Action creators
export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer

export const selectCurrentUser = (state : RootState) => state.auth.user
export const selectCurrentToken = (state : RootState) => state.auth.token
