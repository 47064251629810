export class AgentAccount {
//  Id: number;
  AccountName: string;
  AccountIdentifier: string;
  isAdded: boolean;
  isSAI: string;
  constructor(AccountName: string, AccountIdentifier: string, isSAI: string) {
    this.AccountName = AccountName;
    this.AccountIdentifier = AccountIdentifier;
    this.isSAI = isSAI;
    this.isAdded = false;
  }
}

export type AccIdentification = {
  accountIdentificationMethod: string;
  accountIdentificationValue: string;
  accountIdentificationSelections: string[];
};
