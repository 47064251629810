import { nationalProgramReportOptions } from '../../features/utils/utils';
import PolicyNumber from '../Shared/AccountIdentification/PolicyNumber';
import ProgramCodes from '../Shared/AccountIdentification/ProgramCodes';
import { TdsField } from '@trv-tds/react';
import { Text } from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NationalProgramsReportData } from '../../common/types';
import { AppContext } from '../../context/appContext';
import { showClaimActivityErrorMessage, addAccountErrorMessage, addSuccessMessage, setSubmitClicked } from '../../features/claimActivity/claimActivityErrorMsgSlice';
import { useUpdateParametersMutation } from '../../features/reports/saveReportApiSlice';
import useClaimActivityListData from '../../hooks/useClaimActivityListData';
import { RootState } from '../../store';
import { AccIdentification } from '../Shared/AgentAccountSearch/agentAccount';
import { updateAccountIdentification, resetAgentAllSelectedAccounts } from '../Shared/AgentAccountSearch/agentAccountSlice';
import { SubmissionSection } from '../Shared/FormSections/SubmissionSection';
import YearSelectionCriteria from '../Shared/FormSections/YearSelectionCriteria';
import DateSelectionCriteria from '../Shared/FormSections/DateSelectionCriteria';
import useAuth from '../../hooks/useAuth';
import { addDateRequiredErrorMessage, addDateRequiredToErrorMessage } from "../../features/errors/errorReducer";

const NationalPrograms = () => {
  const dispatch = useDispatch();
  const userAccountIdentification: AccIdentification = useSelector(
    (state: RootState) => state.agentAccount.accountIdentification
  );
  const { claimActivityErrorMessage: claimActivityListError, showClaimActivityError } = useSelector((state: RootState) => state.claimActivity);
  const { accountIdentificationSelections } = userAccountIdentification;
  const { profileData } = useAuth();
  const [resetDates, setResetDates] = useState<boolean | undefined>(false);
  const [reportName, setReportName] = useState<string>("Experience Recap By Group Member");
  const [reportHeading, setReportHeading] = useState<string>("");
  const [reportTypeSelected, setReportTypeSelected] = useState<string>("pdf");
  const [saveResponse, setSaveResponse] = useState<number>(0);
  const [missingReportName, setMissingReportName] = useState<boolean>(false);
  const [previousSelectionsCount, setPreviousSelectionsCount] =
    useState<number>(0);
  const [clearMsg, setClearMsg] = useState<boolean>(false);
  const [clearedCriteriaMsg, setClearedCriteriaMsg] = useState<boolean>(false);
  const [displayOrSummarize, setDisplayOrSummarize] = useState<string>('display');
  const [reportSelection, setReportSelection] = useState<string>('Experience Recap By Group Member');
  const [fromYear, setFromYear] = useState<string>();
  const [toYear, setToYear] = useState<string>();
  const [dateHasErrors, setDateHasErrors] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [historyDate, setHistoryDate] = useState<string>();
  const defaultAppConfig = { REACT_APP_ST_CLAIM_REPORT_URL: undefined }
  const { appConfig: { REACT_APP_ST_CLAIM_REPORT_URL } = defaultAppConfig } = useContext(AppContext)
  const dateRef = useRef<HTMLInputElement>(null);
  const [programCodeSelections, setProgramCodeSelections] = useState<string[]>([]);
  const [dateRangeRequiredMsg, setDateRangeRequiredMsg] = useState<boolean>(false);
  const [dateRangeToRequiredMsg, setDateRangeToRequiredMsg] = useState<boolean>(false);

  //Note this is needed because we validate account information with the claim activity service when user enters it in account ID section
  useClaimActivityListData();

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setClearedCriteriaMsg(false);
    setPreviousSelectionsCount(accountIdentificationSelections.length);
  }, [accountIdentificationSelections]);


  useEffect(() => {
    dispatch(showClaimActivityErrorMessage(false));
    setResetDates(true);
    dispatch(setSubmitClicked(false));
}, [])

  
  function getSelections() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
        return accountIdentificationSelections;
      }
    }

  function getAccountIdType() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
        if (
          userAccountIdentification.accountIdentificationMethod ===
          "Policy Number"
        )
          return 2;
        else if (
          userAccountIdentification.accountIdentificationMethod === "SAI Number"
        )
          return 4;
        else if (
          userAccountIdentification.accountIdentificationMethod ===
          "Producer Code"
        )
          return 3;
        else return -1;
      }
    }
    function getProgramCodes(){
      return programCodeSelections;
    }

    function getExperienceRecapType(){
      if(reportSelection==='Experience Recap By Group Member')
        return 0;
      else if(reportSelection==='Experience Recap By Eff Year')
        return 3;
      else if(reportSelection==='Experience Recap By Line')
        return 1;
      else if(reportSelection==='Group Member Loss Run')
        return 0;
    }
  const reportData: NationalProgramsReportData = {
    NationalCriteria: {
      EffYearFrom: reportSelection==='Group Member Loss Run'?0:(fromYear && fromYear!='YYYY'?Number.parseInt(fromYear):0),
      EffYearTo: reportSelection==='Group Member Loss Run'?0:(toYear && toYear!='YYYY'?Number.parseInt(toYear):0),
      HistoryDate: "0001-01-01T00:00:00",
      HistoryDateSecond: "0001-01-01T00:00:00",
      Limits:[],
      ReportCategory: 1,
      AccountName: "",
      Selections: getSelections(),
      FromDate: reportSelection==='Group Member Loss Run'?fromDate+'T00:00:00':(fromYear && fromYear!='YYYY'?Number.parseInt(fromYear):0)+'-01-01T00:00:00',
      ToDate: reportSelection==='Group Member Loss Run'?toDate+'T00:00:00':(toYear && toYear!='YYYY'?Number.parseInt(toYear):0)+'-01-01T00:00:00',
      Heading: reportHeading,
      OutputFormat: reportTypeSelected === "pdf" ? 0 : 1,
      ReportType: reportSelection==='Group Member Loss Run'?6:7,
      AccountIdType: getAccountIdType(),
      SortGroups: [],
          BaseUrl: REACT_APP_ST_CLAIM_REPORT_URL,
      ShowDrilldown: true,
      ReportName: reportName,
      SummarizePgmCodes: displayOrSummarize!=='display',
      PgmCodeSelections: getProgramCodes(),
      ExperienceRecapType: getExperienceRecapType(),
      NewLossReport: true,
    },
    UserInformation:
      profileData && profileData.length ? profileData[0] : undefined,
  };
  const saveReportData = {
    ...reportData,
    ParameterCriteria: {
      ParamId: 0,
      EventType: 0,
      OverrideOrgCheck: false,
    },
  };

  const [saveData] = useUpdateParametersMutation();

  const scrollToTop = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }





  const saveForm = async () => {
    dispatch(showClaimActivityErrorMessage(true))
    if (claimActivityListError !== "") {
      scrollToTop();
      return true;
    } else {
      if ((profileData && profileData.length > 0 && profileData[0].Role && 
        (profileData[0].Role.IsAccountId !== true && profileData[0].Role.IsAccountLocationId !== true)) && accountIdentificationSelections.length === 0) {
      dispatch(
          addAccountErrorMessage(
            "Please enter an account identifier to save the report criteria."
          )
        );
      } else if (reportName.trim() === "") {
        setMissingReportName(true);
      } else {
        setMissingReportName(false);
        const { data }: any = await saveData(saveReportData);
        setSaveResponse(Number(data.result));
        dispatch(addSuccessMessage(""));
        if (data && data.result === "1") {
          dispatch(addSuccessMessage("success"));
        }
      }
    }
  };

  const submitForm = async () => {
    dispatch(showClaimActivityErrorMessage(true))
    if (claimActivityListError !== "") {
      scrollToTop();
      return true;
    } else {
      if (accountIdentificationSelections.length === 0) {
        dispatch(
          addAccountErrorMessage(
            "Please enter an account identifier to run the report."
          )
        );
        scrollToTop();
      }
      if(reportSelection!=='Group Member Loss Run'){
         if(fromYear==='' || toYear===''){
          setDateRangeRequiredMsg(fromYear==='');
          setDateRangeToRequiredMsg(toYear==='');
          setDateHasErrors(true);
          dispatch(addDateRequiredErrorMessage(fromYear===''));
          dispatch(addDateRequiredToErrorMessage(toYear===''));
          return true;
        }
      }else{
        if(fromDate==='' || toDate===''){
          dispatch(addDateRequiredErrorMessage(fromDate===''));
          dispatch(addDateRequiredToErrorMessage(toDate===''));
          setDateHasErrors(true);
          return true;
        }
      }
      const hasErrors = accountIdentificationSelections.length === 0 || dateHasErrors;
      return hasErrors;
    }
  };

  const resetForm = () => {
    //remove id's and reset to policy number
    dispatch(
      updateAccountIdentification({
        ...userAccountIdentification,
        ["accountIdentificationMethod"]: "Producer Code",
      })
    );
    dispatch(resetAgentAllSelectedAccounts());
    setMissingReportName(false);
    setSaveResponse(0);
    clearWarningMessages();
    setResetDates(true);
    setDisplayOrSummarize("display");
    setTimeout(()=>{setReportSelection('Experience Recap By Group Member'),1000});
    setReportName('Experience Recap By Group Member');
    dispatch(addSuccessMessage(""));
    setDateHasErrors(false);
    dispatch(showClaimActivityErrorMessage(false));
    dispatch(setSubmitClicked(false));
 };


  function clearWarningMessages() {
    setClearMsg(true);
  }
  function setSelection(event:any){
    setReportSelection(event.target.value);
    setReportName(event.target.value);
  }
  
  return (
     <div>
      <PolicyNumber
        clearMsg={clearMsg}
        setClearMsg={setClearMsg}
        clearedCriteriaMsg={clearedCriteriaMsg}
        setClearedCriteriaMsg={setClearedCriteriaMsg}
        hasCustomFilters={false}
        presetAccountIdentificationOptions={["Policy Number", "SAI Number", "Producer Code"]}
        pageName='NationalPrograms'
      />

      <div className='report-selection-div'>
      <ProgramCodes
        programCodeSelections={programCodeSelections}
        setProgramCodeSelections={setProgramCodeSelections}
        />
      </div>

      <div className='report-selection-div'>
        <Text as="h3" textStyle="h3">
          Report Selection
        </Text>
        <div className='national-programs-report-selection-form-elements-div'>
          <div className='national-programs-report-selection-div'>
            <TdsField style={{ width: '100%', height: '2rem', verticalAlign: 'top'}}>
              <select className='tds-field__input' 
              aria-label='report_selection' 
              name='tds-field__input'
              value={reportSelection} 
              onChange={(event) => {setSelection(event)}}>
                {nationalProgramReportOptions.map((element, index) => (
                  <option key={index} value={element.label}>
                    {element.label}
                  </option>
                ))}
                isSearchable={false}
              </select>
            </TdsField>
          </div>
        </div>
      </div>

      <div ref={dateRef}>
      {(reportSelection!=='Group Member Loss Run'?
      <YearSelectionCriteria
       reset={resetDates}
      setReset={setResetDates}
      fromYear={fromYear}
      setFromYear={setFromYear}
      toYear={toYear}
      setToYear={setToYear}
      setDateHasErrors={setDateHasErrors}
      dateHasErrors={dateHasErrors}
      disabledProducer={true}
      pageName={'NationalPrograms'}
      dateRangeRequiredMsg={dateRangeRequiredMsg}
      setDateRangeRequiredMsg={setDateRangeRequiredMsg}
      dateRangeToRequiredMsg={dateRangeToRequiredMsg}
      setDateRangeToRequiredMsg={setDateRangeToRequiredMsg}
    />
                  :
    <DateSelectionCriteria
          hasDouble={false}
          reset={resetDates}
          setReset={setResetDates}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          blankDates={true}
          blankHistoryDate={true}
          historyDate={historyDate}
          setHistoryDate={setHistoryDate}
          setDateHasErrors={setDateHasErrors}
          dateHasErrors={dateHasErrors}
          dateRangeRequiredMsg={dateRangeRequiredMsg}
          setDateRangeRequiredMsg={setDateRangeRequiredMsg}
          pageName={'NationalPrograms'}
          hasNationalProgramsGroupMemberLossRun={reportSelection?reportSelection==='Group Member Loss Run':false}
          />
        )}
        </div>
        {reportSelection!=='Group Member Loss Run' ?(
      <SubmissionSection
        onResetForm={resetForm}
        onSaveForm={saveForm}
        onSubmitForm={submitForm}
        reportName={reportName}
        setReportName={setReportName}
        reportHeading={reportHeading}
        setReportHeading={setReportHeading}
        reportTypeSelected={reportTypeSelected}
        setReportTypeSelected={setReportTypeSelected}
        missingReportNameError={missingReportName}
        setMissingReportName={setMissingReportName}
        reportData={reportData}
        saveResponse={saveResponse}
        setSaveResponse={setSaveResponse}
        disableButtons={((claimActivityListError !== "" && showClaimActivityError)||dateHasErrors)}
        formHasErrors={false}
        pageName='NationalPrograms'
        displayOrSummarize={displayOrSummarize}
        setDisplayOrSummarize={setDisplayOrSummarize}
      />):
      (<SubmissionSection
      onResetForm={resetForm}
      onSaveForm={saveForm}
      onSubmitForm={submitForm}
      reportName={reportName}
      setReportName={setReportName}
      reportHeading={reportHeading}
      setReportHeading={setReportHeading}
      reportTypeSelected={reportTypeSelected}
      setReportTypeSelected={setReportTypeSelected}
      missingReportNameError={missingReportName}
      setMissingReportName={setMissingReportName}
      reportData={reportData}
      saveResponse={saveResponse}
      setSaveResponse={setSaveResponse}
      disableButtons={((claimActivityListError !== "" && showClaimActivityError)||dateHasErrors)}
      formHasErrors={false}
      displayOrSummarize={displayOrSummarize}
      setDisplayOrSummarize={setDisplayOrSummarize}
    />

      )}
      </div>
  );
};
export default NationalPrograms;
