import { Dispatch, SetStateAction } from 'react';
import { ReactComponent as HelpCloseIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-12.svg';
import { ReactComponent as FilePdf118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/file-pdf-1-18.svg';
import { ReactComponent as Play118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/play-1-18.svg';
type HelpProps = {
  setShowHelpDialog: Dispatch<SetStateAction<boolean>>;
};
const Help = ({ setShowHelpDialog }: HelpProps) => {
  return (
    <div role='dialog' className='tds-modal tds-modal__dialog--small' aria-label='help dialog'>
      <div role='document' className='tds-modal__dialog-container'>
        <div className='tds-modal__dialog-header'>
          <h3 className='tds-modal__dialog-title' style={{ color: '#46494d' }} id='my-modal-heading-sm'>
            Help
          </h3>
        </div>

        <div className='tds-modal__dialog-body' style={{ padding: '2rem' }}>
          <h3 style={{ fontSize: '1.1rem', color: '#46494d', marginBottom: '8%' }}>Available Resources</h3>

          <h4 style={{ color: '#007395', fontWeight: '400', marginBottom: '8%' }}>
            <a href={require('../../documents/LossReportsQuickGuideOLD.pdf')} target='_blank' rel='noreferrer' style={{ textDecoration: 'none', color: '#007395', fontSize: '1rem' }}>
              <FilePdf118 className='tds-icon-18' color='#007395' /> &nbsp;&nbsp;Quick Guide
            </a>
          </h4>

          <h4 style={{ color: '#007395', fontWeight: '400', marginBottom: '8%' }}>
            <a href='http://vista-survey.com/survey/v2/survey2.dsb?ID=9025771530' target='_blank' rel='noreferrer' style={{ textDecoration: 'none', color: '#007395', fontSize: '1rem' }}>
              <Play118 className='tds-icon-18' color='#007395' /> &nbsp;&nbsp;Tutorial
            </a>
          </h4>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button onClick={() => setShowHelpDialog(false)} className='tds-button--primary tds-button--small' type='button'>
              
              CLOSE
            </button>
          </div>

          <button onClick={() => setShowHelpDialog(false)} className='tds-modal__dismiss' aria-label='Dismiss dialog' type='button'>
            <HelpCloseIcon alt='Close Alert' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Help;
