import './App.css';
import SecurityWrapper from './SecurityWrapper';
import mockServer from './mock/mockServer';
import createRoutes from './routes/Routes';

console.log('Hostname ' + window.location.hostname);

if (process.env.REACT_APP_MOCK_API_TRUE) {
  mockServer();
}

const App = () => {
  const routes = createRoutes();

  return <SecurityWrapper>{routes}</SecurityWrapper>;
};
export default App;
