import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface ErrorMessageState {
  errorMessages: string[];
  hasDateRequiredErrorMessage: boolean;
  hasDateRequiredToErrorMessage: boolean;
  hasHistoryDateRequiredErrorMessage: boolean;
}

const initialState: ErrorMessageState = {
  errorMessages: [],
  hasDateRequiredErrorMessage: false,
  hasDateRequiredToErrorMessage: false,
  hasHistoryDateRequiredErrorMessage: false,
};

export const errorMsgSlice = createSlice({
  name: "errorMessages",
  initialState,
  reducers: {
    addDateRequiredErrorMessage: (
      state: ErrorMessageState,
      action: PayloadAction<boolean>
    ) => {
      state.hasDateRequiredErrorMessage = action.payload;
    },
    addDateRequiredToErrorMessage: (
      state: ErrorMessageState,
      action: PayloadAction<boolean>
    ) => {
      state.hasDateRequiredToErrorMessage = action.payload;
    },
    addHistoryDateRequiredErrorMessage: (
      state: ErrorMessageState,
      action: PayloadAction<boolean>
    ) => {
      state.hasHistoryDateRequiredErrorMessage = action.payload;
    },
    clearErrorMessages: (state: ErrorMessageState) => {
      state.errorMessages = [];
    },
    addErrorMessage: (
      state: ErrorMessageState,
      action: PayloadAction<string>
    ) => {
      state.errorMessages = [...state.errorMessages, action.payload];
    },
    removeErrorMessage: (
      state: ErrorMessageState,
      action: PayloadAction<string>
    ) => {
      state.errorMessages = state.errorMessages.filter(
        (msg) => msg !== action.payload
      );
    },
  },
});

// Action creators
export const {
  clearErrorMessages,
  addErrorMessage,
  removeErrorMessage,
  addDateRequiredErrorMessage,
  addDateRequiredToErrorMessage,
  addHistoryDateRequiredErrorMessage,
} = errorMsgSlice.actions;

export default errorMsgSlice.reducer;
