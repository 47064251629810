import { Table, Thead, Tbody, Th, Tr, Td } from '@chakra-ui/react';
import { ReactComponent as MyAccountsClaimActivityCloseIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-12.svg';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReportData } from '../../../common/types';
import { AppContext } from '../../../context/appContext';
import { useGetClaimActivityDrilldownQuery } from '../../../features/claimActivity/claimActivityDrilldownApiSlice';
import { useGetPrefillDataQuery } from '../../../features/utils/prefillApiSlice';
import { getCurrentDateMinusTwo } from '../../../features/utils/utils';
import useAuth from '../../../hooks/useAuth';
import useClaimActivityListData from '../../../hooks/useClaimActivityListData';
import { RootState } from '../../../store';
import { AccIdentification } from '../AgentAccountSearch/agentAccount';
import { Loader } from '../Common/Loader';

type ClaimActivityAccountsModalProps = {
  setShowClaimActivityAccountsModal: Dispatch<SetStateAction<boolean>>;
  activityPeriod: number;
  categoryCode: string;
};

const ClaimActivityAccountsModal = ({ setShowClaimActivityAccountsModal, activityPeriod, categoryCode }: ClaimActivityAccountsModalProps) => {
  const { profileData } = useAuth();
  const userAccountIdentification: AccIdentification = useSelector((state: RootState) => state.agentAccount.accountIdentification);
  const defaultAppConfig = { REACT_APP_ST_CLAIM_STATUS_URL: undefined, REACT_APP_ST_ETRACER_URL: undefined, REACT_APP_ST_CLAIM_PORTAL_URL: undefined }
  const { appConfig: { REACT_APP_ST_CLAIM_STATUS_URL, REACT_APP_ST_CLAIM_PORTAL_URL } = defaultAppConfig } = useContext(AppContext)
  const { claimActivityListData } = useClaimActivityListData();
  const { accountIdentificationSelections } = userAccountIdentification;
  const [acctOrgLevelData, setAcctOrgLevelData] = useState<any>();

  const qoutedAndCommaSeparated = userAccountIdentification.accountIdentificationSelections;
  const drilldownCriteria: ReportData = {
    LossesCriteria: {
      CategoryCode: categoryCode,
      activityPeriod: activityPeriod,
    },
    UserInformation: profileData && profileData.length ? profileData[0] : undefined
  };
  if (qoutedAndCommaSeparated.length > 0) {
    drilldownCriteria.LossesCriteria.Selections = qoutedAndCommaSeparated;
  }
  if (getAccountIdType() > 0) {
    drilldownCriteria.LossesCriteria.AccountIdType = getAccountIdType();
  }

  const { data: claimActivityDrilldownData, error: getClaimActivityDrilldownDataError,
    isError: isClaimActivityDrilldownDataError, isLoading: claimActivityDrilldownLoading } = useGetClaimActivityDrilldownQuery(drilldownCriteria);

  if (isClaimActivityDrilldownDataError) {
    console.log("error retrieving claim activity table data:" + getClaimActivityDrilldownDataError);
  }

  const lossCriteria = {
    UserInformation: profileData[0],
  };

  let shouldSkip = (profileData === undefined || profileData === null || profileData?.length === 0) ||
    (profileData?.length > 0 && (
      profileData[0]?.Role.IsInternalUser ||
      (profileData[0]?.Role.IsAccountId === false && profileData[0]?.Role.IsAccountLocationId === false)
    ));

     const {
      data: prefill_data,
      isSuccess,
      isLoading,
      isError,
      error,
    } = useGetPrefillDataQuery(lossCriteria, {
      skip: shouldSkip
    });
  
    useEffect(() => {
      if (
        prefill_data &&
        prefill_data.result &&
        prefill_data.result.Selections &&
        !isLoading
      ) {
        setAcctOrgLevelData(prefill_data.result);
      }
    }, [prefill_data, isLoading, profileData]);
  

  function getAccountIdType() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (
        profileData[0].Role.IsAccountId === true ||
        profileData[0].Role.IsAccountLocationId === true
      ) {
            return acctOrgLevelData?.AccountIdType;
      } else {
        if (
          userAccountIdentification.accountIdentificationMethod ===
          "Policy Number"
        )
          return 2;
        else if (
          userAccountIdentification.accountIdentificationMethod ===
          "Account Number"
        )
          return 1;
        else if (
          userAccountIdentification.accountIdentificationMethod === "SAI Number"
        )
          return 4;
        else if (
          userAccountIdentification.accountIdentificationMethod ===
          "Producer Code"
        )
          return 3;
        else return -1;
      }
    }
  }

  const claimStatusSearch = (claimNumberValue: string) => {
    let baseUrl = REACT_APP_ST_CLAIM_PORTAL_URL;
    let region = "";
    if (baseUrl && baseUrl.search("travelers.com") > 0) {
      region = 'External';
    } else {
      region = 'Internal';
    }
    let searchParams = `?clm=${claimNumberValue}&UserType=` + region;
    let url = baseUrl + searchParams;
    let newWindow = window.open(url, 'ClaimStatus', 'width=document.body.clientWidth,height=document.body.clientHeight,scrollbars=yes,menubar=no,resizable=yes,directories=no,status=yes,location=no');
    newWindow?.focus();
  };

  const closeModal = ()=>{
    document.body.style.position = '';
    document.body.style.top = '';
    setShowClaimActivityAccountsModal(false)
  }

  return (
    <div role='dialog' className='tds-modal' aria-label='claim activity modal'>
      <div role='document' className='tds-modal__dialog-container' style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
        <div className='tds-modal__dialog-header'>
          <h2 className='tds-modal__dialog-title' style={{ color: '#46494d' }}>
            {
              (accountIdentificationSelections.length === 0 && profileData[0].Role.IsBroker && !profileData[0].Role.IsInternalUser)
                ? "My Accounts"
                : (claimActivityListData.result !== null ? claimActivityListData.result.LossesCriteria[0].AccountName : "")
            } - Claim Activity
            {activityPeriod === 0 && (` for ${getCurrentDateMinusTwo()}`)}
          </h2>
        </div>
        <div className='tds-modal__dialog-body'>
          {claimActivityDrilldownLoading && <Loader w="full" p={50} />}
          {!claimActivityDrilldownLoading && !isClaimActivityDrilldownDataError &&
            (<Table
              style={{ borderCollapse: "separate" }}
              fontSize="sm">
              <Thead
                position="sticky"
                top={0}
                zIndex="docked"
                bgColor="white"
                m={0}
              >
                <Tr>
                  <Th>
                    ACCOUNT NAME
                  </Th>
                  <Th>
                    CATEGORY
                  </Th>
                  <Th>
                    CLAIMANT
                  </Th>
                  <Th>
                    CLAIM #
                  </Th>
                  <Th>
                    LINE
                  </Th>
                  {(categoryCode === "H" || categoryCode === "I") &&
                    <Th>
                      ACTIVITY
                    </Th>
                  }
                </Tr>
              </Thead>
              <Tbody>
                {
                  claimActivityDrilldownData && claimActivityDrilldownData.map((record) => {
                    const { ACCT_NM, CATEGORY_DESC, CLMT_NM, CLM_NBR_ID, INC_TOT_AMT, MAJ_LN_CATGY_CD } = record;
                    return (
                      <Tr>
                        <Td>
                          {ACCT_NM}
                        </Td>
                        <Td>
                          {CATEGORY_DESC}
                        </Td>
                        <Td>
                          {CLMT_NM}
                        </Td>
                        <Td>
                          <a onClick={() => {
                            claimStatusSearch(CLM_NBR_ID)
                          }} className='table-button-links' color="#007395"> {CLM_NBR_ID} </a>
                        </Td>
                        <Td>
                          {MAJ_LN_CATGY_CD}
                        </Td>
                        {(categoryCode === "H" || categoryCode === "I") &&
                          <Td textAlign="right" style={{ fontSize: '0.95rem' }}>
                            ${INC_TOT_AMT.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                          </Td>
                        }
                      </Tr>
                    );
                  })
                }
              </Tbody>
            </Table>
            )}
          <div className='claim-activity-accounts-modal-btn-div'>
            <button
              onClick={closeModal}
              className='tds-button--primary tds-button--small claim-activity-accounts-modal-close-btn'
              style={{ marginBottom: '2rem' }}
              type='button'
            >
              CLOSE
            </button>
          </div>
          <button onClick={closeModal} className='tds-modal__dismiss' aria-label='Dismiss Claim Activity e-Alerts' type='button'>
            <MyAccountsClaimActivityCloseIcon alt='Close Alert' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClaimActivityAccountsModal;