import { Text, Box, VStack, BoxProps, StackDirection, Stack } from '@chakra-ui/react';
import { ReactNode } from "react"

interface StackContainerProps extends BoxProps {
    title: string, 
    hasBorder?: Boolean,
    spacing?: number,
    outerSpacing?: number,
    direction?: StackDirection,
    stackProps?: BoxProps,
    children: ReactNode
}

export const StackContainer = (props: StackContainerProps) => {
    const { title, spacing=4, outerSpacing=6, hasBorder = true, direction="column", stackProps={}, children } = props
    const boxProps = hasBorder ? {
        borderTop: '1px',
        borderColor: 'gray.400'
    } : {}
        const {title: _, ...sanitizedProps} = props;

        return (
            <Box py={outerSpacing} my={outerSpacing} {...boxProps} {...sanitizedProps}>   
            <Stack spacing={outerSpacing} direction="column" alignItems="start">
                <Text as="h3" textStyle="h3">
                    {title}
                </Text>
                <Stack direction={direction} spacing={spacing} alignItems="start" {...stackProps}>
                {children}
                </Stack>
            </Stack>
        </Box>
    )
}